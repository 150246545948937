import React, { ReactElement, useRef, useState, useContext } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import { auth } from 'lib/firebase';
import { isRoleDisallowed } from 'lib/role';
import { getNavAction } from 'lib/nav';

import { MenuOptionType } from './Menu';
import { AccountIcon } from 'icons';

import {
  Nav,
  AccountNavContainer,
  AccountIconContainer,
  LoggedInNavDropdown,
  LoggedInStyledNavLink,
  StyledNavLink
} from './element';
import AuthContext from 'AuthContext';

/* eslint-disable prettier/prettier */
const MOUSEDOWN = "mousedown";
const TOUCHSTART = "touchstart";
type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
    [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];

const clickOutsideHandler = ({ handler }: { handler: (e: boolean) => void }) => {
  const ref = useRef<HTMLInputElement>(null);

  /* eslint-disable prettier/prettier */
  useOnClickOutside(ref, (event: PossibleEvent) => {
    if (ref && ref.current && event.target && !ref.current.contains((event.target as HTMLInputElement))) {
      handler(false);
    }
  });

  return ref;
};

export const LoggedInNavOptions = ({
  options,
  currentRole,
  toggleChangePasswordModal
}: {
  options: MenuOptionType[],
  currentRole?: string,
  toggleChangePasswordModal: () => void
}): ReactElement => {
  const [loginNavIsOpen, setLoginNavIsOpen] = useState(false);
  const { profile } = useContext(AuthContext);
  const ref = clickOutsideHandler({ handler: setLoginNavIsOpen });

  return (
    <>
      <AccountNavContainer ref={ref} onClick={() => setLoginNavIsOpen(!loginNavIsOpen)}>
        <AccountIconContainer>
          <AccountIcon />
          <LoggedInNavDropdown isOpen={loginNavIsOpen}>
            {options.map(
              (option: MenuOptionType, index: number) =>
                !isRoleDisallowed({ currentRole, module: option }) && (
                  <LoggedInStyledNavLink
                    key={index}
                    onClick={() =>
                      option.action &&
                      getNavAction({ action: option.action, toggleChangePasswordModal })({
                        auth,
                        profile
                      })
                    }>
                    {option.name}
                  </LoggedInStyledNavLink>
                )
            )}
          </LoggedInNavDropdown>
        </AccountIconContainer>
      </AccountNavContainer>
    </>
  );
};

export const NavOptions = ({
  options,
  currentRole,
  isLoggedIn,
  toggleChangePasswordModal
}: {
  options: MenuOptionType[],
  currentRole?: string,
  isLoggedIn?: boolean,
  toggleChangePasswordModal: () => void
}): ReactElement | null => {
  const navOptions = options.filter((opt: MenuOptionType) => !opt.loggedInOnly);
  const loggedInNavOptions = options.filter((opt: MenuOptionType) => opt.loggedInOnly);

  return isLoggedIn ? (
    <Nav>
      {navOptions.map(
        (option: MenuOptionType, index: number) =>
          !isRoleDisallowed({ currentRole, module: option }) && (
            <StyledNavLink key={index} to={option.link || '/'}>
              {option.name}
            </StyledNavLink>
          )
      )}
      <LoggedInNavOptions
        options={loggedInNavOptions}
        currentRole={currentRole}
        toggleChangePasswordModal={toggleChangePasswordModal}
      />
    </Nav>
  ) : null;
};

export default NavOptions;
