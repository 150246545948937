import React, { ReactElement, useState } from 'react';

import { BlockItem, Image, Modal } from 'component';

import { AlertModalContainer, AlertContainer, ModalClose } from './element';

const AlertModalContent = ({
  closeHandler
}: {
  closeHandler: (e: boolean) => void
}): ReactElement => (
  <AlertContainer>
    <ModalClose onClick={() => closeHandler(false)} />
    <Image src="/image/icons/Hazard_Report.png" size="Small" />
    <BlockItem
      theme="BigHeadingText"
      captionScale="S"
      foregroundColor="black"
      heading="Waze Brand Center Update"
      body="This website will be retired after October 15, 2024.  
For the latest Waze brand resources, please visit the [Google Partner Marketing Hub](https://partnermarketinghub.withgoogle.com/)."
      withLayout
    />
  </AlertContainer>
);

export const AlertModal = () => {
  let showAlert = true;
  if (typeof window !== 'undefined') {
    showAlert = window.sessionStorage.getItem('sunsetAlert') == null ? true : false;
  }
  const [openAlertModal, setOpenAlertModal] = useState(showAlert);
  const toggleModal = () => {
    setOpenAlertModal(false);
    window.sessionStorage.setItem('sunsetAlert', 'true');
  };

  return (
    <>
      {openAlertModal && (
        <Modal modalIsOpen={true} onRequestClose={() => toggleModal()}>
          <AlertModalContainer>
            <AlertModalContent closeHandler={toggleModal} />
          </AlertModalContainer>
        </Modal>
      )}
    </>
  );
};

export default AlertModal;
