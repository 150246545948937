import React, { ReactElement, ReactNode, useContext } from 'react';
import tw, { styled } from 'twin.macro';
import { navigate } from 'gatsby';
import { HistoryLocation } from '@reach/router';

import { isBrowser } from 'lib/browser';

import { Container } from './element';
import AuthContext from 'AuthContext';

interface Props {
  children: ReactNode | ReactNode[];
  location: HistoryLocation;
}

const SiteContainer = ({ children, location }: Props): ReactElement | null => {
  const { isLoggedIn, isLoadingInitial: isAuthLoading, permissionLevel } = useContext(AuthContext);

  if (!isBrowser) {
    return <Container>{children}</Container>;
  }

  if (isAuthLoading) {
    // We won't know if a session is active/inactive
    // until Firebase asynchronously comes back
    // through firebase.auth.onAuthStateChanged inside AuthContexrt
    return null;
  }

  if (!isLoggedIn && location.pathname !== '/') {
    // Firebase came back with an empty user session, bring them to login
    navigate('/');
    return null;
  }

  if (location.pathname.startsWith('/logout')) {
    return <Container>{children}</Container>;
  }

  if (isLoggedIn) {
    if (permissionLevel === 'LEVEL_UNKNOWN') {
      // AuthContext is still processing the user's perm... hold on!
      return <Loading />;
    }

    if (permissionLevel === 'LEVEL0' && !location.pathname.startsWith('/locked')) {
      // Redirect to 'locked' page
      navigate('/locked');
      return null;
    }

    if (permissionLevel !== 'LEVEL0' && location.pathname.startsWith('/locked')) {
      // Redirect to 'home' page, they arent locked!
      navigate('/brand');
      return null;
    }

    if (location.pathname === '/') {
      // If they are logged in, take them away from the login screen
      navigate('/brand');
      return null;
    }
  }

  // OK, render the page
  return <Container>{children}</Container>;
};

export default SiteContainer;

const StyledLoadingDiv = styled.div`
  ${tw`
    container
    flex
    justify-center
    items-center
  `};
  min-height: calc(100vh - 96px);
`;

const Loading = () => {
  return (
    <Container>
      <StyledLoadingDiv>
        <svg width="100" height="100" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle stroke="#98e9ff" strokeOpacity="0.15" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18" stroke="#65dbff">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </StyledLoadingDiv>
    </Container>
  );
};
