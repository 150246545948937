export const MODAL_ROOT_SELECTOR =
  process.env.STORYBOOK_IN_STORYBOOK_CONTEXT === 'true' ? '#root' : '#___gatsby';

export const LOGIN_CONTENT_TITLE = 'Welcome to Brand Center!';
export const LOGIN_CONTENT_DESCRIPTION =
  "This is the place you'll find the brand guidelines, templates and resources you need to walk, talk and design like a Wazer.";

export const LOGIN_FORM_TITLE = 'Log in';
export const LOGIN_FORM_DESCRIPTION_MARKDOWN =
  'Login in with your account to access all guidelines and assets.';
export const LOGIN_FORM_SUBMIT_LABEL = 'Sign In';
export const LOGIN_FORM_FOOT_MARKDOWN = 'Go [back] for other access options.';
export const LOGIN_FORM_BACK_LABEL = 'Back';

export const SIGNUP_FORM_TITLE = 'Sign Up';
export const SIGNUP_FORM_DESCRIPTION_MARKDOWN =
  'Create your account to access all guidelines and assets.';
export const SIGNUP_FORM_SUBMIT_LABEL = 'Sign Up';
export const SIGNUP_FORM_FOOT_MARKDOWN = LOGIN_FORM_FOOT_MARKDOWN;
export const SIGNUP_FORM_BACK_LABEL = 'Back';

export const MAIN_LOGIN_FORM_TITLE = 'Log in';
export const MAIN_LOGIN_FORM_DESCRIPTION_MARKDOWN =
  'Use your Waze account to access all guidelines and assets. If you don’t have an account yet, [please register.](#)';
export const MAIN_LOGIN_FORM_SUBMIT_LABEL = 'Log in With Email';
export const MAIN_LOGIN_FORM_FOOT_MARKDOWN = '[Forgot your password?](#)';
export const MAIN_LOGIN_FORM_SIGN_IN_WITH_GOOGLE_LABEL = 'Sign in with Google';

export const FORGOT_FORM_TITLE = 'Forgot your password?';
export const FORGOT_FORM_DESCRIPTION_MARKDOWN = `We’ll send you a reset link.`;
export const FORGOT_FORM_SUBMIT_LABEL = 'Reset password';
export const FORGOT_FORM_BACK_LABEL = 'Back to Sign In';
export const FORGOT_ERROR_MESSAGE = `Oops. Looks like there was some problem sending a reset link. Please try again.`;
export const FORGOT_SUCCESS_TITLE = `Check your email`;
export const FORGOT_SUCCESS_DESCRIPTION = `We’ve sent a confirmation link to your email address.`;
export const FORGOT_SUCCESS_IMAGE = '/image/illustration/03_emailicon_01.svg';

export const CHANGE_PASSWORD_SUCCESS_IMAGE = '/image/illustration/14_emailicon_02.svg';
export const CHANGE_PASSWORD_SUCCESS_TITLE = `Check your email`;
export const CHANGE_PASSWORD_SUCCESS_DESCRIPTION = `We’ve sent a  link to your email address to reset your password.`;

export const firebase = {
  credentials: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID
  },
  loginRedirectPath: '/brand',
  socialLogins: ['google']
};
