import React, { ReactElement, useEffect, useRef, useState } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import { AnimationContainer } from './element';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const AnimationData = require('animation');

const loadAnimation = ({
  container,
  name
}: {
  container: React.RefObject<HTMLInputElement>,
  name: string
}) => {
  const animationData = AnimationData[name];
  if (container && container.current && animationData) {
    return lottie.loadAnimation({
      container: container && container.current,
      // eslint-disable-next-line
      renderer: 'canvas',
      loop: false,
      autoplay: false,
      animationData
    });
  }
  return;
};

interface Props {
  size?: ImageSizeNameType;
  name: string;
  show?: boolean;
  triggerPlay?: boolean;
  idx?: number;
}

export const Animation = ({
  name,
  size,
  show = false,
  triggerPlay = false,
  idx = 1
}: Props): ReactElement => {
  const container = useRef<HTMLInputElement>(null);
  const [animation, setAnimation] = useState<AnimationItem | undefined | null>(null);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    setPlay(triggerPlay);
  }, [triggerPlay]);

  useEffect(() => {
    setAnimation(loadAnimation({ container, name }));
    if (animation) {
      animation.hide();
    }
  }, []);

  useEffect(() => {
    const delay = (idx + 1) * 500;
    if (show) {
      const timer = setTimeout(() => {
        setPlay(true);
      }, delay);
      return () => clearTimeout(timer);
    }
    return;
  }, [show]);

  useEffect(() => {
    if (animation) {
      if (play && show) {
        animation.show();
        animation.goToAndPlay(0, true);
      } else {
        animation.goToAndStop(0, true);
      }
    }
  }, [show, play]);

  return <AnimationContainer size={size} ref={container} />;
};

export default Animation;