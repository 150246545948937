import tw, { styled } from 'twin.macro';
import Markdown from 'react-markdown';

interface StyledMarkdownTextProps {
  size?: string;
  children: string
}

export const StyledMarkdownText =
  styled(Markdown) <
  StyledMarkdownTextProps >
  `
  ${tw`
    text-center
    w-1/2
    py-1
    font-light
    text-left
    w-64
    leading-normal
  `}

  a {
    ${tw`
    text-black
    underline
    `}
  }
`;

export const OnClickLink = styled.span`
  ${tw`
    text-black
    underline
    cursor-pointer
    `}
`;
