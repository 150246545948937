import React, { ReactElement, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  type?: string;
  children?: ReactNode[];
}

export const PageMetadata = ({ title, description, type, children }: Props): ReactElement => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {type && <meta property="og:type" content={type} />}
      {children}
    </Helmet>
  );
};

export default PageMetadata;
