/* eslint-disable max-lines */

export interface StyleByThemeViewport {
  heading?: { [e: string]: string };
  body?: { [e: string]: string };
  align?: string;
}

export interface StyleByThemeRecord {
  [key: string]: string | StyleByThemeViewport;
}

export interface StyleByTheme {
    captionScaleName: string;
    desktop: StyleByThemeViewport;
    mobile: StyleByThemeViewport;
    position: string;
}

export const stylesByTheme = {
  BigHeadingTextTwoButtons: {
    captionScaleName: 'XL',
    position: 'col',
    desktop: {
      align: 'center',
      heading: {
        marginTop: '6',
        marginBottom: '3'
      },
      body: {
        marginTop: '0',
        marginBottom: '4'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  },
  BigHeadingTextButton: {
    captionScaleName: 'L',
    position: 'col',
    desktop: {
      align: 'center',
      heading: {
        marginTop: '6',
        marginBottom: '3'
      },
      body: {
        marginTop: '0',
        marginBottom: '4',
        maxWidth: '28rem'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  },
  BigHeadingText2: {
    captionScaleName: 'L',
    position: 'col',
    desktop: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '3'
      },
      body: {
        marginTop: '0',
        marginBottom: '0'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  },
  BigHeadingText: {
    captionScaleName: 'XL',
    position: 'col',
    desktop: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '3'
      },
      body: {
        marginTop: '0',
        marginBottom: '0'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  },
  HeadingTextButton: {
    captionScaleName: 'M',
    position: 'col',
    desktop: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  },
  SideImageBigHeadingTextButton: {
    captionScaleName: 'XL',
    position: 'row',
    orientation: 'left',
    desktop: {
      align: 'left',
      heading: {
        marginTop: '0',
        marginBottom: '3'
      },
      body: {
        marginTop: '1',
        marginBottom: '4'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '2'
      }
    }
  },
  SideImageHeadingTextButton: {
    captionScaleName: 'M',
    position: 'row',
    orientation: 'right',
    desktop: {
      align: 'left',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  },
  ImageTextTwoButtons: {
    captionScaleName: 'M',
    position: 'col',
    desktop: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  },
  ImageTextButton: {
    captionScaleName: 'M',
    position: 'col',
    desktop: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '5'
      },
      body: {
        marginTop: '0',
        marginBottom: '9',
        maxWidth: '24rem'
      }
    },
    mobile: {
      align: 'center',
      heading: {
        marginTop: '0',
        marginBottom: '2'
      },
      body: {
        marginTop: '0',
        marginBottom: '3'
      }
    }
  }
};

export default stylesByTheme;
