/* eslint-disable max-lines */
export default {
  v: '5.6.5',
  fr: 30,
  ip: 10,
  op: 30,
  w: 750,
  h: 750,
  nm: '0Home Fade up slow wazers 2',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: '▽ megaphone-with-sound-waves-svgrepo-com',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [217.5, 217.5, 0], ix: 2 },
            a: { a: 0, k: [115.5, 103.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          w: 231,
          h: 207,
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path Copy',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [216, 216, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-39.76, 0],
                        [-13.5, -13.5],
                        [0, -19.1],
                        [39.76, 0],
                        [0, 39.76]
                      ],
                      o: [
                        [19.1, 0],
                        [13.5, 13.5],
                        [0, 39.76],
                        [-39.76, 0],
                        [0, -39.76]
                      ],
                      v: [
                        [0, -72],
                        [50.91, -50.91],
                        [72, 0],
                        [0, 72],
                        [-72, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path Copy',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        }
      ]
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [129.897, 87.918, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [12.69, 3.92],
                        [0.08, 0.02],
                        [0, 0.67],
                        [0, 0],
                        [-0.66, 0.12],
                        [-1.73, 0.53],
                        [-3, 3.01]
                      ],
                      o: [
                        [-3, -3],
                        [-1.73, -0.53],
                        [-0.66, -0.13],
                        [0, 0],
                        [0, -0.67],
                        [0.08, -0.02],
                        [12.69, -3.91],
                        [0, 0]
                      ],
                      v: [
                        [13.844, 23.484],
                        [-8.936, 10.734],
                        [-12.566, 9.824],
                        [-13.846, 8.274],
                        [-13.846, -8.276],
                        [-12.566, -9.826],
                        [-8.936, -10.736],
                        [13.844, -23.486]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.800000011921, 0.800000011921, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [52.461, 87.914, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.01],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-8.111, 11.268],
                        [-8.111, 5.518],
                        [-8.111, -5.132],
                        [-8.111, -11.272],
                        [8.109, -11.272],
                        [8.109, -8.282],
                        [8.109, -8.272],
                        [8.109, 8.278],
                        [8.109, 8.288],
                        [8.109, 11.268],
                        [-8.111, 11.268]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.321568995714, 0.321568995714, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [59.467, 164.365, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.04, -0.05],
                        [0.18, 0],
                        [0, 0],
                        [0.16, 0.69],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0.04, 0.17],
                        [-0.03, 0.04],
                        [0, 0],
                        [-0.7, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [5.931, 9.931],
                        [5.921, 10.271],
                        [5.591, 10.361],
                        [0.411, 10.361],
                        [-1.279, 9.021],
                        [-5.959, -10.359],
                        [1.041, -10.359]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.949020028114, 0.956862986088, 0.968626976013, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [14.064, 88.491, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.48, 0],
                        [0.07, 0.09],
                        [0, 0],
                        [-0.47, 0],
                        [-0.07, -0.09],
                        [0, 0]
                      ],
                      o: [
                        [-0.47, 0],
                        [0, 0],
                        [0.07, -0.09],
                        [0.48, 0],
                        [0, 0],
                        [-0.07, 0.09]
                      ],
                      v: [
                        [-0.002, 5.567],
                        [-0.832, 5.277],
                        [-0.832, -5.273],
                        [-0.002, -5.563],
                        [0.828, -5.273],
                        [0.828, 5.277]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.949020028114, 0.956862986088, 0.968626976013, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [91.5, 103.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.26, 0],
                        [0.74, -0.91],
                        [0, 0],
                        [14.39, -4.56],
                        [0, 0],
                        [0.02, 0],
                        [0.35, -0.15],
                        [1.4, 0],
                        [0, 0],
                        [0, -2.12],
                        [0, 0],
                        [0.28, 0],
                        [0, -2.25],
                        [0, 0],
                        [-2.59, 0],
                        [-0.27, 0.04],
                        [0, 0],
                        [-2.13, 0],
                        [0, 0],
                        [0, 0],
                        [-2.48, 0],
                        [0, 0],
                        [-0.78, 0.99],
                        [0.32, 1.32],
                        [0, 0],
                        [0, 0],
                        [-0.67, 1.12],
                        [-0.28, -0.07],
                        [0, 0],
                        [0, 0],
                        [-0.54, -0.17],
                        [-0.06, -0.08],
                        [-0.88, -0.06],
                        [0, 0],
                        [-0.44, 0.89],
                        [0, 0.75],
                        [0, 0]
                      ],
                      o: [
                        [-0.89, 0],
                        [0, 0],
                        [-1.04, 1.23],
                        [0, 0],
                        [-1.52, 0.47],
                        [-0.38, 0.08],
                        [-0.67, -1.11],
                        [0, 0],
                        [-2.13, 0],
                        [0, 0],
                        [-0.27, -0.04],
                        [-2.59, 0],
                        [0, 0],
                        [0, 2.26],
                        [0.28, 0],
                        [0, 0],
                        [0, 2.12],
                        [0, 0],
                        [0, 0],
                        [0.58, 2.4],
                        [0, 0],
                        [1.35, 0],
                        [0.79, -0.99],
                        [0, 0],
                        [0, 0],
                        [1.4, 0],
                        [0.27, 0.12],
                        [0, 0],
                        [0, 0],
                        [0.5, 0.12],
                        [16.08, 4.96],
                        [0.73, 0.98],
                        [0, 0],
                        [0.94, 0],
                        [0.25, -0.51],
                        [0, 0],
                        [0, -3.22]
                      ],
                      v: [
                        [27.92, -34.5],
                        [25.46, -33.13],
                        [25.15, -32.74],
                        [3.22, -19.76],
                        [2.72, -19.61],
                        [-0.51, -18.81],
                        [-1.61, -18.46],
                        [-4.9, -20.32],
                        [-21.12, -20.32],
                        [-24.98, -16.47],
                        [-24.98, -14.35],
                        [-25.81, -14.42],
                        [-30.5, -10.33],
                        [-30.5, 0.32],
                        [-25.81, 4.41],
                        [-24.98, 4.35],
                        [-24.98, 6.08],
                        [-21.12, 9.93],
                        [-20.6, 9.93],
                        [-15.71, 30.22],
                        [-10.26, 34.5],
                        [-5.08, 34.5],
                        [-1.73, 32.94],
                        [-1, 29.31],
                        [-5.67, 9.93],
                        [-4.9, 9.93],
                        [-1.61, 8.06],
                        [-0.79, 8.35],
                        [0.54, 8.64],
                        [1.11, 8.78],
                        [2.72, 9.22],
                        [25.3, 22.53],
                        [27.73, 24.1],
                        [27.92, 24.11],
                        [30.13, 22.68],
                        [30.5, 20.8],
                        [30.5, -31.19]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.48, 0],
                        [0.07, 0.09],
                        [0, 0],
                        [-0.47, 0],
                        [-0.07, -0.09],
                        [0, 0]
                      ],
                      o: [
                        [-0.47, 0],
                        [0, 0],
                        [0.07, -0.09],
                        [0.48, 0],
                        [0, 0],
                        [-0.07, 0.09]
                      ],
                      v: [
                        [-25.814, 0.564],
                        [-26.644, 0.274],
                        [-26.644, -10.276],
                        [-25.814, -10.566],
                        [-24.984, -10.276],
                        [-24.984, 0.274]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.04, -0.05],
                        [0.18, 0],
                        [0, 0],
                        [0.16, 0.69],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0.04, 0.17],
                        [-0.03, 0.04],
                        [0, 0],
                        [-0.7, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-4.747, 30.219],
                        [-4.757, 30.559],
                        [-5.087, 30.649],
                        [-10.267, 30.649],
                        [-11.957, 29.309],
                        [-16.637, 9.929],
                        [-9.637, 9.929]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-4.904, 6.073],
                        [-21.124, 6.073],
                        [-21.124, -16.467],
                        [-4.904, -16.467]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3, 3.01],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [11.69, 3.61],
                        [0, 0],
                        [0, 0],
                        [0.06, 0.01],
                        [0, 0.67],
                        [0, 0],
                        [-0.61, 0.19],
                        [0, 0],
                        [-1.73, 0.53]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.37, -3.15],
                        [0, 0],
                        [0, 0],
                        [-1.35, -0.36],
                        [-0.66, -0.13],
                        [0, 0],
                        [0, -0.63],
                        [0, 0],
                        [0.08, -0.02],
                        [12.69, -3.91]
                      ],
                      v: [
                        [26.643, -28.68],
                        [26.643, 18.29],
                        [26.343, 18],
                        [25.853, 17.53],
                        [3.863, 5.54],
                        [3.233, 5.35],
                        [2.823, 5.24],
                        [0.233, 4.63],
                        [-1.047, 3.08],
                        [-1.047, -13.47],
                        [0.113, -14.99],
                        [0.233, -15.02],
                        [3.863, -15.93]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Shape',
              np: 7,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [196.5, 91.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.07, 2.77],
                        [0.94, -0.6],
                        [-0.65, -0.86],
                        [0, -2.74],
                        [1.61, -2.12],
                        [-0.93, -0.61],
                        [-0.41, 0],
                        [-0.41, 0.54],
                        [0, 3.57]
                      ],
                      o: [
                        [-0.65, -0.87],
                        [-0.94, 0.6],
                        [1.6, 2.14],
                        [0, 2.74],
                        [-0.65, 0.86],
                        [0.37, 0.23],
                        [0.65, 0],
                        [2.11, -2.79],
                        [0, -3.52]
                      ],
                      v: [
                        [0.33, -9.67],
                        [-2.55, -10.16],
                        [-3.08, -7.51],
                        [-0.64, -0.04],
                        [-3.13, 7.5],
                        [-2.62, 10.16],
                        [-1.43, 10.5],
                        [0.27, 9.68],
                        [3.5, -0.04]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [219, 90, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.94, 4.35],
                        [0.86, -0.6],
                        [-0.59, -0.87],
                        [0, -4.75],
                        [2.51, -3.67],
                        [-0.85, -0.6],
                        [-0.37, 0],
                        [-0.37, 0.53],
                        [0, 5.6]
                      ],
                      o: [
                        [-0.59, -0.87],
                        [-0.86, 0.6],
                        [2.5, 3.71],
                        [0, 4.74],
                        [-0.6, 0.87],
                        [0.33, 0.24],
                        [0.59, 0],
                        [2.99, -4.37],
                        [0, -5.53]
                      ],
                      v: [
                        [-0.49, -15.17],
                        [-3.11, -15.66],
                        [-3.59, -12.99],
                        [0.23, -0.06],
                        [-3.66, 12.98],
                        [-3.2, 15.65],
                        [-2.12, 16],
                        [-0.57, 15.18],
                        [4, -0.06]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: '▽ News Icon',
      parent: 15,
      refId: 'comp_0',
      sr: 1.13333333333333,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 11, s: [0] },
            { t: 24.0003734022691, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1243, 1275, 0], ix: 2 },
        a: { a: 0, k: [216, 216, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.041, 0.041, 0.667], y: [1.484, 1.484, 1] },
              o: { x: [0.372, 0.372, 0.333], y: [0, 0, 0] },
              t: 11,
              s: [-80, 80, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1.854] },
              o: { x: [0.308, 0.308, 0.333], y: [1.128, 1.128, 0] },
              t: 22,
              s: [-131.05, 131.05, 100]
            },
            { t: 29.9995691512279, s: [-125.926, 125.926, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [124.911, 0],
                [0, -124.911],
                [-124.911, 0],
                [0, 124.911]
              ],
              o: [
                [-124.911, 0],
                [0, 124.911],
                [124.911, 0],
                [0, -124.911]
              ],
              v: [
                [217, -8.172],
                [-9.172, 218],
                [217, 444.172],
                [443.172, 218]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 11, s: [-246] },
              { t: 24.0003734022691, s: [0] }
            ],
            ix: 4
          },
          nm: 'Mask 1'
        }
      ],
      tm: {
        a: 1,
        k: [
          { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 11, s: [0] },
          { t: 362.000315955766, s: [5] }
        ],
        ix: 2
      },
      w: 432,
      h: 432,
      ip: 11,
      op: 360,
      st: 11,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 3,
      nm: 'News Grey Block',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 11, s: [0] },
            { t: 24, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 11,
              s: [1614, -856, 0],
              to: [0, -13.333, 0],
              ti: [0, 13.333, 0]
            },
            { t: 24, s: [1614, -936, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [-100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 11,
      op: 360,
      st: 11,
      bm: 0
    }
  ],
  markers: []
};
