import React, { ReactElement } from 'react';
import { get } from 'lodash';

import { VerticalMenuOptionsType } from './VerticalMenu';
import { VerticalMenuChildren } from './VerticalMenuChildren';
import { VerticalMenuStyleThemeLink } from './VerticalMenuStyleThemeLink';

import ArrowIcon from 'icons/Arrow';

import { getComponentsByTheme, VerticalMenuOptionsPropsType } from './VerticalMenu';

export interface Props {
  option: VerticalMenuOptionsType;
  theme: string;
  onClickHandle: (props: { name: string, hasChildren: boolean }) => void;
  currentRole?: string;
  updateOptions: (
    options: VerticalMenuOptionsType[] | VerticalMenuOptionsPropsType[],
    id?: string | undefined,
    parents?: string[] | undefined
  ) => VerticalMenuOptionsType[];
  setVerticalMenuOptions: React.Dispatch<React.SetStateAction<VerticalMenuOptionsType[]>>;
  verticalMenuOptions: VerticalMenuOptionsType[];
}

export const VerticalMenuContents = ({
  option,
  theme,
  onClickHandle,
  currentRole,
  updateOptions,
  setVerticalMenuOptions,
  verticalMenuOptions
}: Props): ReactElement => {
  const { Container, VerticalMenuContent } = getComponentsByTheme(theme);

  return (
    <VerticalMenuContent key={option.id} isOpened>
      <Container
        onClick={() => {
          onClickHandle({
            ...option,
            name: get(option, 'codename'),
            hasChildren:
              option && option.children && option.children !== null
                ? option.children.length > 0
                : false
          });
          setVerticalMenuOptions(updateOptions(verticalMenuOptions, option.id));
        }}>
        <VerticalMenuStyleThemeLink
          theme={theme}
          option={option}
          onClickHandle={onClickHandle}
          verticalMenuOptions={verticalMenuOptions}
          setVerticalMenuOptions={setVerticalMenuOptions}
        />
        {theme === 'B' && option.children && option.children.length > 0 ? (
          <ArrowIcon direction={option.isOpened ? 'bottom' : 'top'} />
        ) : null}
      </Container>
      {option.children && option.children.length > 0 ? (
        <VerticalMenuChildren
          parents={[option.id]}
          options={option.children}
          isOpened={option.isOpened}
          theme={theme}
          currentRole={currentRole}
          handleClick={(
            id: string,
            childOption: VerticalMenuOptionsPropsType,
            parents?: string[]
          ) => {
            onClickHandle({
              name: get(childOption, 'id'),
              hasChildren:
                childOption && childOption.children && childOption.children !== null
                  ? childOption.children.length > 0
                  : false
            });
            setVerticalMenuOptions(updateOptions(verticalMenuOptions, id, parents || [option.id]));
          }}
        />
      ) : null}
    </VerticalMenuContent>
  );
};

export default VerticalMenuContents;
