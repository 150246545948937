import React from 'react';
import tw, { styled } from 'twin.macro';
import ReactModal from 'react-modal';

interface Props extends ReactModal.Props {
  className?: string;
}

export const ModalAdapter = ({ className, ...props }: Props) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
};

export const StyledModal = styled(ModalAdapter)`
  &__overlay {
    ${tw`
      z-50
    `}

    transition: opacity 0.8s ease-in-out;
    background: rgba(255, 255, 255, 0.9);
  }

  &__content {
    ${tw`
      border-none
      outline-none
      bg-gray
      rounded-none
      bottom-auto
      left-0
      right-0
      fixed
      text-center
      my-0
      mx-auto
      max-w-screen
      p-3
      top-1/2
      z-50
      duration-500
      ease-in-out
      opacity-0
      transition-opacity
    `}

    box-sizing: border-box;
    transform: translateY(-50%);

    &.ReactModal__Content--after-open {
      ${tw`
        h-full
        opacity-100
      `}

      background: rgba(255, 255, 255, 0.9);
      div:first-child {
        ${tw`
          h-full
          transition-opacity
        `}
      }
    }
  }
`;
