import React, { ReactElement, useState } from 'react';

import { Animation } from 'component';

import { LogoContainer } from './element';

export const Logo = (): ReactElement => {
  // @ts-ignore
  const [hovered, setHovered] = useState(false);
  return (
    <LogoContainer onMouseLeave={() => setHovered(false)} onMouseOver={() => setHovered(true)}>
      <Animation name="Logo" size="228px" show={true} triggerPlay={hovered} />
    </LogoContainer>
  );
};
export default Logo;
