import React, { ReactElement } from 'react';
import { get } from 'lodash';

import { StyledLinkByTheme } from './StyledLinkByTheme';
import { VerticalMenuOptionsType, updateOptions } from './VerticalMenu';

export const VerticalMenuStyleThemeLink = ({
  theme,
  option,
  onClickHandle,
  setVerticalMenuOptions,
  verticalMenuOptions
}: {
  theme?: string,
  option: VerticalMenuOptionsType,
  verticalMenuOptions: VerticalMenuOptionsType[],
  setVerticalMenuOptions: (e: VerticalMenuOptionsType[]) => void,
  onClickHandle: (props: { name: string, hasChildren: boolean }) => void
}): ReactElement => (
  <StyledLinkByTheme
    theme={theme}
    to={option.link || '/'}
    isActive={option.isActive}
    onClick={() => {
      onClickHandle({
        name: get(option, 'codename'),
        hasChildren:
          option && option.children && option.children !== null ? option.children.length > 0 : false
      });
      setVerticalMenuOptions(updateOptions(verticalMenuOptions, option.id));
    }}>
    {option.name}
  </StyledLinkByTheme>
);

export default VerticalMenuStyleThemeLink;
