import tw, { styled } from 'twin.macro';

export const Container = styled.main`
  ${tw`
    flex
    flex-col
    justify-center
    items-center
  `}
`;
