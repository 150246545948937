import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `);
  return site.siteMetadata;
};

interface Props {
  pathname?: string;
}

export const SiteMetadata = ({ pathname }: Props): ReactElement => {
  const { siteUrl, title } = useSiteMetadata();

  const href = `${siteUrl}${pathname}`;

  return (
    <Helmet defer={false} defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <link rel="canonical" href={href} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
    </Helmet>
  );
};

export default SiteMetadata;
