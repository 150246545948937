import React, { ReactElement } from 'react';
import { GatsbyLinkProps } from 'gatsby';

import {
  StyledLinkThemeA,
  StyledLinkThemeB,
  StyledLinkThemeC,
  StyledChildLinkThemeA,
  StyledChildLinkThemeB
} from './element';

/* eslint-disable @typescript-eslint/no-explicit-any, max-statements */
export const StyledLinkByTheme = ({
  theme,
  to,
  isActive,
  onClick,
  children
}: { theme?: string, isActive: boolean } & GatsbyLinkProps<any>): ReactElement => {
  if (theme === 'B') {
    return (
      <StyledLinkThemeB to={to} onClick={onClick}>
        {children}
      </StyledLinkThemeB>
    );
  }
  if (theme === 'A1') {
    return (
      <StyledChildLinkThemeA to={to} isActive={isActive || false} onClick={onClick}>
        {children}
      </StyledChildLinkThemeA>
    );
  }
  if (theme === 'B1') {
    return (
      <StyledChildLinkThemeB to={to} onClick={onClick}>
        {children}
      </StyledChildLinkThemeB>
    );
  }
  if (theme === 'C') {
    return <StyledLinkThemeC onClick={onClick}>{children}</StyledLinkThemeC>;
  }

  return (
    <StyledLinkThemeA to={to} isActive={isActive || false} onClick={onClick}>
      {children}
    </StyledLinkThemeA>
  );
};

export default StyledLinkByTheme;
