import tw, { css, styled } from 'twin.macro';
import { get } from 'lodash';
import { Link } from 'gatsby';

interface StyledButtonProps {
  disabled?: boolean;
  size?: ButtonSizeType;
  theme?: ButtonThemeType;
  download?: boolean;
  target?: string;
}

const buttonVariants = {
  sizes: {
    Large: tw`h-14 min-w-40 ms-2xl px-8`,
    Small: tw`h-12 min-w-28 ms-xl px-5`
  },
  theme: {
    PrimaryWhite: tw`bg-white text-black`,
    SecondaryWhite: tw`border-white text-black`,
    PrimaryBlue: tw`bg-wazeBlue text-black`
  }
};

export const StyledLinkButton = styled(Link)<StyledButtonProps>(
  // @ts-ignore
  ({ size, theme, disabled, download }) => [
    tw`
    flex
    justify-center
    box-border
    border-solid
    font-boing
    font-medium
    items-center
    max-w-80
    border-3
    rounded-full
    border-transparent
    no-underline
    hover:border-black
  `,
    get(buttonVariants, `sizes.${size}`, buttonVariants.sizes.Large),
    get(buttonVariants, `theme.${theme}`, buttonVariants.theme.PrimaryBlue),
    `transition: all 250ms ease-in-out;`,
    disabled ? tw`pointer-events-none` : '',
    download &&
      css`
        svg {
          margin-right: 1rem;
        }
      `
  ]
);

export const StyledAnchorButton = styled.a<StyledButtonProps>(
  ({ size, theme, disabled, download }) => [
    tw`
    flex
    justify-center
    box-border
    border-solid
    font-boing
    font-medium
    items-center
    max-w-80
    border-3
    rounded-full
    border-transparent
    no-underline
    hover:border-black
  `,
    get(buttonVariants, `sizes.${size}`, buttonVariants.sizes.Large),
    get(buttonVariants, `theme.${theme}`, buttonVariants.theme.PrimaryBlue),
    `transition: all 250ms ease-in-out;`,
    disabled ? tw`pointer-events-none` : '',
    download &&
      css`
        svg {
          margin-right: 1rem;
        }
      `
  ]
);
