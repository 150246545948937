import { transparentize } from 'polished';
import React, { ReactElement } from 'react';

import design from 'config/design';
import styled from 'styled-components';


const { colors, fontFamily } = design;
// import { colors, fontFamily } from 'config/design';


const SWATCH_HEIGHT = '100px';
const SWATCH_WIDTH = '100px';

const ColorItem = styled.div`
  ${({ colorName }: { colorName: string }) => `
    width: ${SWATCH_HEIGHT};
    height: ${SWATCH_WIDTH};
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: ${colors[colorName]};
    display: inline-block;
  `};
`;

const ColorName = styled.div`
  ${({ colorName }: { colorName: string }) => `
    display: flex;
    justify-content: space-between;
    padding: 5px;
    content: '${colorName}';
    font-weight: 500;
  `};
`;

const ColorNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  content: '';
  padding: 8px;
`;

const ColorWrapper = styled.div`
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 0 1px ${transparentize(0.91, colors['gray'])},
    0 0 1px 0 ${transparentize(0.87, colors['gray'])},
    0 2px 2px 0 ${transparentize(0.87, colors['gray'])};
  font-family: ${fontFamily['boing']};
  letter-spacing: 0.08em;
  font-size: 12px;
  line-height: 18px;
  padding: 20px;
`;

const ColorHex = styled.div`
  display: flex;
  justify-content: space-between;
  content: '';
  padding: 0px 0px 12px 12px;
  font-weight: normal;
  font-family: ${fontFamily['boing']};
  font-size: 10px;
`;

export const Color = ({ colorName }: { colorName: string }): ReactElement => (
  <ColorWrapper>
    <ColorItem colorName={colorName} />
    <ColorNameContainer>
      <ColorName colorName={colorName}>{colorName}</ColorName>
    </ColorNameContainer>
    <ColorHex>{colors[colorName]}</ColorHex>
  </ColorWrapper>
);

export default Color;
