import React, { createContext, ReactElement, ReactNode } from 'react';
import firebase, { auth, database } from './lib/firebase';
// import { navigate } from 'gatsby';

type AuthContextState = {
  permissionLevel: string,
  isLoadingInitial: boolean,
  isLoggedIn: boolean,
  profile: firebase.User | null
};

const defaultValue: AuthContextState = {
  profile: null,
  permissionLevel: 'LEVEL_UNKOWN',
  isLoadingInitial: false,
  isLoggedIn: false
};

const AuthContext = createContext(defaultValue);
const { Provider } = AuthContext;

type Props = {
  children: ReactNode
};

export class AuthContextProvider extends React.Component<Props, AuthContextState> {
  unsubscribeAuthWatch: firebase.Unsubscribe | null;
  tokenClaimsSubscribeRef: firebase.database.Reference | null;
  // @ts-ignore
  tokenClaimsCallback: (a: firebase.database.DataSnapshot, b?: string | null) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      permissionLevel: 'LEVEL_UNKNOWN',
      isLoadingInitial: true,
      isLoggedIn: false,
      profile: null
    };
  }

  componentDidMount(): void {
    // https://firebase.google.com/docs/auth/admin/custom-claims#best_practices_for_custom_claims
    // @ts-ignore
    this.unsubscribeAuthWatch = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Grab the current token for the user
        const tokenResult = await user.getIdTokenResult(true);
        // The level may not be set yet if they are a new signup
        // it happens in the background
        const permissionLevel = tokenResult.claims.level || 'LEVEL_UNKNOWN';

        // Set initial logged-in state
        this.setState({
          profile: user,
          permissionLevel,
          isLoadingInitial: false,
          isLoggedIn: true
        });

        // Token metadata subscribe routine
        // Watch the realtime time database for changes to the users claims
        // in their tokens. If the refreshTime changes, that means we need
        // to pull a new token.

        if (this.tokenClaimsSubscribeRef) {
          // Reset the subscription callback when auth state changes
          this.tokenClaimsSubscribeRef.off('value', this.tokenClaimsCallback);
        }

        // This callback invoked initially, and then as the claims may change
        this.tokenClaimsCallback = () => {
          // Fetch the actual token and parse the claims
          // @ts-ignore
          user.getIdTokenResult(true).then((tokenResult) => {
            const permissionLevel = tokenResult.claims.level || 'LEVEL_UNKNOWN';
            this.setState({
              permissionLevel
            });
          });
        };

        // Subscribe to the users token meta refresh time
        this.tokenClaimsSubscribeRef = database.ref('metadata/' + user.uid + '/refreshTime');
        this.tokenClaimsSubscribeRef.on('value', this.tokenClaimsCallback);
      } else {
        // IF the user isn't logged in...
        this.setState({
          profile: null,
          permissionLevel: 'LEVEL_UNKOWN',
          isLoadingInitial: false,
          isLoggedIn: false
        });
      }
    });
  }

  componentWillUnmount(): void {
    if (this.tokenClaimsSubscribeRef) {
      // Remove the token refresh callback
      this.tokenClaimsSubscribeRef.off('value', this.tokenClaimsCallback);
    }

    if (this.unsubscribeAuthWatch) {
      // Unwatch the user session
      this.unsubscribeAuthWatch();
    }
  }

  render(): ReactElement {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export default AuthContext;
