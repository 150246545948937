import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import { Image } from 'component';

import { WazerContainer, WazerContent } from './element';

interface WazerProps {
  image?: string;
  direction: 'left' | 'right';
  position?: string;
  animate?: boolean;
  width?: number;
}

export const Wazer = ({
  image,
  direction = 'left',
  position,
  animate,
  width = 63
}: WazerProps): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '100px 0px', triggerOnce: true });

  return (
    <WazerContainer
      direction={direction}
      position={position}
      animate={animate}
      ref={ref}
      show={inView}>
      <WazerContent direction={direction}>
        <Image width={width} src={image} />
      </WazerContent>
    </WazerContainer>
  );
};

export default Wazer;
