import React, { Fragment, ReactElement } from 'react';
import { StyledComponent } from 'styled-components';

import { VerticalMenuOptionsPropsType, VerticalMenuOptionsType } from './VerticalMenu';
import { StyledLinkByTheme } from './StyledLinkByTheme';
import { VerticalMenuChildren } from './VerticalMenuChildren';

export const VerticalSubMenuChildren = ({
  Container,
  handleClick,
  theme,
  option,
  parents
}: {
  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */
  Container: StyledComponent<any, any, object, string | number | symbol>,
  parents: string[],
  theme?: string,
  option: VerticalMenuOptionsType,
  handleClick: (id: string, option: VerticalMenuOptionsPropsType, parents?: string[]) => void
}): ReactElement => (
  <Fragment key={option.id}>
    <Container>
      <StyledLinkByTheme
        theme={theme}
        to={option.link || '/'}
        isActive={option.isActive || false}
        onClick={() => handleClick(option.id, option)}>
        {option.name}
      </StyledLinkByTheme>
    </Container>
    <VerticalMenuChildren
      parents={[...parents, option.id]}
      options={option.children || []}
      isOpened={option.isOpened}
      handleClick={(
        id: string,
        childOption: VerticalMenuOptionsPropsType,
        innerParents?: string[]
      ) => handleClick(id, childOption, innerParents ? [...innerParents, option.id] : [option.id])}
    />
  </Fragment>
);

export default VerticalSubMenuChildren;
