import React, { ReactElement } from 'react';

import { StyleByTheme, StyleByThemeViewport } from './blockItemStylesWithTheme';
import { ParagraphContent } from './element';

import Paragraph from 'component/Paragraph';

export const BlockItemParagraph = ({
  captionScale,
  styles,
  foregroundColor,
  desktopAlign,
  desktopMargin,
  mobileAlign,
  mobileMargin,
  bodyClickHandler,
  withMaxWidth,
  body
}: {
  desktopAlign: string,
  styles: StyleByTheme,
  captionScale?: CaptionScaleNameType,
  foregroundColor?: string,
  mobileAlign: string,
  desktopMargin: StyleByThemeViewport,
  mobileMargin: StyleByThemeViewport,
  bodyClickHandler?: () => void,
  withMaxWidth: boolean,
  body?: string
}): ReactElement => {
  /* eslint-disable prettier/prettier */
  const captionStyleOveride:CaptionScaleNameType = (styles.captionScaleName as CaptionScaleNameType);;

  return (
    <ParagraphContent withMaxWidth={withMaxWidth}>
      <Paragraph
        bodyClickHandler={bodyClickHandler}
        markdownText={body}
        captionScaleName={captionScale || captionStyleOveride}
        textStyle={{
          color: foregroundColor,
          textAlign: desktopAlign,
          ...desktopMargin.body
        }}
        textStyleForMobile={{ textAlign: mobileAlign, ...mobileMargin.body }}
      />
    </ParagraphContent>
  );
};

export default BlockItemParagraph;
