import React, { ChangeEvent, FocusEvent, ReactElement } from 'react';

import { StyledFormTextArea } from './element';

interface InputTextField {
  field: {
    name: string,
    value: string,
    onChange: (props: ChangeEvent<HTMLTextAreaElement>) => void,
    onBlur: (e: FocusEvent<HTMLTextAreaElement>) => void
  };
  id?: string;
  type?: string;
  withoutBorder?: boolean;
  hasError?: boolean;
}

export const FormTextArea = ({
  field,
  withoutBorder = false,
  hasError = false,
  ...props
}: InputTextField): ReactElement => {
  return (
    <StyledFormTextArea hasError={hasError} withoutBorder={withoutBorder}>
      <textarea type={props.type || 'text'} {...field} {...props} />
    </StyledFormTextArea>
  );
};

export default FormTextArea;
