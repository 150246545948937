import React, { ReactElement, useContext } from 'react';

import { BurgerIcon } from 'icons';

import VerticalMenu from 'component/VerticalMenu';
import { getNavAction } from 'lib/nav';
import { auth } from 'lib/firebase';
import { MenuOptionType } from './Menu';
import {
  MobileNavContainer,
  MobileMenuContainer,
  MobileMenuBackground,
  MobileMenuContent,
  VerticalMenuContainer,
  BurgerIconContainer,
  MobileMenuContentWithPadding,
  MobileMenuGradientContainer,
  MobileMenuGradient
} from './element';
import AuthContext from 'AuthContext';

export const MobileMenu = ({
  options,
  isOpen,
  toggle,
  currentRole,
  toggleChangePasswordModal,
  isLoggedIn
}: {
  options: MenuOptionType[],
  isOpen: boolean,
  toggle: (e: boolean) => void,
  currentRole?: string,
  toggleChangePasswordModal: () => void,
  isLoggedIn?: boolean
}): ReactElement => {
  const { profile } = useContext(AuthContext);

  // const navOptions = options.filter((opt: MenuOptionType) => !opt.loggedInOnly);

  return (
    <MobileNavContainer>
      <BurgerIconContainer onClick={() => toggle(!isOpen)}>
        <BurgerIcon isOpen={isOpen} />
      </BurgerIconContainer>
      <MobileMenuContainer>
        <MobileMenuBackground isOpen={isOpen}>
          <MobileMenuContentWithPadding>
            <MobileMenuContent>
              <VerticalMenuContainer>
                <VerticalMenu
                  options={options}
                  theme="B"
                  isLoggedIn={isLoggedIn}
                  currentRole={currentRole}
                  onClickHandle={(option: { action?: string, hasChildren?: boolean }) => {
                    if (option.action) {
                      getNavAction({
                        action: option.action,
                        toggleChangePasswordModal
                      })({
                        auth,
                        profile
                      });
                    }
                    if (option.hasChildren === false) {
                      toggle(!isOpen);
                    }
                  }}
                />
              </VerticalMenuContainer>
            </MobileMenuContent>
          </MobileMenuContentWithPadding>
          <MobileMenuGradientContainer>
            <MobileMenuGradient />
          </MobileMenuGradientContainer>
        </MobileMenuBackground>
      </MobileMenuContainer>
    </MobileNavContainer>
  );
};

export default MobileMenu;
