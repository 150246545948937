import tw, { styled } from 'twin.macro';

export const AlertModalContainer = styled.div`
  display: flex;
  align-items: center;
`;

/* stylelint-disable selector-type-no-unknown */
export const AlertContainer = styled.div`
  ${tw`
  flex
  flex-col
  shadow-none
  items-center
  bg-wazeYellow
  p-10
  font-boing
  border
  rounded-cardRadius
  justify-center
  duration-300
  ease-in-out
  transition-all
  translate-y-4
  transform
  justify-center
  items-center
  mx-auto
  w-1/2
  max-h-screen-1/2
  md:max-h-full
  md:w-full
  md:min-w-full
  md:pt-18
  `}

  min-width: 440px;

  > div:last-child {
    padding-top: 24px;
  }

  h4 {
    font-size: 2.25rem;
  }

  button {
    &::after,
    &::before {
      height: 2px;
    }
  }

  div {
    ${tw`px-0`}
  }

  div > div {
    width: 95%;
    ${tw`mx-auto md:w-full`}
  }

  img {
    ${tw`
      md:h-auto
      md:px-3
    `}

    ${tw`
    md:p-0
    delay-700
    duration-500
    ease-in-out
    transition-opacity`}
      padding-top: 3rem;
  }
`;

export const ModalClose = styled.button`
  ${tw`
    bg-transparent
    cursor-pointer
    absolute
    top-4
  `}
  appearance: none;
  border: 0;
  cursor: pointer;
  height: 44px;
  right: 1rem;
  top: 1rem;
  width: 40px;
  z-index: 3;

  &::after,
  &::before {
    background-color: black;
    content: '';
    height: 1px;
    left: 50%;
    margin-left: -15px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 30px;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`;
