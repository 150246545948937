import React, { ReactNode, ReactElement } from 'react';
import { get } from 'lodash';

import Box from 'component/Box/Box';
import Wazer from 'component/Wazer/Wazer';

import { blocksByAccentNames, BlocksItem } from './blockWithDecoratorsConfig';

import {
  BlockWithDecoratorsContainer,
  Block,
  BlocksContainer,
  BlocksContent,
  BlockWithDecoratorsContent
} from './element';

interface BlockWithDecoratorsProps {
  leftDecorator?: DecoratorType;
  rightDecorator?: DecoratorType;
  backgroundColor?: string;
  flipHorizontal?: boolean;
  splitRows?: boolean;
  wazer?: Wazer;
  children?: ReactNode;
  idx?: number;
  show?: boolean;
  twoUpPadding?: boolean;
  bottomPadding?: boolean;
}

export const BlockWithDecorators = ({
  leftDecorator,
  rightDecorator,
  backgroundColor = 'gray',
  flipHorizontal = false,
  splitRows = false,
  wazer,
  children,
  idx,
  show,
  twoUpPadding = false,
  bottomPadding = false
}: BlockWithDecoratorsProps): ReactElement => {
  const leftDecoratorType = get(leftDecorator, 'type', 'rectangle');
  const rightDecoratorType = get(rightDecorator, 'type', 'rectangle');
  const leftDecoratorColor = get(leftDecorator, 'color', 'wazeOrange');
  const rightDecoratorColor = get(rightDecorator, 'color', 'wazeOrange');
  const blockVariants = blocksByAccentNames(
    leftDecoratorColor,
    rightDecoratorColor,
    backgroundColor
  );
  const blocks: BlocksItem[] = blockVariants[`${leftDecoratorType}_${rightDecoratorType}`];

  const hasBottomPadding = [
    bottomPadding || leftDecoratorType === 'square' || rightDecoratorType === 'square'
  ].some((v: boolean) => !v);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <BlockWithDecoratorsContainer idx={idx} show={show} hasBottomPadding={hasBottomPadding}>
      <BlocksContainer flipHorizontal={flipHorizontal} splitRows={splitRows}>
        <BlocksContent>
          {blocks.map((block: any, index: number) => (
            <Block
              key={`blocks-with-decorators-${index}`}
              columns={block.columns}
              hideOnMobile={block.hideOnMobile}>
              <Box
                flipHorizontal={flipHorizontal}
                direction={block.direction}
                backgroundColor={block.backgroundColor}
                squarePosition={block.squarePosition}
                squareColor={block.squareColor}
                orientation={block.orientation}
                disableOnMobile={block.disableOnMobile}
                decorators={{
                  left: block.left,
                  right: block.right
                }}
                twoUpPadding={twoUpPadding}
              />
            </Block>
          ))}
        </BlocksContent>
      </BlocksContainer>
      <BlockWithDecoratorsContent>{children}</BlockWithDecoratorsContent>
      <Wazer
        image={get(wazer, 'image')}
        direction={get(wazer, 'direction', 'left')}
        position={get(wazer, 'position')}
        animate={get(wazer, 'animate')}
        width={get(wazer, 'width')}
      />
    </BlockWithDecoratorsContainer>
  );
};

export default BlockWithDecorators;
