import { get } from 'lodash';

import { signOut } from 'lib/account';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const nullAction = (): void => {};

export const getNavAction = ({
  action,
  toggleChangePasswordModal
}: {
  action: string,
  toggleChangePasswordModal: () => void
  // @ts-ignore
}): ((props: { [e: string]: firebase.auth.Auth | firebase.User | null }) => void) => {
  const ALLOWED_LOGIN_ACTIONS = {
    signOut: signOut,
    toggleChangePasswordModal: toggleChangePasswordModal
  };

  return get(ALLOWED_LOGIN_ACTIONS, action, nullAction);
};
