import React, { ReactNode, ReactElement } from 'react';

import { StyledFormField } from './element';

interface Props {
  children?: ReactNode;
  disabled?: boolean;
}

export const FormField = ({ children }: Props): ReactElement => (
  <StyledFormField>{children}</StyledFormField>
);

export default FormField;
