/* eslint-disable max-lines */
// import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { get } from 'lodash';
import { getDesignSystemValue } from 'config/design';

interface BoxContainer {
  direction?: 'left' | 'right' | 'center';
  justifyEnd?: boolean;
}

interface DiagonalShape {
  backgroundColor?: string;
  orientation?: 'right' | 'left';
  leftDecorator?: {
    name?: string
  };
  rightDecorator?: {
    name?: string
  };
  disableOnMobile?: boolean;
  shrinkHeight?: boolean;
}

interface SquareShapeProps {
  backgroundColor?: string;
  orientation?: 'right' | 'left';
  leftDecorator?: {
    name?: string
  };
  rightDecorator?: {
    name?: string
  };
  disableOnMobile?: boolean;
}

interface SquareBackgroundProps {
  backgroundColor?: string;
  orientation?: 'right' | 'left';
}

interface SquareProps {
  backgroundColor?: string;
  orientation?: 'right' | 'left';
}

interface ContentProps {
  leftDecorator?: {
    name?: string
  };
  rightDecorator?: {
    name?: string
  };
}

const diagonalDecorators = {
  rectangle_diagonal: {
    width: tw` w-full-2`,
    rounded: tw`
      rounded-tl-none
      rounded-bl-none
      rounded-tr-cardRadius
      rounded-br-cardRadius
      sm:rounded-tl-none
      sm:rounded-tr-none
      sm:rounded-br-cardRadius
      sm:rounded-bl-cardRadius
    `,
    disableOnMobile: tw`
      sm:skew-x-0
      sm:skew-y-0
    `,
    orientation: {
      left: tw`
        transform
        skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:skew-y-8
      `,
      right: tw`
        transform
        -skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:-skew-y-8
      `
    },
    padding: tw`sm:pb-3`
  },
  diagonal_rectangle: {
    width: tw`w-full-2`,
    rounded: tw`
      rounded-tl-cardRadius
      rounded-bl-cardRadius
      rounded-tr-none
      rounded-br-none
      sm:rounded-tl-cardRadius
      sm:rounded-tr-cardRadius
      sm:rounded-br-none
      sm:rounded-bl-none
    `,
    disableOnMobile: tw`
      sm:skew-x-0
      sm:skew-y-0
    `,
    orientation: {
      left: tw`
        transform
        skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:skew-y-8
      `,
      right: tw`
        transform
        -skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:-skew-y-8
      `
    },
    padding: tw`sm:pt-3`
  },
  rectangle_rectangle: {
    width: tw` w-full`,
    rounded: tw`
      rounded-none
    `,
    orientation: {
      left: '',
      right: ''
    },
    padding: tw`sm:pb-3`
  },
  diagonal_diagonal: {
    width: tw` w-full`,
    rounded: tw`
      rounded-cardRadius
    `,
    disableOnMobile: tw`
      sm:skew-x-0
      sm:skew-y-0
    `,
    orientation: {
      left: tw`
        transform
        skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:skew-y-8
      `,
      right: tw`
        transform
        -skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:-skew-y-8
      `
    },
    padding: tw`sm:pb-3`
  }
};

const squareDecoratos = {
  square_rectangle: {
    position: tw`right-0`,
    container: {
      before: [
        tw`
          h-max-full-gap-3-square-full
          left-0
          top-0
          pl-3
          rounded-l-cardRadius
          sm:h-flexible-full-gap-2-square-full
        `
      ],
      after: [
        tw`
          w-max-full-gap-3-square-full
          bottom-0
          right-0
          pb-3
          rounded-b-cardRadius
          sm:w-flexible-full-gap-2-square-full
        `
      ]
    },
    background: {
      parent: [
        css`
          right: 0;
        `,
        tw`
          h-max-full-gap-3
          w-max-full-gap-3
          rounded-tr-cardRadius
          sm:h-flexible-full-gap-2
          sm:w-flexible-full-gap-2
        `
      ],
      before: [
        tw`
          rounded-tr-max-square-1/2plusCardRadius
          left-0
          bottom-0
          sm:rounded-tr-flexible-square-1/2plusCardRadius
        `
      ]
    },
    square: [
      tw`
        left-0
        bottom-0
        rounded-tr-max-square-1/2-cardRadius
        rounded-tl-cardRadius
        rounded-br-cardRadius
        rounded-bl-cardRadius
        sm:rounded-tr-flexible-square-1/2-cardRadius
      `
    ]
  },
  rectangle_square: {
    position: tw`left-0`,
    container: {
      before: [
        tw`
          h-max-full-gap-3-square-full
          right-0
          top-0
          pr-3
          rounded-r-cardRadius
          sm:h-flexible-full-gap-2-square-full
        `
      ],
      after: [
        tw`
          w-max-full-gap-3-square-full
          bottom-0
          left-0
          pb-3
          rounded-b-cardRadius
          sm:w-flexible-full-gap-2-square-full
        `
      ]
    },
    background: {
      parent: [
        css`
          left: 0;
        `,
        tw`
          h-max-full-gap-3
          w-max-full-gap-3
          rounded-tl-cardRadius
          sm:h-flexible-full-gap-2
          sm:w-flexible-full-gap-2
        `
      ],
      before: [
        tw`
          rounded-tl-max-square-1/2plusCardRadius
          right-0
          bottom-0
          sm:rounded-tl-flexible-square-1/2plusCardRadius
        `
      ]
    },
    square: [
      tw`
        right-0
        bottom-0
        rounded-tl-max-square-1/2-cardRadius
        rounded-tr-cardRadius
        rounded-br-cardRadius
        rounded-bl-cardRadius
        sm:rounded-tl-flexible-square-1/2-cardRadius
      `
    ]
  },
  diagonal_square: {
    position: tw`left-0`,
    rounded: tw`
      rounded-tl-cardRadius
      rounded-bl-cardRadius
      rounded-tr-none
      rounded-br-none
      sm:rounded-tl-cardRadius
      sm:rounded-tr-cardRadius
      sm:rounded-br-none
      sm:rounded-bl-none
    `,
    disableOnMobile: tw`
      sm:skew-x-0
      sm:skew-y-0
    `,
    orientation: {
      left: tw`
        transform
        skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:skew-y-8
      `,
      right: tw`
        transform
        -skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:-skew-y-8
      `
    },
    padding: tw`sm:pb-3`
  },
  square_diagonal: {
    position: tw`right-0`,
    rounded: tw`
      rounded-tl-none
      rounded-bl-none
      rounded-tr-cardRadius
      rounded-br-cardRadius
      sm:rounded-tl-none
      sm:rounded-tr-none
      sm:rounded-br-cardRadius
      sm:rounded-bl-cardRadius
    `,
    disableOnMobile: tw`
      sm:skew-x-0
      sm:skew-y-0
    `,
    orientation: {
      left: tw`
        transform
        skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:skew-y-8
      `,
      right: tw`
        transform
        -skew-x-8
        skew-y-0
        sm:skew-x-0
        sm:-skew-y-8
      `
    },
    padding: tw`sm:pb-3`
  }
};

const defaults = {
  leftDecorator: {
    name: 'rectangle'
  },
  rightDecorator: {
    name: 'diagonal'
  }
};

const directions = {
  left: tw`justify-end items-end`,
  right: tw`justify-start items-start`,
  center: ''
};

export const Container = styled.div`
  ${tw`
    flex
    min-h-full
    relative
    rounded-cardRadius
  `};
`;

export const BoxContainer = styled.div(
  ({ direction = 'center', justifyEnd = false }: BoxContainer) => {
    return [
      tw`
    flex
    min-h-64
    rounded-cardRadius
    relative
    w-full
    sm:min-h-48
  `,
      justifyEnd && direction === 'left' ? tw`justify-end items-start` : directions[direction]
    ];
  }
);

interface SquareShapeContainerProps {
  direction?: 'center' | 'left' | 'right' | undefined;
  twoUpPadding?: boolean;
}

export const SquareShapeContainer = styled.div(
  ({ direction = 'center', twoUpPadding }: SquareShapeContainerProps) => [
    tw`
    flex
    min-h-64
    rounded-cardRadius
    absolute
    h-full
    w-full
  `,
    directions[direction === 'left' ? 'right' : 'left'],
    twoUpPadding && tw`justify-start items-end`
  ]
);

// export const DiagonalShape = ({
//   backgroundColor = 'gray',
//   orientation = 'right',
//   leftDecorator = defaults.leftDecorator,
//   rightDecorator = defaults.rightDecorator,
//   disableOnMobile,
//   shrinkHeight
// }: DiagonalShape) => {
//   console.log(`-----------------${backgroundColor}---------------------------`)
//   // console.log(tw`
//   // absolute
//   // origin-center
//   // h-full
//   // z-10
//   // sm:w-full
//   // sm:h-3/2`)
//   // console.log(shrinkHeight ? tw`h-24` : ``)
//   // console.log(css`
//   //   background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
//   // `)
//   console.log(
//     // diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].width,
//     // diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].rounded,
//     diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].orientation[orientation],
//     // disableOnMobile
//     //   ? diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].disableOnMobile
//     //   : ''
//   )

//   console.log('******************')
// console.log(tw`
// skew-x-8
// skew-y-0
// sm:skew-x-0
// sm:skew-y-8
// `)
//   console.log('******************')
//   console.log('--------------------------------------------')
//   return (
//     <div>
//       <p>TESTING</p>
//     </div>
//   )
// }

export const DiagonalShape = styled.div(
  ({
    backgroundColor = 'gray',
    orientation = 'right',
    leftDecorator = defaults.leftDecorator,
    rightDecorator = defaults.rightDecorator,
    disableOnMobile,
    shrinkHeight
  }: DiagonalShape) => {
    return [
      tw`
      absolute
      origin-center
      h-full
      z-10
      sm:w-full
      sm:h-3/2
    `,
      shrinkHeight ? tw`h-24` : ``,
      css`
        background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
      `,
      diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].width,
      diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].rounded,
      diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].orientation[orientation],
      disableOnMobile
        ? diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`].disableOnMobile
        : ''
    ];
  }
);

export const DiagonalShapeAndSquare = styled.div(
  ({
    backgroundColor = 'gray',
    orientation = 'right',
    leftDecorator = defaults.leftDecorator,
    rightDecorator = defaults.rightDecorator,
    disableOnMobile
  }: DiagonalShape) => [
    tw`
      absolute
      origin-center
      h-full
      z-10
    `,
    css`
      width: 20%;
    `,
    css`
      background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
    `,
    get(squareDecoratos[`${leftDecorator.name}_${rightDecorator.name}`], 'position', ''),
    get(squareDecoratos[`${leftDecorator.name}_${rightDecorator.name}`], 'rounded', ''),
    get(
      squareDecoratos[`${leftDecorator.name}_${rightDecorator.name}`],
      `orientation[${orientation}]`,
      ''
    ),
    disableOnMobile
      ? get(squareDecoratos[`${leftDecorator.name}_${rightDecorator.name}`], `disableOnMobile`, '')
      : ''
  ]
);

export const SquareShape = styled.div(
  ({ backgroundColor = 'gray', orientation = 'left' }: SquareShapeProps) => [
    tw`
      relative
      h-full
    `,
    css`
      width: 90%;
      &:before {
        content: '';
        background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
        ${tw`
          absolute
        `}
        ${squareDecoratos[orientation === 'left' ? 'square_rectangle' : 'rectangle_square']
          .container.before}
      }
      &:after {
        content: '';
        background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
        ${tw`
          absolute
        `}
        ${squareDecoratos[orientation === 'left' ? 'square_rectangle' : 'rectangle_square']
          .container.after}
      }
    `
  ]
);

export const SquareBackground = styled.div(
  ({ backgroundColor = 'gray', orientation = 'left' }: SquareBackgroundProps) => [
    tw`
      absolute
      overflow-hidden
    `,
    css`
      &:before {
        content: '';
        position: absolute;
        box-shadow: 0 0 0 200vw ${getDesignSystemValue(`colors.${backgroundColor}`)};
        ${squareDecoratos[orientation === 'left' ? 'square_rectangle' : 'rectangle_square']
          .background.before}
        ${tw`
          absolute
          h-max-square-full
          w-max-square-full
          sm:h-flexible-square-full
          sm:w-flexible-square-full
        `}
      }
    `,
    squareDecoratos[orientation === 'left' ? 'square_rectangle' : 'rectangle_square'].background
      .parent
  ]
);

export const Square = styled.div(
  ({ backgroundColor = 'wazeOrange', orientation = 'left' }: SquareProps) => [
    tw`
      absolute
      h-max-square-full
      w-max-square-full
      sm:h-flexible-square-full
      sm:w-flexible-square-full
    `,
    squareDecoratos[orientation === 'left' ? 'square_rectangle' : 'rectangle_square'].square,
    css`
      background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)};
    `
  ]
);

export const Content = styled.div<ContentProps>(
  ({ leftDecorator, rightDecorator }: ContentProps) => [
    tw`
      flex
      items-center
      justify-center
      w-full
      h-full
      z-20
    `,
    leftDecorator &&
      rightDecorator &&
      get(diagonalDecorators[`${leftDecorator.name}_${rightDecorator.name}`], 'padding')
  ]
);
