import tw, { css, styled } from 'twin.macro';
import { StyledFunction } from 'styled-components';
import { Link } from 'gatsby';

export const VerticalMenuContainer = styled.div`
  ${tw`
    inline-block
    w-full
  `};
`;

export const Nav = styled.nav`
  ${tw`
    flex
    flex-col
    justify-start
  `};
`;

export const NavThemeA = styled(Nav)``;

export const NavThemeB = styled(Nav)`
  ${tw`
    w-full
  `};
`;

export const Container = styled.div`
  ${tw`
    inline-flex
    items-center
    justify-between
  `};
`;

export const ContainerThemeA = styled(Container)`
  ${tw`
    min-h-0
  `};
  margin: 11px 0;
`;

export const ContainerThemeB = styled(Container)`
  ${tw`
    min-h-12
  `};
`;

const link: StyledFunction<{ isActive?: boolean } & typeof Link> = styled(Link);

export const StyledLink = link`
  ${tw`
    font-boing
    font-medium
    text-black
    leading-none
    no-underline
    text-lg
    text-left
    relative
    cursor-pointer
    inline-block
    outline-none
    p-0
  `};
  background: none;
  border: none;
  width: fit-content;
`;

export const StyledLinkThemeA = styled(StyledLink)`
  :after {
    ${tw`
      bg-black
      absolute
      left-0
      w-0
      duration-500
      ease-in-out
    `};
    content: '';
    bottom: -6px;
    height: 2px;
    transition-property: width;
    width: ${({ isActive }: { isActive?: boolean }): string => {
      return isActive ? '100%' : '0px';
    }};
  }

  :hover:after {
    ${tw`
      w-full
    `};
  }
`;

export const StyledLinkThemeB = styled(StyledLink)``;

export const StyledLinkThemeC = styled.span(() => [
  tw`
    font-boing
    font-medium
    text-black
    leading-none
    no-underline
    text-lg
    text-left
    relative
    cursor-pointer
    inline-block
    outline-none
    p-0
  `,
  css`
    background: none;
    border: none;
    width: fit-content;
  `
]);

export const ChildrenVerticalMenuContainer = styled.div`
  ${tw`
    flex
    flex-col
    justify-start
    pl-1
    overflow-hidden
    duration-500
    ease-in-out
  `};
  transition-property: max-height;
  max-height: ${({ isOpened }: { isOpened?: boolean }): string => {
    return isOpened ? '9999px' : '0px';
  }};
`;

export const VerticalMenuContent = styled(ChildrenVerticalMenuContainer)`
  ${tw`
    max-h-full
    pl-0
    h-auto
  `};
  overflow: initial;
`;

export const VerticalMenuContentThemeA = styled(VerticalMenuContent)`
  ${tw`
    min-h-10
  `};
`;

export const VerticalMenuContentThemeB = styled(VerticalMenuContent)`
  ${tw`
    min-h-12
  `};
`;

export const StyledChildLinkThemeA = styled(StyledLinkThemeA)`
  ${tw`
    font-normal
  `};
`;

export const StyledChildLinkThemeB = styled(StyledLinkThemeB)`
  ${tw`
    font-normal
  `};
`;
