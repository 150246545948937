import tw, { styled, TwStyle } from 'twin.macro';

interface ImgProps {
  size?: ImageSizeNameType;
}

const imagesSizing = {
  XXLarge: tw`w-128 sm:w-64`,
  XLarge: tw`w-80 sm:w-48`,
  Large: tw`w-64 sm:w-32`,
  Med: tw`w-40 sm:w-32`,
  Small: tw`w-32`
};

export const Img = styled.img`
  ${({ size }: ImgProps): TwStyle => size && imagesSizing[size]}
`;
