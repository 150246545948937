import tw, { styled, css } from 'twin.macro';

export const DropdownContainer = styled.div`
  ${tw`
    flex
    justify-between
    items-center
    relative
    bg-gray2
    rounded-cardRadius
    h-13
    w-full
    p-1
    pl-3
    cursor-pointer
  `};
`;

export const OptionSelected = styled.p`
  ${tw`
    font-boing
    font-medium
    pointer-events-none
  `}
`;

export const ArrowContainer = styled.div`
  ${tw`
    flex
    justify-center
    items-center
    h-12
    w-12
    pointer-events-none
  `}
`;

interface OptionsContainerProps {
  isDropdownOpened: boolean;
}

export const OptionsContainer = styled.div(({ isDropdownOpened }: OptionsContainerProps) => [
  tw`
    absolute
    bg-white
    rounded-cardRadius
    min-w-48
    px-3
    overflow-hidden
    duration-500
    ease-in-out
    z-10
  `,
  css`
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    transition-property: max-height;
    > div {
      ${tw`my-3`}
    }
  `,
  isDropdownOpened
    ? css`
        max-height: 9999px;
      `
    : css`
        max-height: 0px;
      `
]);
