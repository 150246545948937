import tw, { css, styled } from 'twin.macro';

interface BlockProps {
  columns: string;
  hideOnMobile: boolean;
}

interface BlockWithDecoratorsContainer {
  hasBottomPadding: boolean;
  show?: boolean;
  idx?: number;
}

const blockGridColumns = {
  '1': tw`col-span-1 sm:col-span-12`,
  '2': tw`col-span-2 sm:col-span-12`,
  '3': tw`col-span-3 sm:col-span-12`,
  '4': tw`col-span-4 sm:col-span-12`,
  '5': tw`col-span-5 sm:col-span-12`,
  '6': tw`col-span-6 sm:col-span-12`,
  '7': tw`col-span-7 sm:col-span-12`,
  '8': tw`col-span-8 sm:col-span-12`,
  '9': tw`col-span-9 sm:col-span-12`,
  '10': tw`col-span-10 sm:col-span-12`,
  '11': tw`col-span-11 sm:col-span-12`,
  '12': tw`col-span-12 sm:col-span-12`
};

export const BlockWithDecoratorsContainer = styled.div(
  ({ show, hasBottomPadding, idx = 1 }: BlockWithDecoratorsContainer) => [
    tw`
    grid
    grid-flow-col
    grid-cols-12
    gap-3
    relative
    w-full
    h-full
    min-h-64
    duration-700
    ease-in-out
    transition-all
    translate-y-4
    transform
    opacity-0
  `,
    show ? tw`opacity-100` : tw`opacity-0`,
    show ? tw`translate-y-0` : tw`translate-y-4`,
    hasBottomPadding && tw`pb-12 sm:pb-10`,
    css`
      transition-delay: ${idx ? (idx + 1) * 375 : 375}ms;
    `
  ]
);

export const Block = styled.div(({ columns, hideOnMobile }: BlockProps) => [
  tw`h-full`,
  blockGridColumns[columns],
  hideOnMobile && tw`sm:hidden`
]);

interface BlocksContainerProps {
  flipHorizontal?: boolean;
  splitRows?: boolean;
}

export const BlocksContainer =
  styled.div <
  BlocksContainerProps >
  `
  ${tw`
    absolute
    w-full
    h-full
    min-h-64
    z-0
  `}
  ${({ splitRows = false }) => splitRows && tw`grid row-start-1 row-end-1`}
  ${({ flipHorizontal = false }) =>
    flipHorizontal &&
    css`
      transform: scaleY(-1);
    `}
`;

export const BlocksContent = styled.div`
  ${tw`
    grid
    grid-flow-col
    grid-cols-12
    gap-3
    overflow-hidden
    rounded-cardRadius
    w-full
    h-full
  `}
`;

export const BlockWithDecoratorsContent = styled.div`
  ${tw`
    col-start-1
    col-end-13
    w-full
    h-full
  `}
`;
