import React, { ReactNode, ReactElement, useState } from 'react';

import DownloadArrow from 'illustration/DownloadArrow';

import { StyledLinkButton, StyledAnchorButton } from './element';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  children: ReactNode;
  theme?: ButtonThemeType;
  size?: ButtonSizeType;
  link?: string;
  disabled?: boolean;
  download?: boolean;
  target?: string;
  tag?: 'link' | 'anchor';
  onClick?: (props?: any) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const Button = ({
  children,
  theme,
  size,
  link = '/',
  disabled = false,
  download,
  target,
  tag,
  onClick
}: Props): ReactElement => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonProps = {
    theme,
    size,
    disabled,
    target: target,
    onMouseOver: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
    download: download
  };

  if (
    tag === 'anchor' ||
    // Hijack direct links to /_downloads because these are files
    // not pages.
    link.startsWith('/_downloads')
  ) {
    return (
      <StyledAnchorButton
        {...(onClick
          ? {
              onClick: (e: any, ...props) => {
                e.preventDefault();
                onClick(props);
              }
            }
          : {})}
        {...buttonProps}
        href={link || '/'}>
        {download && <DownloadArrow toggleAnimation={isHovered} />}
        {children}
      </StyledAnchorButton>
    );
  }

  return (
    <StyledLinkButton
      {...(onClick
        ? {
            onClick: (e: any, ...props) => {
              e.preventDefault();
              onClick(props);
            }
          }
        : {})}
      {...buttonProps}
      to={link || '/'}>
      {download && <DownloadArrow toggleAnimation={isHovered} />}
      {children}
    </StyledLinkButton>
  );
};

export default Button;
