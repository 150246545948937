import React, { ReactElement, useState, useContext } from 'react';

import { MobileMenu } from './MobileMenu';
import { NavOptions } from './NavOptions';
import { NavContainer } from './element';
import AuthContext from 'AuthContext';

export interface MenuOptionType {
  name: string;
  link?: string;
  loggedInOnly?: boolean;
  action?: string;
}

interface Props {
  options: MenuOptionType[];
  toggleChangePasswordModal: () => void;
}

const showMenuMap: Record<string, boolean> = {
  LEVEL0: false,
  LEVEL1: false,
  LEVEL2: true,
  LEVEL3: true,
  LEVEL4: true
};

export const Menu = ({ options, toggleChangePasswordModal }: Props): ReactElement | null => {
  const [isOpen, toggle] = useState(false);

  const { isLoadingInitial, isLoggedIn, permissionLevel } = useContext(AuthContext);

  if (isLoadingInitial) {
    return null;
  }

  if (permissionLevel === 'LEVEL_UNKNOWN' || permissionLevel === 'LEVEL0') {
    return null;
  }

  return (
    <>
      <NavContainer>
        <NavOptions
          options={options}
          currentRole={permissionLevel}
          isLoggedIn={isLoggedIn}
          toggleChangePasswordModal={toggleChangePasswordModal}
        />
      </NavContainer>
      {isLoggedIn && showMenuMap[permissionLevel] && (
        <MobileMenu
          options={options}
          isOpen={isOpen}
          toggle={toggle}
          currentRole={permissionLevel}
          toggleChangePasswordModal={toggleChangePasswordModal}
          isLoggedIn={isLoggedIn}
        />
      )}
    </>
  );
};

export default Menu;
