import React, { ReactElement } from 'react';
import { get, isEmpty } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { stylesByTheme } from './blockItemStylesWithTheme';
import { VerticalContainer, HorizontalContainer, ImageContainer } from './element';

import Image from 'component/Image';
import Wazer from 'component/Wazer';
import Animation from 'component/Animation';

import BlockItemContent from './BlockItemContent';

export interface Props {
  theme?: ThemeType;
  foregroundColor?: string;
  captionScale?: CaptionScaleNameType;
  heading?: string;
  body?: string;
  image?: ImageType;
  wazer?: Wazer;
  footText?: string;
  buttons?: Button[];
  align?: string;
  withoutContainer?: boolean;
  withLayout?: boolean;
  hasWazer?: boolean;
  animation?: AnimationType;
  bodyClickHandler?: () => void;
  idx?: number;
}

export const BlockItem = ({
  theme = 'HeadingTextButton',
  foregroundColor,
  captionScale,
  heading,
  body,
  image,
  wazer,
  footText,
  buttons = [],
  align,
  withoutContainer,
  withLayout,
  hasWazer,
  animation,
  bodyClickHandler,
  idx
}: Props): ReactElement => {
  const styles = get(stylesByTheme, `${theme}`, stylesByTheme.HeadingTextButton);
  const hasImage = image && image.path && image.path.length > 0;
  const Container = styles.position === 'row' ? HorizontalContainer : VerticalContainer;
  const orientation = get(styles, 'orientation', 'right');
  const desktopAlign = align || get(styles, 'desktop.align', 'left');
  const mobileAlign = align || get(styles, 'mobile.align', 'left');

  const [ref, inView] = useInView({ rootMargin: '-200px 0px', triggerOnce: true });

  return (
    <Container
      ref={ref}
      withoutContainer={withoutContainer}
      hasWazer={hasWazer || !isEmpty(wazer)}
      withLayout={withLayout}
      align={desktopAlign}
      alignForMobile={mobileAlign}>
      {hasImage ? (
        <ImageContainer orientation={orientation}>
          <Image src={get(image, 'path', '')} size={get(image, 'size')} />
        </ImageContainer>
      ) : null}
      {animation ? (
        <Animation
          name={get(animation, 'name', '')}
          size={get(animation, 'size')}
          idx={idx}
          show={inView}
        />
      ) : null}

      <BlockItemContent
        theme={theme}
        desktopAlign={desktopAlign}
        styles={styles}
        captionScale={captionScale}
        foregroundColor={foregroundColor}
        mobileAlign={mobileAlign}
        heading={heading}
        body={body}
        bodyClickHandler={bodyClickHandler}
        buttons={buttons}
        footText={footText}
      />

      <Wazer
        image={get(wazer, 'image')}
        direction={get(wazer, 'direction', 'left')}
        position={get(wazer, 'position')}
        animate={get(wazer, 'animate')}
      />
    </Container>
  );
};

export default BlockItem;
