import React, { ReactElement } from 'react';
import { StyledMarkdownText, OnClickLink } from './element';

export interface Props {
  markdownText?: string;
  source?: string;
  type?: string;
  disabled?: boolean;
  size?: string;
  onClickHandler?: () => void;
}

const onClickLinkRenderer = ({ onClickHandler, ...props }: { onClickHandler: () => void }) => (
  <OnClickLink onClick={onClickHandler} {...props} />
);

export const MarkdownText = ({
  markdownText,
  source,
  size = 'm',
  onClickHandler
}: Props): ReactElement => {
  let componentsObject = {};

  if(onClickHandler != undefined) {
    componentsObject = {
      a: (props: any) => onClickLinkRenderer({ onClickHandler, ...props })
    };
  }

  return (
  <StyledMarkdownText
    size={size}
    children={markdownText || source as string}
    components={componentsObject}

  />
  )
};

export default MarkdownText;
