import React, { ReactElement, ReactNode } from 'react';
import tw, { styled, css } from 'twin.macro';
import { get } from 'lodash';
import Markdown from 'react-markdown';

import { paragraphScales, paragraphMargins, getCaptionScaleByName } from 'config/design';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require('config/tailwind.config');

const {
  theme: {
    colors,
    extend: { margin }
  }
} = config;

interface StyledParagraphProps {
  source: string;
  captionScaleName?: CaptionScaleNameType;
  paragraphScaleName?: ParagraphScaleNameType;
  scaleName?: ParagraphScaleNameType;
  textStyle?: TextStyle;
  textStyleForMobile?: TextStyle;
  bodyClickHandler?: () => void;
  children?: ReactNode;
}

const styles = {
  desktop: {
    textAlign: {
      left: tw`text-left`,
      center: tw`text-center`,
      right: tw`text-right`
    },
    padding: {
      top: tw`pb-3`
    },
    list: tw`ml-2 mb-2`,
    listItem: tw`pl-1 mb-2`
  },
  mobile: {
    textAlign: {
      left: tw`sm:text-left`,
      center: tw`sm:text-center`,
      right: tw`sm:text-right`
    },
    padding: {
      top: tw`pb-3`
    },
    list: tw`ml-2 leading-6`,
    listItem: tw`pl-1 mb-2`
  }
};

export const OnClickLink = styled.span`
  ${tw`
    text-black
    underline
    cursor-pointer
    `}
`;

//
// TODO : refactor to DRY things up

const onClickLinkRenderer = ({
  bodyClickHandler,
  ...props
}: {
  bodyClickHandler: () => void
}): ReactElement => <OnClickLink onClick={bodyClickHandler} {...props} />;

/* eslint-disable @typescript-eslint/no-explicit-any */
const MarkdownText = ({
  bodyClickHandler,
  ...props
}: {
  bodyClickHandler?: () => void
}): ReactElement => {
  // @ts-ignore
  const { className }: string = {...props};
  // @ts-ignore
  const { source }: string = {...props};

  let componentsObject = {};

  if(bodyClickHandler != undefined) {
    componentsObject = {
      a: (props: any) => onClickLinkRenderer({ bodyClickHandler, ...props })
    };
  }

  return (
    <Markdown
      children={source}
      className={className}
      linkTarget="_blank"
      remarkRehypeOptions={{...props}}
      components={componentsObject}
    />
  );
}

export const StyledParagraph = styled((props: any) => MarkdownText(props))<StyledParagraphProps>(
  ({
    paragraphScaleName = 'Med',
    captionScaleName,
    textStyle,
    textStyleForMobile
  }: StyledParagraphProps) => [
    tw`
      font-light
      leading-tight
    `,
    paragraphScales[paragraphScaleName],
    captionScaleName ? getCaptionScaleByName(captionScaleName).paragraph : '',
    styles.desktop.textAlign[get(textStyle, 'textAlign', 'left')],
    styles.mobile.textAlign[get(textStyleForMobile, 'textAlign', 'left')],
    css`
      color: ${colors[get(textStyle, 'color', 'black')]};
    `,
    css`
      margin-right: ${margin[get(textStyle, 'marginRight', '0')]};
    `,
    css`
      margin-left: ${margin[get(textStyle, 'marginLeft', '0')]};
    `,
    css`
      p {
        ${paragraphMargins.desktop.top[get(textStyle, 'marginTop', '0')]};
        ${paragraphMargins.desktop.bottom[get(textStyle, 'marginBottom', '0')]};
        ${paragraphMargins.mobile.bottom[get(textStyleForMobile, 'marginTop', '0')]};
        ${paragraphMargins.mobile.bottom[get(textStyleForMobile, 'marginBottom', '0')]};
      }
    `,
    css`
      em {
        ${tw`font-bold`};
      }
    `,
    css`
      ul {
        list-style-type: '• ';
        ${styles.desktop.list};
        ${styles.mobile.list};
      }
    `,
    css`
      li {
        ${styles.desktop.list};
        ${styles.mobile.list};
      }
    `,
    css`
      ol {
        list-style-type: decimal;
        ${styles.desktop.list};
        ${styles.mobile.list};
      }

      ol li {
        ${styles.desktop.listItem};
        ${styles.mobile.listItem};
      }

      a {
        text-decoration: underline;
      }
    `,
    get(textStyle, 'maxWidth') &&
      css`
        display: flex;
        justify-content: center;
        max-width: ${get(textStyle, 'maxWidth')};
      `
  ]
);
