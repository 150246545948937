import React, { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  strokeColor?: string;
}

export const AccountIcon = ({
  width = '26px',
  height = '26px',
  strokeColor = '#000000'
}: Props): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 26 26" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1088.000000, -33.000000)" stroke={strokeColor} strokeWidth="2">
        <g id="Group-4-Copy-4" transform="translate(929.000000, 22.000000)">
          <g id="Group" transform="translate(148.000000, 0.000000)">
            <g id="Group-7" transform="translate(12.000000, 12.000000)">
              <path
                d="M24,12 C24,18.627 18.627,24 12,24 C5.373,24 0,18.627 0,12 C0,5.373 5.373,0 12,0 C18.627,0 24,5.373 24,12 Z"
                id="Stroke-1"></path>
              <path
                d="M17,10 C17,12.761 14.761,15 12,15 C9.239,15 7,12.761 7,10 C7,7.239 9.239,5 12,5 C14.761,5 17,7.239 17,10 Z"
                id="Stroke-3"></path>
              <path
                d="M3.23,20.1904 C4.261,18.3144 5.877,16.8044 7.832,15.9074 C9.101,15.3244 10.513,15.0004 12,15.0004 C13.487,15.0004 14.899,15.3244 16.168,15.9074 C18.123,16.8044 19.739,18.3144 20.77,20.1904"
                id="Stroke-5"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AccountIcon;
