import { get, isEmpty } from 'lodash';

const LOCKED_ROLE = 'LEVEL0';
const PUBLIC_ROLE = 'LEVEL1';

//-------------------------------------------------------------
// check for role context against disallowed roles in content
//-------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-explicit-any */
export const isRoleDisallowed = ({
  currentRole,
  module
}: {
  currentRole?: string,
  module: any
}): boolean => {
  if (currentRole === LOCKED_ROLE && !get(module, 'allowPublic', false)) {
    return true;
  }

  if (currentRole === PUBLIC_ROLE && !get(module, 'allowPublic', false)) {
    return true;
  }
  return (
    !isEmpty(currentRole) &&
    !isEmpty(get(module, 'disallowRoles')) &&
    get(module, 'disallowRoles', []).includes(currentRole)
  );
};
