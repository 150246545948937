import tw, { styled } from 'twin.macro';

export const Container = styled.footer`
  ${tw`
    flex
    flex-col
    w-full
    px-3
    sm:px-2
  `};
  max-width: 1440px;
`;

interface FooterNavContainerProps {
  show?: boolean;
}

export const FooterNavContainer = styled.div(({ show }: FooterNavContainerProps) => [
  tw`
    flex
    items-center
    justify-between
    mt-5
    mb-6
    px-1
    w-full
    sm:flex-col
    sm:grid
    sm:gap-3
    sm:justify-center
    sm:grid-flow-row
    delay-1000
    duration-1000
    ease-in-out
    transition-all
    translate-y-4
    transform
    opacity-0
  `,
  show ? tw`opacity-100` : tw`opacity-0`,
  show ? tw`translate-y-0` : tw`translate-y-4`
]);

export const OptionsContainer = styled.div`
  ${tw`
    grid
    gap-7
    grid-flow-col
    sm:grid-flow-row
    sm:justify-center
    sm:gap-3
  `};
`;

export const StyledLink = styled.a`
  ${tw`
    text-black
    text-center
    font-light
    no-underline
    ms-lg
  `};
`;
