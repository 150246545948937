import React, { ReactNode, ReactElement, FunctionComponent, ElementType } from 'react';

import { getCaptionScaleByName } from 'config/design';

import { StyledHeading } from './element';

interface Props {
  children: ReactNode;
  headingScaleName?: HeadingScaleNameType;
  captionScaleName?: CaptionScaleNameType;
  textStyle?: TextStyle;
  textStyleForMobile?: TextStyle;
}

const defaultProps = {
  children: null,
  captionScaleName: 'M',
  textStyle: {
    color: 'black',
    textAlign: 'left',
    marginTop: '0',
    marginRight: '0',
    marginBotton: '0',
    marginLeft: '0'
  },
  textStyleForMobile: {
    color: 'black',
    textAlign: 'left',
    marginTop: '0',
    marginRight: '0',
    marginBotton: '0',
    marginLeft: '0'
  }
};

export const Heading: FunctionComponent<Props> = ({
  children,
  headingScaleName,
  captionScaleName,
  textStyle = defaultProps.textStyle,
  textStyleForMobile = defaultProps.textStyleForMobile
}: Props): ReactElement => {
  const captionScale: CaptionScale = getCaptionScaleByName(captionScaleName || defaultProps.captionScaleName);
  return (
    <React.Fragment>
      <StyledHeading
        // eslint-disable-next-line prettier/prettier
        as={captionScale.heading.desktop as ElementType}
        headingScaleName={headingScaleName}
        captionScaleName={captionScaleName}
        textStyle={textStyle}
        textStyleForMobile={textStyleForMobile}
        desktop>
        {children}
      </StyledHeading>
      <StyledHeading
        // eslint-disable-next-line prettier/prettier
        as={captionScale.heading.mobile as ElementType}
        headingScaleName={headingScaleName}
        captionScaleName={captionScaleName}
        textStyle={textStyle}
        textStyleForMobile={textStyleForMobile}
        mobile>
        {children}
      </StyledHeading>
    </React.Fragment>
  );
};

export default Heading;
