import tw, { styled } from 'twin.macro';

import { headingScales } from 'config/design';

export const FormSelectContainer = styled.div`
  ${tw`
    flex
    items-center
    relative
    bg-white
    rounded-cardRadius
    h-13
    w-full
    overflow-hidden
    duration-500
    ease-in-out
  `}
  select {
    ${tw`
      bg-transparent
      font-medium
      border-none
      outline-none
      resize-none
      appearance-none
      pl-3
      pr-6
      h-full
      w-full
    `}
    ${headingScales.H6}
  }
`;

export const ArrowContainer = styled.div`
  ${tw`
    flex
    absolute
    right-0
    mr-1
    justify-center
    items-center
    h-12
    w-12
    pointer-events-none
  `}
`;
