import React, { ReactElement } from 'react';
import ReactModal from 'react-modal';

import { MODAL_ROOT_SELECTOR } from 'config';

ReactModal.setAppElement(MODAL_ROOT_SELECTOR);

import { StyledModal } from './element';

interface Props {
  children?: ReactElement;
  modalIsOpen: boolean;
  onRequestClose: () => void;
}

export const Modal = ({ children, modalIsOpen, onRequestClose }: Props): ReactElement => {
  return (
    <StyledModal isOpen={modalIsOpen} onRequestClose={onRequestClose}>
      {children}
    </StyledModal>
  );
};

export default Modal;
