import React, { ReactElement } from 'react';

import { isRoleDisallowed } from 'lib/role';
import { isBrowser } from 'lib/browser';

import { StyledLinkByTheme } from './StyledLinkByTheme';
import {
  getComponentsByTheme,
  PropsType,
  VerticalMenuOptionsPropsType,
  VerticalMenuOptionsType
} from './VerticalMenu';
import { VerticalSubMenuChildren } from './VerticalSubMenuChildren';
import { ChildrenVerticalMenuContainer } from './element';

export const VerticalMenuChildren = ({
  parents,
  options,
  isOpened,
  theme,
  handleClick,
  currentRole
}: PropsType & {
  parents: string[],
  isOpened: boolean,
  theme?: string,
  handleClick: (id: string, option: VerticalMenuOptionsPropsType, parents?: string[]) => void
}): ReactElement => {
  const { Container } = getComponentsByTheme(theme || 'A');
  return (
    <ChildrenVerticalMenuContainer isOpened={isOpened}>
      {options.map((option: VerticalMenuOptionsType) => {
        return option.children &&
          option.children.length > 0 &&
          isBrowser &&
          window.location.pathname === option.link ? (
          <VerticalSubMenuChildren
            Container={Container}
            option={option}
            theme={theme}
            handleClick={handleClick}
            parents={parents}
          />
        ) : (
          !isRoleDisallowed({ currentRole, module: option }) && (
            <Container key={option.id}>
              <StyledLinkByTheme
                theme={`${theme}1`}
                to={option.link || '/'}
                isActive={option.isActive}
                onClick={() => handleClick(option.id, option, [...parents, option.id])}>
                {option.name}
              </StyledLinkByTheme>
            </Container>
          )
        );
      })}
    </ChildrenVerticalMenuContainer>
  );
};

export default VerticalMenuChildren;
