import React, { ReactElement, useState, useEffect, MouseEvent, SetStateAction } from 'react';
import { find, get, reduce } from 'lodash';

import ArrowIcon from 'icons/Arrow';
import { VerticalMenu } from 'component';

import { DropdownContainer, OptionSelected, ArrowContainer, OptionsContainer } from './element';

export const Arrow = ({ isDropdownOpened }: { isDropdownOpened?: boolean }): ReactElement => (
  <ArrowContainer>
    <ArrowIcon direction={isDropdownOpened ? 'top' : 'bottom'} />
  </ArrowContainer>
);

export interface VerticalMenuOptionsPropsType {
  name: string;
  codename?: string;
  link?: string;
  children?: VerticalMenuOptionsPropsType[];
  disallowRoles?: string;
}

type OptionType = {
  displayName: string,
  codename?: string,
  link?: string,
  name?: string
};

interface DropdownProps {
  initialOptionCodenameSelected?: string;
  options: OptionType[];
  onClickHandle?: (props: {
    [k: string]: string | boolean | number | SetStateAction<string> | undefined
  }) => void;
}

const getVerticalMenuOptions = ({ options }: { options: OptionType[] }) =>
  reduce(
    options,
    (nextOptions: OptionType[], option: OptionType): {
    displayName?: string;
    codename?: string | undefined;
    link?: string | undefined;
    name?: string | undefined;
}[]  => [
      ...nextOptions,
      { name: option.displayName, codename: option.codename }
    ],
    []
  );

const getOptionSelected = ({
  options,
  optionCodenameSelected
}: {
  options: OptionType[],
  optionCodenameSelected: string
}): OptionType | undefined =>
  find(options, (option: OptionType): boolean => option.codename === optionCodenameSelected);

export const Dropdown = ({
  initialOptionCodenameSelected = '',
  options,
  onClickHandle
}: DropdownProps): ReactElement => {
  const [optionCodenameSelected, setOptionCodenameSelected] = useState(
    initialOptionCodenameSelected
  );
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const optionSelected = getOptionSelected({ options, optionCodenameSelected });
  const verticalMenuOptions = getVerticalMenuOptions({ options });

  const toggleDropdown = (event: MouseEvent) => {
    if (get(event, 'target.id') === 'DropdownContainer') {
      setIsDropdownOpened(!isDropdownOpened);
    }
  };
  const handleOnClick = (props: { [k: string]: string | boolean }) => {
    setIsDropdownOpened(false);
    setOptionCodenameSelected(String(get(props, 'name', optionCodenameSelected)));
    return onClickHandle ? onClickHandle(props) : undefined;
  };

  useEffect(() => {
    setOptionCodenameSelected(initialOptionCodenameSelected);
  }, [initialOptionCodenameSelected]);

  /* eslint-disable prettier/prettier */
  return (
    <DropdownContainer id="DropdownContainer" onClick={toggleDropdown}>
      <OptionSelected>{get(optionSelected, 'displayName', 'None')}</OptionSelected>
      <Arrow isDropdownOpened={isDropdownOpened} />
      <OptionsContainer isDropdownOpened={isDropdownOpened}>
        <VerticalMenu options={(verticalMenuOptions as VerticalMenuOptionsPropsType[])} onClickHandle={handleOnClick} theme="C" />
      </OptionsContainer>
    </DropdownContainer>
  );
};

export default Dropdown;
