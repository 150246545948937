import React, { ReactElement, ReactNode } from 'react';

import { Container } from './element';

interface Props {
  children: ReactNode | ReactNode[];
}

const SitewideContainer = ({ children }: Props): ReactElement => <Container>{children}</Container>;

export default SitewideContainer;
