import React, { ReactElement } from 'react';
import tw, { styled } from 'twin.macro';

interface Props {
  isOpen?: boolean;
}

interface StyleProps {
  isOpen?: boolean;
}

const Container = styled.div`
  ${tw`
    flex
    flex-col
    relative
    bg-black
    duration-200
    ease-in-out
  `};
  width: 28px;
  height: 2px;
  transition-property: transform;
  transition-delay: ${({ isOpen }: StyleProps) => (isOpen ? '0.2s' : '0s')};
  transform: ${({ isOpen = false }: StyleProps): string =>
    isOpen ? 'rotate(45deg)' : 'rotate(0deg)'};
  :before {
    ${tw`
      bg-black
      absolute
      duration-200
      ease-in-out
    `};
    content: '';
    width: 28px;
    height: 2px;
    transition-property: top, opacity;
    top: ${({ isOpen = false }: StyleProps): string => (isOpen ? '0' : '-10px')};
    transition-delay: ${({ isOpen }: StyleProps) => (isOpen ? '0s' : '0.2s')};
  }
  :after {
    ${tw`
      bg-black
      absolute
      duration-200
      ease-in-out
    `};
    content: '';
    width: 28px;
    height: 2px;
    transition-property: bottom, transform;
    bottom: ${({ isOpen = false }: StyleProps): string => (isOpen ? '0' : '-10px')};
    transition-delay: ${({ isOpen }: StyleProps) => (isOpen ? '0s' : '0.2s')};
  }
`;

const InnerLine = styled.div`
  ${tw`
    bg-black
    absolute
    duration-200
    ease-in-out
  `};
  width: 28px;
  height: 2px;
  transition-delay: ${({ isOpen }: StyleProps) => (isOpen ? '0.2s' : '0s')};
  transform: ${({ isOpen = false }: StyleProps): string =>
    isOpen ? 'rotate(-90deg)' : 'rotate(0deg)'};
`;

// eslint-disable @typescript-eslint/no-unused-vars
export const BurgerIcon = ({ isOpen = false }: Props): ReactElement => (
  <Container isOpen={isOpen}>
    <InnerLine isOpen={isOpen} />
  </Container>
);

export default BurgerIcon;
