import tw, { styled } from 'twin.macro';

export const StyledHeader = styled.header`
  ${tw`
    container
    flex
    justify-center
    items-center
    h-24
    sm:h-18
    fixed
    bg-white
    z-40
  `};
`;

export const StyledHeaderContent = styled.div`
  ${tw`
    container
    flex
    justify-between
    items-center
    h-full
    px-3
    sm:px-2
  `};
  max-width: 1440px;
`;
