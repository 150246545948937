/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-type-no-unknown */
/* eslint-disable max-lines, max-lines-per-function */

import tw, { css, styled, TwStyle } from 'twin.macro';
import { Link } from 'gatsby';

export const NavContainer = styled.nav`
  ${tw`
  block
  md:hidden
  `};
`;

export const MobileNavContainer = styled.div`
  ${tw`
  hidden
  md:block
  `};
`;

export const Nav = styled.nav`
  ${tw`
  flex
  `};
`;

export const LoggedInStyledNavLink = styled.span`
  ${tw`
    font-boing
    font-medium
    text-black
    no-underline
    leading-9
    relative
    ml-8
    lg:ml-6`}
  font-size: 1.0625rem;
`;

export const StyledNavLink = styled(Link)`
  ${tw`
    font-boing
    font-medium
    text-black
    no-underline
    leading-9
    relative
    ml-8
    lg:ml-6`}

  font-size: 1.0625rem;
  :first-child {
    ${tw`ml-0`}
  }
  :after {
    ${tw`
      bg-black
      absolute
      left-0
      w-0
      duration-300
      ease-in-out
    `}
    content: '';
    bottom: 3px;
    height: 2px;
    transition-property: width;
  }

  :hover:after {
    ${tw`w-full`};
  }

  &.is-active,
  &[aria-current='page'] {
    :after {
      ${tw`
        bg-black
        absolute
        left-0
        w-full
        duration-300
        ease-in-out
      `};
      content: '';
      bottom: 3px;
      height: 2px;
      transition-property: width;
    }
  }
`;

export const MobileMenuContainer = styled.div`
  ${tw`
    pb-2
    absolute
    top-0
    left-0
    right-0
    w-full
    min-h-screen
    pointer-events-none
    z-40
    md:mt-12
    sm:mt-9
  `};
`;

export const MobileMenuBackground = styled.div`
  ${tw`
    bg-white
    relative
    w-full
    duration-300
    ease-in-out
    pointer-events-auto
    overflow-hidden
  `};
  transition-property: height;
  ${({ isOpen }: { isOpen: boolean }): TwStyle => (isOpen ? tw`h-menu-md sm:h-menu-sm` : tw`h-0`)};
`;

export const MobileMenuGradientContainer = styled.div`
  ${tw`
    absolute
    bottom-0
    left-0
    w-full
    md:pb-3
    md:px-3
    sm:px-2
    sm:pb-2
  `};
  pointer-events: none;
  height: 55px;
`;

export const MobileMenuGradient = styled.div`
  background: linear-gradient(transparent, #f5f5f5);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.001) 0%, #f5f5f5 100%);
  ${tw`
    rounded-b-lg
    w-full
    h-full
  `}
`;

export const MobileMenuContentWithPadding = styled.div`
  ${tw`
    w-full
    h-full
    md:pb-3
    md:px-3
    sm:pb-2
    sm:px-2
  `}
`;

export const MobileMenuContent = styled.div`
  ${tw`
    bg-gray
    rounded-lg
    overflow-scroll
    w-full
    h-full
    pointer-events-auto
  `};
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const VerticalMenuContainer = styled.div`
  ${tw`
    p-5
  `};
`;

export const BurgerIconContainer = styled.button`
  ${tw`
    flex
    items-center
    justify-center
    w-12
    h-12
  `};
`;

interface LoggedInNavDropdownProps {
  isOpen?: boolean;
}

export const LoggedInNavDropdown = styled.div(({ isOpen }: LoggedInNavDropdownProps) => [
  tw`flex
  flex-col
  bg-white
  h-32
  absolute
  w-48
  p-3
  right--3
  top-8
  rounded-cardRadius
  shadow-border-1-gray3
  delay-500
  duration-700
  ease-in-out
  opacity-0
  transition-all
  translate-y-2
  transform
  `,
  css`
    display: none;
    span {
      ${tw`ml-0 mb-1 `}

      :hover {
        :after {
          ${tw`bg-gray2
          w-48
          my-auto
          z-10
          absolute
          content
          left--3
          h-12
          z--1
          bottom--0.5
          delay-700
          duration-700
          ease-in-out
          transition-opacity
          `};
        }
      }
    }
  `,
  isOpen ? tw`opacity-100` : tw`opacity-0`,
  isOpen ? tw`translate-y-0` : tw`translate-y-4`,
  isOpen
    ? css`
        display: flex;
      `
    : css`
        display: none;
      `,
  css`
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
    :before {
      border-left: 23px solid transparent;
      border-top: 23px solid white;
      top: -14.5px;
      content: '';
      position: absolute;
      right: 24px;
      transform: rotate(-45deg);
      box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      z-index: -10;
    }
    :after {
      top: -12px;
      right: 19px;
      transform: rotate(-45deg);
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: white;
      border-top-color: white;
      border-width: 17px;
      border-radius: 4px;
      z-index: -10;
    }
  `
]);

export const AccountNavContainer = styled.div`
  ${tw`
    font-boing
    font-medium
    text-black
    no-underline
    leading-9
    relative
    ml-8
    lg:ml-6
  `};

  /* 17px */
  font-size: 1.0625rem;

  :first-child {
    ${tw`
      ml-0
    `};
  }
`;

export const AccountIconContainer = styled.div(() => [tw`mt-1 hover:cursor-pointer`]);
