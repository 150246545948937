import React, { ReactElement } from 'react';

import { Img } from './element';

interface Props {
  alt?: string;
  height?: string | number;
  src?: string;
  width?: string | number;
  size?: ImageSizeNameType;
}

export const Image = ({ alt, height, src, width, size }: Props): ReactElement => (
  <Img alt={alt} width={width} height={height} src={src} size={size} />
);

export default Image;
