/* eslint-disable max-lines */
export default {
  v: '5.6.5',
  fr: 30,
  ip: 0,
  op: 30,
  w: 230,
  h: 50,
  nm: 'Layer 1/brand-center-logo',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Brand center Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [77.625, 31.902, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [97.685, 97.685, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.678, 0],
                    [0, 0],
                    [0, -0.244],
                    [0, 0],
                    [-0.266, 0],
                    [0, 0],
                    [0, 2.304],
                    [2.038, 0.687],
                    [0, 1.396]
                  ],
                  o: [
                    [0, 0],
                    [-0.288, 0],
                    [0, 0],
                    [0, 0.266],
                    [0, 0],
                    [3.39, 0],
                    [0, -1.595],
                    [1.374, -0.576],
                    [0, -1.772]
                  ],
                  v: [
                    [8.641, -15.509],
                    [1.883, -15.509],
                    [1.418, -15.044],
                    [1.418, -0.465],
                    [1.883, 0],
                    [9.239, 0],
                    [14.423, -4.52],
                    [11.543, -8.375],
                    [13.626, -11.587]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'B',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.152],
                    [1.507, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 1.174],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.507, 0]
                  ],
                  v: [
                    [10.568, -11.189],
                    [8.242, -9.195],
                    [4.387, -9.195],
                    [4.387, -13.161],
                    [8.242, -13.161]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'B',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -1.396],
                    [1.418, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [1.44, 0],
                    [0, 1.418],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [8.774, -6.957],
                    [11.344, -4.653],
                    [8.774, -2.349],
                    [4.387, -2.349],
                    [4.387, -6.957]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'B',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'B',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.554, -0.953],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [-1.418, 0],
                    [0, 0],
                    [0, 0.443],
                    [0, 0],
                    [0.222, 0]
                  ],
                  o: [
                    [-1.13, 0],
                    [0, 0],
                    [0, -0.222],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -1.839],
                    [0, 0],
                    [0.177, 0],
                    [0, 0],
                    [0, -0.443],
                    [0, 0]
                  ],
                  v: [
                    [21.275, -10.812],
                    [18.661, -9.239],
                    [18.661, -10.258],
                    [18.217, -10.679],
                    [16.378, -10.679],
                    [15.935, -10.214],
                    [15.935, -0.443],
                    [16.378, 0],
                    [18.328, 0],
                    [18.793, -0.421],
                    [18.793, -5.229],
                    [21.031, -7.799],
                    [21.563, -7.799],
                    [22.095, -8.353],
                    [22.095, -10.258],
                    [21.585, -10.812]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'r',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'r',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -3.434],
                    [-2.792, 0],
                    [-0.554, 0.931],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.244, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [1.507, 0]
                  ],
                  o: [
                    [0, 3.434],
                    [1.507, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.244, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [-0.222, 0],
                    [0, 0],
                    [-0.532, -0.931],
                    [-2.814, 0]
                  ],
                  v: [
                    [22.51, -5.362],
                    [27.849, 0.31],
                    [31.128, -1.307],
                    [31.128, -0.443],
                    [31.571, 0],
                    [33.521, 0],
                    [33.964, -0.443],
                    [33.964, -10.214],
                    [33.521, -10.679],
                    [31.571, -10.679],
                    [31.128, -10.214],
                    [31.128, -9.372],
                    [27.871, -10.989]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'a',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 1.662],
                    [-1.64, 0],
                    [0, -1.662],
                    [1.64, 0]
                  ],
                  o: [
                    [0, -1.662],
                    [1.64, 0],
                    [0, 1.662],
                    [-1.64, 0]
                  ],
                  v: [
                    [25.39, -5.34],
                    [28.27, -8.331],
                    [31.128, -5.34],
                    [28.27, -2.326]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'a',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'a',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [2.836, 0],
                    [0.62, -0.864],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [-1.617, 0],
                    [0, -1.684]
                  ],
                  o: [
                    [0, 0.244],
                    [0, 0],
                    [0.244, 0],
                    [0, 0],
                    [0, -2.637],
                    [-1.706, 0],
                    [0, 0],
                    [0, -0.222],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -1.64],
                    [1.64, 0],
                    [0, 0]
                  ],
                  v: [
                    [44.13, -0.421],
                    [44.596, 0],
                    [46.523, 0],
                    [46.988, -0.421],
                    [46.988, -6.359],
                    [42.402, -10.989],
                    [38.857, -9.416],
                    [38.857, -10.258],
                    [38.392, -10.679],
                    [36.442, -10.679],
                    [35.999, -10.214],
                    [35.999, -0.443],
                    [36.442, 0],
                    [38.392, 0],
                    [38.857, -0.421],
                    [38.857, -5.583],
                    [41.538, -8.353],
                    [44.13, -5.583]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'n',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'n',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -3.412],
                    [-2.814, 0],
                    [-0.62, 0.931],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.266, 0],
                    [0, 0],
                    [0, -0.244],
                    [0, 0],
                    [1.529, 0]
                  ],
                  o: [
                    [0, 3.434],
                    [1.507, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.266, 0],
                    [0, 0],
                    [0, -0.244],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [-0.532, -0.886],
                    [-2.769, 0]
                  ],
                  v: [
                    [48.381, -5.362],
                    [53.721, 0.31],
                    [57, -1.307],
                    [57, -0.443],
                    [57.443, 0],
                    [59.37, 0],
                    [59.836, -0.443],
                    [59.836, -15.066],
                    [59.37, -15.509],
                    [57.443, -15.509],
                    [56.978, -15.066],
                    [56.978, -9.416],
                    [53.676, -10.989]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'd',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 1.662],
                    [-1.64, 0],
                    [0, -1.662],
                    [1.64, 0]
                  ],
                  o: [
                    [0, -1.662],
                    [1.64, 0],
                    [0, 1.662],
                    [-1.64, 0]
                  ],
                  v: [
                    [51.261, -5.34],
                    [54.142, -8.331],
                    [57, -5.34],
                    [54.142, -2.326]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'd',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'd',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.564, 0],
                    [-1.019, 3.035],
                    [0.332, 0.089],
                    [0, 0],
                    [0.066, -0.155],
                    [2.149, 0],
                    [0, 2.991],
                    [-2.947, 0],
                    [-0.798, -1.617],
                    [-0.332, 0.066],
                    [0, 0],
                    [0.066, 0.199],
                    [3.368, 0],
                    [0, -4.564]
                  ],
                  o: [
                    [3.368, 0],
                    [0.066, -0.199],
                    [0, 0],
                    [-0.332, -0.066],
                    [-0.798, 1.617],
                    [-2.947, 0],
                    [0, -2.991],
                    [2.149, 0],
                    [0.066, 0.155],
                    [0, 0],
                    [0.332, -0.089],
                    [-1.019, -3.035],
                    [-4.564, 0],
                    [0, 4.564]
                  ],
                  v: [
                    [74.157, 0.31],
                    [81.557, -4.498],
                    [81.313, -4.985],
                    [79.097, -5.539],
                    [78.61, -5.229],
                    [74.179, -2.437],
                    [69.105, -7.755],
                    [74.179, -13.072],
                    [78.61, -10.28],
                    [79.097, -9.97],
                    [81.313, -10.524],
                    [81.557, -11.011],
                    [74.157, -15.819],
                    [66.003, -7.755]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'C',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'C',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.301, 0],
                    [0, -3.168],
                    [-3.346, 0],
                    [-0.886, 1.13],
                    [0.244, 0.177],
                    [0, 0],
                    [0.155, -0.177],
                    [1.13, 0],
                    [0.155, 1.507],
                    [0, 0],
                    [0, 0.266],
                    [0, 0]
                  ],
                  o: [
                    [-3.279, 0],
                    [0, 3.168],
                    [2.349, 0],
                    [0.133, -0.177],
                    [0, 0],
                    [-0.244, -0.177],
                    [-0.643, 0.731],
                    [-1.44, 0],
                    [0, 0],
                    [0.399, 0],
                    [0, 0],
                    [0, -2.836]
                  ],
                  v: [
                    [87.888, -10.989],
                    [82.261, -5.295],
                    [87.933, 0.31],
                    [92.563, -1.75],
                    [92.474, -2.393],
                    [91.079, -3.368],
                    [90.503, -3.257],
                    [87.999, -2.127],
                    [85.207, -4.631],
                    [92.829, -4.631],
                    [93.339, -5.118],
                    [93.339, -5.65]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'e',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.266, -1.462],
                    [0, 0],
                    [-1.285, 0]
                  ],
                  o: [
                    [0, 0],
                    [0.288, -1.352],
                    [1.219, 0]
                  ],
                  v: [
                    [90.525, -6.447],
                    [85.274, -6.447],
                    [87.91, -8.685]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'e',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'e',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [2.836, 0],
                    [0.62, -0.864],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [-1.617, 0],
                    [0, -1.684]
                  ],
                  o: [
                    [0, 0.244],
                    [0, 0],
                    [0.244, 0],
                    [0, 0],
                    [0, -2.637],
                    [-1.706, 0],
                    [0, 0],
                    [0, -0.222],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -1.64],
                    [1.64, 0],
                    [0, 0]
                  ],
                  v: [
                    [102.803, -0.421],
                    [103.269, 0],
                    [105.196, 0],
                    [105.661, -0.421],
                    [105.661, -6.359],
                    [101.075, -10.989],
                    [97.53, -9.416],
                    [97.53, -10.258],
                    [97.065, -10.679],
                    [95.115, -10.679],
                    [94.672, -10.214],
                    [94.672, -0.443],
                    [95.115, 0],
                    [97.065, 0],
                    [97.53, -0.421],
                    [97.53, -5.583],
                    [100.211, -8.353],
                    [102.803, -5.583]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'n',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'n',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.772, 0],
                    [-0.687, 0.687],
                    [0.177, 0.266],
                    [0, 0],
                    [0.332, -0.199],
                    [0.266, 0],
                    [0, 1.086],
                    [0, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.244, 0],
                    [0, 0],
                    [0, 0],
                    [0.244, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 2.393],
                    [1.219, 0],
                    [0.199, -0.199],
                    [0, 0],
                    [-0.199, -0.288],
                    [-0.377, 0.244],
                    [-0.51, 0],
                    [0, 0],
                    [0, 0],
                    [0.244, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [-0.266, 0],
                    [0, 0],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [108.429, -3.146],
                    [111.974, 0.222],
                    [114.987, -0.842],
                    [115.054, -1.573],
                    [114.389, -2.659],
                    [113.525, -2.747],
                    [112.506, -2.415],
                    [111.287, -3.789],
                    [111.287, -8.286],
                    [114.389, -8.286],
                    [114.854, -8.752],
                    [114.854, -10.214],
                    [114.389, -10.679],
                    [111.287, -10.679],
                    [111.287, -13.648],
                    [110.844, -14.113],
                    [108.894, -14.113],
                    [108.429, -13.648],
                    [108.429, -10.679],
                    [106.967, -10.679],
                    [106.524, -10.214],
                    [106.524, -8.752],
                    [106.967, -8.286],
                    [108.429, -8.286]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 't',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 't',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.301, 0],
                    [0, -3.168],
                    [-3.346, 0],
                    [-0.886, 1.13],
                    [0.244, 0.177],
                    [0, 0],
                    [0.155, -0.177],
                    [1.13, 0],
                    [0.155, 1.507],
                    [0, 0],
                    [0, 0.266],
                    [0, 0]
                  ],
                  o: [
                    [-3.279, 0],
                    [0, 3.168],
                    [2.349, 0],
                    [0.133, -0.177],
                    [0, 0],
                    [-0.244, -0.177],
                    [-0.643, 0.731],
                    [-1.44, 0],
                    [0, 0],
                    [0.399, 0],
                    [0, 0],
                    [0, -2.836]
                  ],
                  v: [
                    [121.13, -10.989],
                    [115.502, -5.295],
                    [121.174, 0.31],
                    [125.805, -1.75],
                    [125.716, -2.393],
                    [124.32, -3.368],
                    [123.744, -3.257],
                    [121.241, -2.127],
                    [118.449, -4.631],
                    [126.071, -4.631],
                    [126.58, -5.118],
                    [126.58, -5.65]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'e',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.266, -1.462],
                    [0, 0],
                    [-1.285, 0]
                  ],
                  o: [
                    [0, 0],
                    [0.288, -1.352],
                    [1.219, 0]
                  ],
                  v: [
                    [123.766, -6.447],
                    [118.516, -6.447],
                    [121.152, -8.685]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'e',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'e',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.554, -0.953],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -0.266],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [-1.418, 0],
                    [0, 0],
                    [0, 0.443],
                    [0, 0],
                    [0.222, 0]
                  ],
                  o: [
                    [-1.13, 0],
                    [0, 0],
                    [0, -0.222],
                    [0, 0],
                    [-0.244, 0],
                    [0, 0],
                    [0, 0.244],
                    [0, 0],
                    [0.222, 0],
                    [0, 0],
                    [0, -1.839],
                    [0, 0],
                    [0.177, 0],
                    [0, 0],
                    [0, -0.443],
                    [0, 0]
                  ],
                  v: [
                    [133.253, -10.812],
                    [130.639, -9.239],
                    [130.639, -10.258],
                    [130.196, -10.679],
                    [128.357, -10.679],
                    [127.914, -10.214],
                    [127.914, -0.443],
                    [128.357, 0],
                    [130.307, 0],
                    [130.772, -0.421],
                    [130.772, -5.229],
                    [133.01, -7.799],
                    [133.542, -7.799],
                    [134.073, -8.353],
                    [134.073, -10.258],
                    [133.564, -10.812]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'r',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'r',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 1/Waze Full Brand Logo Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [115, 25, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [115.5, 25.5, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [5.124, 5.239],
                ix: 2
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3
              },
              r: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [0.833]
                    },
                    o: {
                      x: [0.167],
                      y: [0.167]
                    },
                    t: 0,
                    s: [20]
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [0.833]
                    },
                    o: {
                      x: [0.167],
                      y: [0.167]
                    },
                    t: 10,
                    s: [1.8]
                  },
                  {
                    t: 15,
                    s: [20]
                  }
                ],
                ix: 4
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 3
              },
              o: {
                a: 0,
                k: 100,
                ix: 4
              },
              w: {
                a: 0,
                k: 0,
                ix: 5
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [36.653, 18.26],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [1, 0.833]
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0, 0.167]
                    },
                    t: 0,
                    s: [93.585, 91.9]
                  },
                  {
                    i: {
                      x: [0.833, 0.833],
                      y: [1, 0.833]
                    },
                    o: {
                      x: [0.167, 0.167],
                      y: [0, 0.167]
                    },
                    t: 10,
                    s: [93.585, 29.271]
                  },
                  {
                    t: 15,
                    s: [93.585, 91.9]
                  }
                ],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'New Right Eye',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.156, 2.433],
                    [0.465, 0],
                    [-0.372, -0.787],
                    [-3.837, 0],
                    [-1.537, 3.255],
                    [0.87, 0],
                    [0, 0],
                    [0.197, -0.413],
                    [2.868, 0]
                  ],
                  o: [
                    [-0.2, -0.42],
                    [-0.87, 0],
                    [1.536, 3.255],
                    [3.837, 0],
                    [0.371, -0.787],
                    [0, 0],
                    [-0.457, 0],
                    [-1.156, 2.433],
                    [-2.867, 0]
                  ],
                  v: [
                    [-6.504, -2.919],
                    [-7.598, -3.601],
                    [-8.683, -1.907],
                    [0, 3.601],
                    [8.684, -1.907],
                    [7.598, -3.601],
                    [7.567, -3.601],
                    [6.504, -2.919],
                    [0, 1.2]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [29.473, 29.076],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Smile',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 1.326],
                    [1.325, 0],
                    [0, -1.325],
                    [-1.325, 0]
                  ],
                  o: [
                    [0, -1.325],
                    [-1.325, 0],
                    [0, 1.326],
                    [1.325, 0]
                  ],
                  v: [
                    [2.399, 0],
                    [0, -2.4],
                    [-2.399, 0],
                    [0, 2.4]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [22.237, 18.295],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Left Eye',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0.556],
                    [0.341, 0.415],
                    [0.746, 0],
                    [0, -1.325],
                    [-0.495, -0.44],
                    [-0.611, 0],
                    [-0.439, 0.564]
                  ],
                  o: [
                    [0, -0.579],
                    [-0.44, -0.534],
                    [-1.325, 0],
                    [0, 0.715],
                    [0.423, 0.376],
                    [0.77, 0],
                    [0.317, -0.407]
                  ],
                  v: [
                    [2.399, 0],
                    [1.853, -1.525],
                    [0, -2.4],
                    [-2.399, 0],
                    [-1.592, 1.796],
                    [0, 2.4],
                    [1.894, 1.474]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [36.642, 18.295],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1]
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0.113]
                    },
                    t: 5,
                    s: [100, 100]
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1]
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0]
                    },
                    t: 10,
                    s: [100, 33.612]
                  },
                  {
                    t: 20,
                    s: [100, 100]
                  }
                ],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Right Eye',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 5.765],
                    [4.081, 4.084],
                    [5.75, 0],
                    [4.195, -5.259],
                    [0, -4.863],
                    [0, 0],
                    [2.426, -0.003],
                    [0.117, -0.386],
                    [-2.593, -2.621],
                    [-2.106, -1.038],
                    [0, -0.348],
                    [-3.314, 0],
                    [-0.543, 2.757],
                    [0, 0],
                    [-3.77, 1.273],
                    [-0.586, 1.629],
                    [0.512, 1.3],
                    [-1.126, 1.127]
                  ],
                  o: [
                    [0, -5.751],
                    [-4.082, -4.083],
                    [-6.593, 0],
                    [-3.033, 3.801],
                    [0, 0],
                    [-0.001, 1.823],
                    [-0.404, 0],
                    [-0.451, 1.497],
                    [1.663, 1.682],
                    [-0.057, 0.332],
                    [0, 3.315],
                    [2.915, 0],
                    [0, 0],
                    [0.671, 3.39],
                    [1.641, -0.555],
                    [0.562, -1.565],
                    [1.31, -0.837],
                    [4.075, -4.076]
                  ],
                  v: [
                    [24, -2.877],
                    [17.671, -18.128],
                    [2.426, -24.46],
                    [-14.504, -16.262],
                    [-19.187, -2.856],
                    [-19.187, 0.725],
                    [-23.051, 4.347],
                    [-23.932, 5.001],
                    [-19.96, 12.421],
                    [-14.284, 16.517],
                    [-14.373, 17.538],
                    [-8.372, 23.54],
                    [-2.485, 18.743],
                    [2.548, 18.746],
                    [10.513, 23.187],
                    [14.044, 19.717],
                    [14.017, 15.334],
                    [17.681, 12.384]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.169, 0],
                    [0, 0],
                    [2.905, 0],
                    [1.062, -1.67],
                    [0, 0],
                    [1.426, 1.442],
                    [0.42, 0.986],
                    [-0.785, 0.83],
                    [-0.001, 1.504],
                    [0, 0],
                    [-2.613, 3.352],
                    [-5.938, 0],
                    [-3.63, -3.632],
                    [0, -5.114],
                    [3.624, -3.626]
                  ],
                  o: [
                    [0, 0],
                    [-0.546, -2.744],
                    [-2.125, 0],
                    [0, 0],
                    [-1.793, -0.909],
                    [-1.689, -1.708],
                    [1.098, -0.27],
                    [1.042, -1.102],
                    [0, 0],
                    [0, -4.25],
                    [3.734, -4.789],
                    [5.113, 0],
                    [3.631, 3.633],
                    [0, 5.127],
                    [-3.54, 3.542]
                  ],
                  v: [
                    [2.422, 16.36],
                    [-2.482, 16.357],
                    [-8.354, 11.543],
                    [-13.407, 14.283],
                    [-13.407, 14.323],
                    [-18.254, 10.744],
                    [-21.282, 6.488],
                    [-18.408, 4.818],
                    [-16.807, 0.717],
                    [-16.807, -2.851],
                    [-12.778, -14.604],
                    [2.421, -22.074],
                    [15.979, -16.441],
                    [21.61, -2.877],
                    [15.99, 10.698]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [24.633, 25.96],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Body',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.094, 2.112],
                    [2.732, 1.161],
                    [3.12, 0],
                    [0, 0],
                    [2.743, -1.237],
                    [2.025, -2.148],
                    [1.102, -2.737],
                    [0, -3.035],
                    [0, 0],
                    [0.722, -0.809],
                    [1.373, 0],
                    [0.225, -0.446],
                    [-0.298, -0.405],
                    [-4.819, -2.284],
                    [-4.943, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.893, 1.072],
                    [-2.175, 1.96],
                    [-1.206, 2.744],
                    [0, 3.399],
                    [1.184, 2.83]
                  ],
                  o: [
                    [-2.102, -2.12],
                    [-2.834, -1.205],
                    [0, 0],
                    [-3.006, 0],
                    [-2.623, 1.185],
                    [-2.005, 2.128],
                    [-1.134, 2.817],
                    [0, 0],
                    [0, 1.307],
                    [-0.774, 0.865],
                    [-0.508, 0],
                    [-0.225, 0.447],
                    [2.891, 3.922],
                    [4.647, 2.202],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.29, -0.084],
                    [2.916, -1.081],
                    [2.248, -2.025],
                    [1.262, -2.872],
                    [0, -3.097],
                    [-1.149, -2.742]
                  ],
                  v: [
                    [79.369, -16.218],
                    [72.085, -21.162],
                    [63.111, -22.979],
                    [-58.168, -23],
                    [-66.832, -21.135],
                    [-73.836, -16.112],
                    [-78.518, -8.78],
                    [-80.228, 0.039],
                    [-80.226, 3.439],
                    [-81.346, 6.721],
                    [-84.627, 8.043],
                    [-85.816, 8.767],
                    [-85.699, 10.148],
                    [-73.914, 19.634],
                    [-59.254, 23],
                    [-59.181, 23],
                    [61.912, 22.99],
                    [61.927, 22.99],
                    [61.941, 22.99],
                    [71.259, 21.249],
                    [78.932, 16.667],
                    [84.138, 9.48],
                    [86.042, 0.03],
                    [84.255, -8.902]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 3
              },
              o: {
                a: 0,
                k: 100,
                ix: 4
              },
              w: {
                a: 0,
                k: 2.5,
                ix: 5
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [141.592, 25.5],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Speech Bubble',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 510,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
