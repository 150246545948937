import tw, { styled } from 'twin.macro';

export const Container = styled.main`
  ${tw`
    p-3
    pt-0
    pb-0
    w-full
    sm:p-2
  `};
  max-width: 1440px;
`;
