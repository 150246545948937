import tw, { styled } from 'twin.macro';
import { getDesignSystemValue } from 'config/design';

interface ReactionContainerProps {
  backgroundColor: string;
  textColor: string;
}

export const ReactionContainer = styled.div`
  ${tw`
    block
    rounded-cardRadius
    bg-wazePurple
    text-white
    items-center
    justify-center
    text-center
    p-2
    flex
    justify-center
    w-full
    md:col-auto
    delay-500
    duration-700
    ease-in-out
    transition-all
    translate-y-4
    transform
    col-start-1
    col-end-3
    md:col-start-1
    md:col-end-3
    min-h-min-content
  `};

  svg {
    ${tw`
      mb-3
    `};
  }

  ${({ backgroundColor }: ReactionContainerProps): string =>
    backgroundColor
      ? `background-color: ${getDesignSystemValue(`colors.${backgroundColor}`)}`
      : ''};

  ${({ textColor }: ReactionContainerProps): string =>
    textColor ? `color: ${getDesignSystemValue(`colors.${textColor}`)}` : ''};
`;
