import React, { ReactElement } from 'react';

import { BlockItem, Image, Modal } from 'component';

import { ChangePasswordModalContainer, ChangePasswordContainer, ModalClose } from './element';

import {
  CHANGE_PASSWORD_SUCCESS_IMAGE,
  CHANGE_PASSWORD_SUCCESS_TITLE,
  CHANGE_PASSWORD_SUCCESS_DESCRIPTION
} from 'config';

const ChangePasswordContent = ({
  closeHandler
}: {
  closeHandler: (e: boolean) => void
}): ReactElement => (
  <ChangePasswordContainer>
    <ModalClose onClick={() => closeHandler(false)} />
    <Image src={CHANGE_PASSWORD_SUCCESS_IMAGE} size="Small" />
    <BlockItem
      theme="BigHeadingText"
      captionScale="S"
      foregroundColor="black"
      heading={CHANGE_PASSWORD_SUCCESS_TITLE}
      body={CHANGE_PASSWORD_SUCCESS_DESCRIPTION}
      withLayout
    />
  </ChangePasswordContainer>
);

export const ChangePasswordModal = ({
  closeHandler
}: {
  closeHandler: (e: boolean) => void
}): ReactElement => {
  return (
    <Modal modalIsOpen={true} onRequestClose={() => closeHandler(false)}>
      <ChangePasswordModalContainer>
        <ChangePasswordContent closeHandler={closeHandler} />
      </ChangePasswordModalContainer>
    </Modal>
  );
};

export default ChangePasswordModal;
