import React, { ReactElement } from 'react';

import { BlockItem } from 'component';

import { ReactionContainer } from './element';

interface Props {
  image: string;
  imageSize: string;
  foregroundColor: string;
  heading: string;
  description: string;
  buttonText?: string;
  backgroundColor?: string;
  textColor?: string;
}

export const Reaction = ({
  image,
  imageSize = 'XL',
  foregroundColor,
  heading,
  description,
  buttonText = '',
  textColor = 'black',
  backgroundColor = 'gray2'
}: Props): ReactElement => {
  return (
    <ReactionContainer backgroundColor={backgroundColor} textColor={textColor}>
      <BlockItem
        foregroundColor={foregroundColor}
        captionScale="L"
        heading={heading}
        body={description}
        image={{ path: image, size: imageSize }}
        {...(buttonText && {
          buttons: [{ text: buttonText, theme: 'PrimaryWhite', link: '/', size: 'Large' }]
        })}
      />
    </ReactionContainer>
  );
};

export default Reaction;
