import { ElementType } from 'react';
import tw, { css, styled } from 'twin.macro';
import { get } from 'lodash';

import { Desktop, Mobile } from 'styles/responsive';

import { headingScales, getCaptionScaleByName } from 'config/design';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require('config/tailwind.config');

const {
  theme: { colors, extend: { margin } }
} = config;

interface StyledHeadingProps {
  // eslint-disable-next-line prettier/prettier
  as?: ElementType | keyof JSX.IntrinsicElements;
  headingScaleName?: HeadingScaleNameType;
  captionScaleName?: CaptionScaleNameType;
  textStyle?: TextStyle;
  textStyleForMobile?: TextStyle;
  desktop?: boolean;
  mobile?: boolean;
}

const styles = {
  desktop: {
    textAlign: {
      left: tw`text-left`,
      center: tw`text-center`,
      right: tw`text-right`
    }
  },
  mobile: {
    textAlign: {
      left: tw`sm:text-left`,
      center: tw`sm:text-center`,
      right: tw`sm:text-right`
    }
  }
};

export const StyledHeading = styled.h1<StyledHeadingProps>(
  ({ headingScaleName = 'H1', captionScaleName, textStyle, textStyleForMobile, desktop, mobile }: StyledHeadingProps) => [
    tw`
      font-medium
    `,
    headingScales[headingScaleName],
    captionScaleName ? getCaptionScaleByName(captionScaleName).heading.tw : '',
    styles.desktop.textAlign[get(textStyle, 'textAlign', 'left')],
    styles.mobile.textAlign[get(textStyleForMobile, 'textAlign', 'left')],
    css`color: ${colors[get(textStyle, 'color', 'black')]};`,
    css`margin-top: ${margin[get(desktop ? textStyle : textStyleForMobile, 'marginTop', '0')]};`,
    css`margin-right: ${margin[get(desktop ? textStyle : textStyleForMobile, 'marginRight', '0')]};`,
    css`margin-bottom: ${margin[get(desktop ? textStyle : textStyleForMobile, 'marginBottom', '0')]};`,
    css`margin-left: ${margin[get(desktop ? textStyle : textStyleForMobile, 'marginLeft', '0')]};`,
    desktop && Desktop,
    mobile && Mobile
  ]
);

