import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import Paragraph from 'component/Paragraph';

import { Container, FooterNavContainer, OptionsContainer, StyledLink } from './element';

type LinkType = {
  name: string,
  link: string
};

interface FooterProps {
  text?: string;
  options?: LinkType[];
}

export const Footer = ({ text, options }: FooterProps): ReactElement => {
  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: true });

  const getCopyrightYear = () => {
    const date = new Date();

    return date.getFullYear();
  };

  return (
    <Container>
      <FooterNavContainer ref={ref} show={inView}>
        <Paragraph paragraphScaleName="Legal">{text}</Paragraph>
        <OptionsContainer>
          {options && options.length > 0
            ? options.map((option: LinkType, index: number) => (
                <StyledLink key={`footer_nav_link_${index}`} href={option.link} target={'_blank'}>
                  {option.link.includes('copyright') ? (
                    <span>©{getCopyrightYear() + ' ' + option.name}</span>
                  ) : (
                    option.name
                  )}
                </StyledLink>
              ))
            : null}
        </OptionsContainer>
      </FooterNavContainer>
    </Container>
  );
};

export default Footer;
