/* eslint-disable max-lines-per-function, max-lines */

type LeftRight = 'left' | 'right';
type LeftRightCenter = LeftRight | 'center';

export interface Variant {
  direction: LeftRightCenter;
  orientation: LeftRight;
  squarePosition?: LeftRight;
  columns?: string;
  squareColor?: string;
  backgroundColor?: string;
  hideOnMobile?: boolean;
  disableOnMobile?: boolean;
  left: {
    name: string
  };
  right: {
    name: string
  };
}

export const diagonalDecoratorsVariants: {
  rectangle_diagonal: Variant,
  rectangle_rectangle: Variant,
  diagonal_diagonal: Variant,
  diagonal_rectangle: Variant
} = {
  rectangle_diagonal: {
    direction: 'left',
    orientation: 'left',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'rectangle'
    },
    right: {
      name: 'diagonal'
    }
  },
  rectangle_rectangle: {
    direction: 'center',
    orientation: 'left',
    columns: '12',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'rectangle'
    },
    right: {
      name: 'rectangle'
    }
  },
  diagonal_diagonal: {
    direction: 'center',
    orientation: 'left',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'diagonal'
    },
    right: {
      name: 'diagonal'
    }
  },
  diagonal_rectangle: {
    direction: 'right',
    orientation: 'left',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'diagonal'
    },
    right: {
      name: 'rectangle'
    }
  }
};

export const squareDecoratorsVariants: {
  square_rectangle: Variant,
  rectangle_square: Variant,
  square_diagonal: Variant,
  diagonal_square: Variant
} = {
  square_rectangle: {
    direction: 'left',
    orientation: 'left',
    squarePosition: 'left',
    columns: '12',
    squareColor: 'wazeOrange',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'square'
    },
    right: {
      name: 'rectangle'
    }
  },
  rectangle_square: {
    direction: 'right',
    orientation: 'right',
    squarePosition: 'right',
    columns: '12',
    squareColor: 'wazeOrange',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'rectangle'
    },
    right: {
      name: 'square'
    }
  },
  square_diagonal: {
    direction: 'left',
    orientation: 'left',
    squarePosition: 'left',
    columns: '11',
    squareColor: 'wazeOrange',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'square'
    },
    right: {
      name: 'diagonal'
    }
  },
  diagonal_square: {
    direction: 'right',
    orientation: 'left',
    squarePosition: 'right',
    columns: '11',
    squareColor: 'wazeOrange',
    hideOnMobile: false,
    disableOnMobile: false,
    left: {
      name: 'diagonal'
    },
    right: {
      name: 'square'
    }
  }
};

export interface BlocksItem {
  [k: string]: string | Variant | boolean | LeftRight | LeftRightCenter | { [k: string]: string };
}

export interface BlocksByAccentName {
  [k: string]: BlocksItem[];
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export const blocksByAccentNames = (
  leftDecoratorColor: string,
  rightDecoratorColor: string,
  backgroundColor: string
): any => ({
  rectangle_rectangle: [{ ...diagonalDecoratorsVariants.rectangle_rectangle, backgroundColor }],
  diagonal_rectangle: [
    {
      ...diagonalDecoratorsVariants.rectangle_diagonal,
      columns: '1',
      backgroundColor: leftDecoratorColor,
      hideOnMobile: true
    },
    {
      ...diagonalDecoratorsVariants.diagonal_rectangle,
      columns: '11',
      backgroundColor,
      disableOnMobile: true
    }
  ],
  rectangle_diagonal: [
    {
      ...diagonalDecoratorsVariants.rectangle_diagonal,
      columns: '11',
      backgroundColor,
      disableOnMobile: true
    },
    {
      ...diagonalDecoratorsVariants.diagonal_rectangle,
      columns: '1',
      backgroundColor: rightDecoratorColor,
      hideOnMobile: true
    }
  ],
  diagonal_diagonal: [
    {
      ...diagonalDecoratorsVariants.rectangle_diagonal,
      columns: '1',
      backgroundColor: leftDecoratorColor,
      hideOnMobile: true
    },
    {
      ...diagonalDecoratorsVariants.diagonal_diagonal,
      columns: '10',
      backgroundColor,
      disableOnMobile: true
    },
    {
      ...diagonalDecoratorsVariants.diagonal_rectangle,
      columns: '1',
      backgroundColor: rightDecoratorColor,
      hideOnMobile: true
    }
  ],
  square_rectangle: [
    {
      ...squareDecoratorsVariants.square_rectangle,
      squareColor: leftDecoratorColor,
      backgroundColor
    }
  ],
  rectangle_square: [
    {
      ...squareDecoratorsVariants.rectangle_square,
      squareColor: rightDecoratorColor,
      backgroundColor
    }
  ],
  diagonal_square: [
    {
      ...diagonalDecoratorsVariants.rectangle_diagonal,
      columns: '1',
      backgroundColor: leftDecoratorColor,
      hideOnMobile: true
    },
    {
      ...squareDecoratorsVariants.diagonal_square,
      squareColor: rightDecoratorColor,
      backgroundColor,
      disableOnMobile: true
    }
  ],
  square_diagonal: [
    {
      ...squareDecoratorsVariants.square_diagonal,
      squareColor: leftDecoratorColor,
      backgroundColor,
      disableOnMobile: true
    },
    {
      ...diagonalDecoratorsVariants.diagonal_rectangle,
      columns: '1',
      backgroundColor: rightDecoratorColor,
      hideOnMobile: true
    }
  ]
});
