import React, { useState, useEffect, ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { reduce } from 'lodash';
import { StyledComponent } from 'styled-components';

import { isRoleDisallowed } from 'lib/role';
import { isBrowser } from 'lib/browser';

import { VerticalMenuContents } from './VerticalMenuContents';

import {
  VerticalMenuContainer,
  VerticalMenuContentThemeA,
  VerticalMenuContentThemeB,
  NavThemeA,
  NavThemeB,
  ContainerThemeA,
  ContainerThemeB
} from './element';

export interface VerticalMenuOptionsPropsType {
  name: string;
  codename?: string;
  link?: string;
  children?: VerticalMenuOptionsPropsType[];
  disallowRoles?: string;
}

export interface VerticalMenuOptionsType {
  id: string;
  name: string;
  isActive: boolean;
  isOpened: boolean;
  link?: string;
  children?: VerticalMenuOptionsType[];
  loggedInOnly?: boolean;
}

export interface PropsType {
  options: VerticalMenuOptionsPropsType[];
  theme?: string;
  onClickHandle?: (props: { name?: string, hasChildren?: boolean }) => void;
  currentRole?: string;
  isLoggedIn?: boolean;
}

// NOTE : any + object typings below to comply w/ styled-component typings
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */
export const getComponentsByTheme = (
  theme: string
): {
  [e: string]: StyledComponent<any, any, object, string | number | symbol>
} => {
  if (theme === 'B') {
    return {
      Nav: NavThemeB,
      Container: ContainerThemeB,
      VerticalMenuContent: VerticalMenuContentThemeB
    };
  }
  return {
    Nav: NavThemeA,
    Container: ContainerThemeA,
    VerticalMenuContent: VerticalMenuContentThemeA
  };
};

const optionIsActive = ({
  option,
  id,
  nextId
}: {
  option: VerticalMenuOptionsType | VerticalMenuOptionsPropsType,
  id?: string,
  nextId: string
}) => (isBrowser && window.location.pathname === option.link) || (id && id === nextId) || false;

const optionIsOpened = ({
  option,
  id,
  nextId,
  parents
}: {
  option: VerticalMenuOptionsType | VerticalMenuOptionsPropsType,
  id?: string,
  nextId: string,
  parents?: string[]
}) =>
  (isBrowser && window.location.pathname === option.link) ||
  (id && id === nextId) ||
  (parents && parents.length > 0 && parents.indexOf(nextId) >= 0) ||
  false;

export const updateOptions = (
  options: VerticalMenuOptionsType[] | VerticalMenuOptionsPropsType[],
  id?: string,
  parents?: string[]
): VerticalMenuOptionsType[] => {
  return reduce(
    options,
    (nextOptions: VerticalMenuOptionsType[], option: VerticalMenuOptionsType) => {
      const nextId = option.id || uuidv4();
      const updatedOption = {
        ...option,
        id: nextId,
        isActive: optionIsActive({ option, id, nextId }),
        isOpened: optionIsOpened({ option, id, nextId, parents })
      };
      if (option.children && option.children.length > 0) {
        updatedOption.children = updateOptions(option.children, id, parents);
      }
      return [...nextOptions, updatedOption];
    },
    []
  );
};

export const VerticalMenu = ({
  options,
  theme = 'A',
  onClickHandle = () => null,
  currentRole,
  isLoggedIn
}: PropsType): ReactElement => {
  const { Nav } = getComponentsByTheme(theme);
  const [verticalMenuOptions, setVerticalMenuOptions] = useState<VerticalMenuOptionsType[]>(
    updateOptions(options)
  );
  useEffect(() => {
    setVerticalMenuOptions(updateOptions(options));
  }, [options]);

  return (
    <VerticalMenuContainer>
      <Nav>
        {verticalMenuOptions
          .filter(
            (option: VerticalMenuOptionsType) =>
              (Boolean(isLoggedIn) && option.loggedInOnly) || !option.loggedInOnly
          )
          .map((option: VerticalMenuOptionsType, idx: number) => {
            return (
              !isRoleDisallowed({ currentRole, module: option }) && (
                <VerticalMenuContents
                  key={`VerticalMenuContents-${idx}`}
                  option={option}
                  theme={theme}
                  onClickHandle={onClickHandle}
                  currentRole={currentRole}
                  updateOptions={updateOptions}
                  setVerticalMenuOptions={setVerticalMenuOptions}
                  verticalMenuOptions={verticalMenuOptions}
                />
              )
            );
          })}
      </Nav>
    </VerticalMenuContainer>
  );
};

export default VerticalMenu;
