import React, { ReactElement } from 'react';
import {  isEmpty } from 'lodash';

import { StyleByThemeViewport, StyleByTheme } from './blockItemStylesWithTheme';
import { BlockItemParagraph } from './BlockItemParagraph';
import { Content, ButtonsContainer } from './element';

import Heading from 'component/Heading';
import Paragraph from 'component/Paragraph';
import Button from 'component/Button';

const getMaxWidth = ({ theme }: { theme: ThemeType }): boolean =>
  theme === 'BigHeadingTextTwoButtons' ||
  theme === 'BigHeadingTextButton' ||
  theme === 'BigHeadingText2' ||
  theme === 'BigHeadingText';

const BlockItemHeading = ({
  captionScale,
  styles,
  foregroundColor,
  desktopAlign,
  desktopMargin,
  mobileAlign,
  mobileMargin,
  heading
}: {
  desktopAlign: string,
  styles: StyleByTheme,
  captionScale?: CaptionScaleNameType,
  foregroundColor?: string,
  mobileAlign: string,
  heading?: string,
  desktopMargin: StyleByThemeViewport,
  mobileMargin: StyleByThemeViewport
}): ReactElement => {

  /* eslint-disable prettier/prettier */
  const captionStyleOveride:CaptionScaleNameType = (styles.captionScaleName as CaptionScaleNameType);;

  return (
    <Heading
      captionScaleName={captionScale || captionStyleOveride}
      textStyle={{
        color: foregroundColor,
        textAlign: desktopAlign,
        ...desktopMargin.heading
      }}
      textStyleForMobile={{ textAlign: mobileAlign, ...mobileMargin.heading }}>
      {heading}
    </Heading>
  );
};

const BlockItemButtons = ({
  buttons,
  desktopAlign,
  mobileAlign
}: {
  buttons: Button[],
  desktopAlign: string,
  mobileAlign: string
}) => (
  <ButtonsContainer align={desktopAlign} alignForMobile={mobileAlign}>
    {buttons.map((button: Button, index: number) => (
      <Button
        key={`button_${index}`}
        theme={button.theme}
        size={button.size}
        download={button.download}
        link={button.link}
        tag={button.tag}
        target={button.target}>
        {button.text}
      </Button>
    ))}
  </ButtonsContainer>
);

interface Props {
  desktopAlign: string;
  styles: StyleByTheme;
  // styles: any;
  captionScale?: CaptionScaleNameType;
  foregroundColor?: string;
  mobileAlign: string;
  heading?: string;
  bodyClickHandler?: () => void;
  body?: string;
  buttons: Button[];
  footText?: string;
  theme: ThemeType;
}

export const BlockItemContent = ({
  desktopAlign,
  styles,
  captionScale,
  foregroundColor,
  mobileAlign,
  heading,
  body,
  bodyClickHandler,
  buttons,
  footText,
  theme
}: Props): ReactElement => {
  const hasButtons = !isEmpty(buttons);

  const desktopMargin = {
    heading: styles.desktop.heading,
    body: styles.desktop.body
  };

  const mobileMargin = {
    heading: styles.mobile.heading,
    body: styles.mobile.body
  };

  const withMaxWidth = getMaxWidth({ theme });

  return (
    <Content align={desktopAlign}>
      {heading && (
        <BlockItemHeading
          captionScale={captionScale}
          styles={styles}
          foregroundColor={foregroundColor}
          desktopAlign={desktopAlign}
          desktopMargin={desktopMargin}
          mobileAlign={mobileAlign}
          mobileMargin={mobileMargin}
          heading={heading}
        />
      )}
      {body && (
        <BlockItemParagraph
          captionScale={captionScale}
          styles={styles}
          foregroundColor={foregroundColor}
          desktopAlign={desktopAlign}
          desktopMargin={desktopMargin}
          mobileAlign={mobileAlign}
          mobileMargin={mobileMargin}
          bodyClickHandler={bodyClickHandler}
          withMaxWidth={withMaxWidth}
          body={body}
        />
      )}
      {hasButtons && (
        <BlockItemButtons buttons={buttons} desktopAlign={desktopAlign} mobileAlign={mobileAlign} />
      )}
      {footText && (
        <Paragraph
          markdownText={footText}
          paragraphScaleName="Legal"
          textStyle={{
            color: foregroundColor,
            textAlign: desktopAlign,
            marginTop: '4'
          }}
          textStyleForMobile={{ textAlign: mobileAlign }}
        />
      )}
    </Content>
  );
};

export default BlockItemContent;
