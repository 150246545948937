import tw, { css, styled } from 'twin.macro';

interface ContainerProps {
  withoutContainer?: boolean;
  withLayout?: boolean;
  hasWazer?: boolean;
  align?: string;
  alignForMobile?: string;
}

interface ButtonsContainer {
  align?: string;
  alignForMobile?: string;
}

interface ImageContainerProps {
  orientation: string;
}

interface ParagraphContentProps {
  withMaxWidth?: boolean;
}

export const VerticalContainer = styled.div(
  ({ withoutContainer = false, hasWazer = false, align, alignForMobile }: ContainerProps) => [
    tw`
      grid
      items-center
      gap-3
      grid-flow-row
      w-full
      relative
      h-full
      sm:gap-4
    `,
    align === 'center' ? tw`justify-center` : '',
    alignForMobile === 'center' ? tw`sm:justify-center` : '',
    withoutContainer === false
      ? [
          tw`
            items-center
            px-7
            py-9
            sm:px-3
            sm:py-10
          `,
          hasWazer ? tw`sm:pb-9` : ''
        ]
      : ''
  ]
);

export const HorizontalContainer = styled.div(
  ({ withoutContainer = false, withLayout = false }: ContainerProps) => [
    tw`
      grid
      items-center
      justify-center
      relative
      h-full
      gap-6
      grid-flow-col
      sm:grid-flow-row
      sm:gap-4
    `,
    withLayout
      ? css`
          grid-auto-columns: 1fr 1fr;
        `
      : '',
    withoutContainer === false
      ? tw`
        p-9
        sm:px-3
        sm:py-10
      `
      : ''
  ]
);

export const Content = styled.div`
  ${tw`
    flex
    flex-col
    justify-center
    order-2

  `};
  ${({ align = 'center' }: { align?: string }) => (align === 'center' ? tw`items-center` : '')}
`;

export const ImageContainer = styled.div(({ orientation }: ImageContainerProps) => [
  tw`
    flex
    justify-center
    items-center
  `,
  css`
    justify-self: center;
  `,
  orientation === 'left' ? tw`order-3 md:order-1` : tw`order-1`
]);

const styles = {
  desktop: {
    align: {
      left: tw`justify-start`,
      center: tw`justify-center`,
      right: tw`justify-end`
    }
  },
  mobile: {
    align: {
      left: tw`sm:justify-start`,
      center: tw`sm:justify-center`,
      right: tw`sm:justify-end`
    }
  }
};

export const ButtonsContainer = styled.div(
  ({ align = 'center', alignForMobile = 'center' }: ButtonsContainer) => [
    tw`
      grid
      gap-3
      sm:pt-4
      grid-flow-col
      justify-center
      sm:grid-flow-row
    `,
    styles.desktop.align[align],
    styles.mobile.align[alignForMobile]
  ]
);

export const ParagraphContent = styled.div(({ withMaxWidth = false }: ParagraphContentProps) => [
  withMaxWidth ? tw`max-w-120` : ''
]);
