import tw, { styled, css } from 'twin.macro';

interface WazerContainerProps {
  direction: 'left' | 'right';
  position?: string;
  animate?: boolean;
  show?: boolean;
}

interface WazerContentProps {
  direction: 'left' | 'right';
}

export const WazerContainer = styled.div`
  ${tw`
    absolute
    bottom-0
    -mb-3
    z-30
  `}
  ${({ direction, position, animate = false, show = false }: WazerContainerProps) => [
    css`
      transition: opacity 1.25s ease-in-out 0.75s;
      opacity: 0;
    `,

    direction === 'left' ? tw`ml-6` : tw`mr-5`,
    position
      ? css`
          ${direction === 'left' ? `left: ${position};` : `right: ${position};`};
        `
      : direction === 'left'
      ? tw`left-0`
      : tw`right-0`,
    animate
      ? css`
          animation-name: ${direction === 'right'
            ? 'wazerAnimationFromLeftToRight'
            : 'wazerAnimationFromRightToLeft'};
          animation-duration: 2.5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-play-state: paused;
        `
      : '',
    show && tw`opacity-100`,
    show &&
      css`
        animation-play-state: running;
      `
  ]}
`;

export const WazerContent = styled.div(({ direction }: WazerContentProps) => [
  direction === 'left'
    ? css`
        transform: scaleX(-1);
      `
    : ''
]);
