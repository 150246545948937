import tw, { styled } from 'twin.macro';

import { paragraphScales } from 'config/design';

interface StyledFormTextAreaProps {
  withoutBorder: boolean;
  hasError: boolean;
}

export const StyledFormTextArea = styled.div`
  ${tw`
    flex
    w-full
  `}
  textarea {
    ${tw`
      bg-white
      text-black
      border-none
      outline-none
      resize-none
      appearance-none
      rounded-cardRadius
      font-boing
      font-light
      p-3
      min-h-64
      w-full
      focus:outline-none
    `};
    ${({ hasError, withoutBorder }: StyledFormTextAreaProps) => {
      if (withoutBorder) {
        return hasError ? tw`shadow-border-2-wazeRed` : '';
      }
      return hasError
        ? tw`shadow-border-2-wazeRed`
        : tw`shadow-border-1-gray3 focus:shadow-border-2-black`;
    }}
    transition: all 250ms ease-in-out;
    ${paragraphScales.Small};
    :placeholder {
      ${tw`
        text-gray2
      `}
    }
  }
`;
