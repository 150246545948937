import React, { ReactElement, useState, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Transition, TransitionGroup } from 'react-transition-group';
import { HistoryLocation } from '@reach/router';

import { changePassword } from '../lib/account';

import {
  AlertModal,
  ChangePasswordModal,
  Footer,
  Header,
  SiteContainer,
  SiteMetadata,
  SitewideContainer
} from '../component';
import AuthContext, { AuthContextProvider } from '../AuthContext';


interface Props {
  children: ReactElement;
  location: HistoryLocation;
}

const transitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    opacity: 1,
    transition: `opacity 10ms ease-in-out`
  },
  exiting: {
    opacity: 0,
    transition: `opacity 250ms ease-in-out`
  }
};

const SITEWIDE_QUERY = graphql`
  query SitewideQueryAndSiteTitleQuery {
    site {
      siteMetadata {
        title
        logoTitle
        menuOptions {
          name
          link
          loggedInOnly
          action
          allowPublic
          children {
            name
            link
          }
        }
      }
    }
    content(meta: { name: { eq: "sitewide" } }) {
      footer {
        text
        options {
          link
          name
        }
      }
    }
  }
`;

const SitewideLayoutInner = ({ children, location }: Props): ReactElement => {
  const data = useStaticQuery(SITEWIDE_QUERY);

  const {
    site: { siteMetadata },
    content: { footer }
  } = data;

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const { isLoggedIn, profile, permissionLevel } = useContext(AuthContext);

  const toggleChangePasswordModal = async () => {
    if (profile) {
      await changePassword();
      setOpenChangePasswordModal(true);
    }
  };

  return (
    <>
      <SiteMetadata {...siteMetadata} currentRole={permissionLevel} />
      <Header
        isLoggedIn={isLoggedIn}
        toggleChangePasswordModal={toggleChangePasswordModal}
        {...siteMetadata}
        currentRole={permissionLevel}
      />
      {openChangePasswordModal && (
        <ChangePasswordModal
          closeHandler={() => {
            setOpenChangePasswordModal(false);
          }}
        />
      )}
      <AlertModal/>
      <SiteContainer location={location}>
        <SitewideContainer>
          <TransitionGroup component={null}>
            <Transition
              key={location.pathname}
              timeout={{
                enter: 100,
                exit: 100
              }}>
              {(status: string) => <div style={{ ...transitionStyles[status] }}>{children}</div>}
            </Transition>
          </TransitionGroup>
        </SitewideContainer>
        <Footer text={footer.text} options={footer.options} />
      </SiteContainer>
    </>
  );
};

const SitewideLayout = (props: Props): ReactElement => {
  return (
    <AuthContextProvider>
      <SitewideLayoutInner {...props} />
    </AuthContextProvider>
  );
};

export default SitewideLayout;
