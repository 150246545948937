import tw from 'twin.macro';

export const Desktop = tw`
  block
  sm:hidden
`;

export const Mobile = tw`
  hidden
  sm:block
`;
