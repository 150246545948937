import React, { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';

interface Props {
  height?: string;
  width?: string;
  toggleAnimation?: boolean;
}

const translateAnimation = keyframes`
  0% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(-1px);
  }
`;

interface SVGProps {
  toggleAnimation?: boolean;
}

const SVG = styled.svg<SVGProps>(({ toggleAnimation }) => [
  css`
    #down-arrow {
      transform: translateY(-1px);
    }
  `,
  toggleAnimation &&
    css`
      #down-arrow {
        animation: ${translateAnimation} 2s ease-in-out infinite;
      }
    `
]);

export const DownloadArrow = ({
  height = '21',
  width = '19',
  toggleAnimation = false
}: Props): ReactElement => (
  <SVG
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    toggleAnimation={toggleAnimation}>
    <g fill="none" fillRule="evenodd">
      <path d="M-14.5-14h48v48h-48z" />
      <g id="down-arrow" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M9.5556 1v14.44L15.5 9.5843M9.5556 1v14.44L3.6112 9.5843" />
      </g>
      <path
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.5 20h16"
      />
    </g>
  </SVG>
);

export default DownloadArrow;
