/* eslint-disable max-lines */
export default {
  v: '5.6.5',
  fr: 30,
  ip: 33,
  op: 60,
  w: 500,
  h: 500,
  nm: 'resourcez',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'resources_01 Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [216, 216, 0], ix: 2 },
            a: { a: 0, k: [72, 72, 0], ix: 1 },
            s: { a: 0, k: [286.111, 286.111, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.75, 0],
                        [0, 0],
                        [0, -1.751],
                        [0, 0],
                        [1.751, 0],
                        [0, 0],
                        [0, 1.751],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.751, 0],
                        [0, 0],
                        [0, 1.751],
                        [0, 0],
                        [-1.75, 0],
                        [0, 0],
                        [0, -1.751]
                      ],
                      v: [
                        [-28.83, -6],
                        [28.83, -6],
                        [32, -2.83],
                        [32, 2.83],
                        [28.83, 6],
                        [-28.83, 6],
                        [-32, 2.83],
                        [-32, -2.83]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.995999983245, 0.626999978458, 0.4, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76, 50], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [48, 84],
                        [40, 84]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [48, 92],
                        [32, 92]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [52, 100],
                        [36, 100]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [48, 64],
                        [40, 64]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [52, 44],
                        [36, 44]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [48, 56],
                        [32, 56]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.209, 0],
                        [0, 0],
                        [0, 2.209],
                        [-2.209, 0],
                        [0, 0],
                        [0, -2.209]
                      ],
                      o: [
                        [0, 0],
                        [-2.209, 0],
                        [0, -2.209],
                        [0, 0],
                        [2.209, 0],
                        [0, 2.209]
                      ],
                      v: [
                        [8, -6],
                        [-8, -6],
                        [-12, -10],
                        [-8, -14],
                        [8, -14],
                        [12, -10]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.751, 0],
                        [0, 0],
                        [0, 1.751],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 1.751],
                        [0, 0],
                        [1.751, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-28, -22],
                        [-28, 18.83],
                        [-24.83, 22],
                        [24.83, 22],
                        [28, 18.83],
                        [28, -22]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.995999983245, 0.626999978458, 0.4, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76, 78], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 8',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-39.765, 0],
                        [0, -39.765],
                        [39.765, 0],
                        [0, 39.765]
                      ],
                      o: [
                        [39.765, 0],
                        [0, 39.765],
                        [-39.765, 0],
                        [0, -39.765]
                      ],
                      v: [
                        [0, -72],
                        [72, 0],
                        [0, 72],
                        [-72, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [72, 72], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [216, 216, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [13.52, -13.5],
                        [0, -19.09],
                        [-13.5, -13.51],
                        [-19.09, 0],
                        [-13.5, 13.5],
                        [0, 19.09],
                        [13.5, 13.52],
                        [19.09, 0]
                      ],
                      o: [
                        [-19.09, 0],
                        [-13.5, 13.52],
                        [0, 19.09],
                        [13.52, 13.5],
                        [19.09, 0],
                        [13.5, -13.51],
                        [0, -19.09],
                        [-13.5, -13.5],
                        [0, 0]
                      ],
                      v: [
                        [0, -72],
                        [-50.92, -50.92],
                        [-72, 0],
                        [-50.92, 50.92],
                        [0, 72],
                        [50.92, 50.92],
                        [72, 0],
                        [50.92, -50.92],
                        [0, -72]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: '▽ Showcase  Icon',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 34, s: [30] },
            { t: 46, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [216, 216, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [2.564, 2.564, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 34,
              s: [80, 80, 100]
            },
            { t: 46, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [142.878, 0],
                [0, -142.878],
                [-142.878, 0],
                [0, 142.878]
              ],
              o: [
                [-142.878, 0],
                [0, 142.878],
                [142.878, 0],
                [0, -142.878]
              ],
              v: [
                [217, -36.703],
                [-41.703, 222],
                [217, 480.703],
                [475.703, 222]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 34, s: [-260] },
              { t: 46, s: [0] }
            ],
            ix: 4
          },
          nm: 'Mask 1'
        }
      ],
      tm: {
        a: 1,
        k: [
          { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [0] },
          { t: 361, s: [5] }
        ],
        ix: 2
      },
      w: 432,
      h: 432,
      ip: 34,
      op: 360,
      st: 34,
      bm: 0
    }
  ],
  markers: []
};
