import tw, { css, styled, TwStyle } from 'twin.macro';

interface AnimationContainerProps {
  size?: ImageSizeNameType;
}

const imagesSizing = {
  XXLarge: tw`w-128 sm:w-64`,
  XLarge: tw`w-96 sm:w-64`,
  Large: tw`w-64 sm:w-32`,
  Med: tw`w-40 h-40 sm:w-32`,
  Small: tw`w-32`
};

export const AnimationContainer = styled.div`
  ${({ size }: AnimationContainerProps): TwStyle | string =>
    size && imagesSizing[size]
      ? imagesSizing[size]
      : css`
          width: ${size};
        `}
  ${tw`mx-auto`}
`;
