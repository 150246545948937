import React, { ReactElement } from 'react';
import { Field } from 'formik';

import { FormSelectContainer, ArrowContainer } from './element';

import ArrowIcon from 'icons/Arrow';

interface Option {
  name?: string;
}

interface Props {
  name: string;
  options?: Option[];
}

export const FormSelect = ({ name, options = [] }: Props): ReactElement => {
  return (
    <FormSelectContainer>
      <Field as="select" id={name} name={name}>
        {options.map(({ name }: { name: string }) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </Field>
      <ArrowContainer>
        <ArrowIcon direction="bottom" />
      </ArrowContainer>
    </FormSelectContainer>
  );
};

export default FormSelect;
