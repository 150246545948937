import React, { ReactElement, ReactNode } from 'react';

import {
  Container,
  BoxContainer,
  SquareShapeContainer,
  DiagonalShape,
  DiagonalShapeAndSquare,
  SquareShape,
  SquareBackground,
  Square,
  Content
} from './element';

interface DecoratorType {
  name: 'rectangle' | 'square' | 'diagonal';
  position?: 'top' | 'bottom';
}

export interface BoxPropTypes {
  backgroundColor?: string;
  squareColor?: string;
  direction?: 'left' | 'right' | 'center';
  orientation?: 'right' | 'left';
  squarePosition?: 'right' | 'left';
  flipHorizontal?: boolean;
  disableOnMobile?: boolean;
  decorators?: {
    left?: DecoratorType,
    right?: DecoratorType
  };
  children?: ReactNode;
  shrinkHeight?: boolean;
  twoUpPadding?: boolean;
}

const defaultProps: {
  backgroundColor: string,
  squareColor: string,
  direction: 'left' | 'right' | 'center',
  orientation: 'right' | 'left',
  squarePosition: 'right' | 'left',
  disableOnMobile: boolean,
  decorators: {
    left: DecoratorType,
    right: DecoratorType
  }
} = {
  backgroundColor: 'gray',
  squareColor: 'wazeOrange',
  direction: 'left',
  orientation: 'right',
  squarePosition: 'right',
  disableOnMobile: false,
  decorators: {
    left: { name: 'rectangle' },
    right: { name: 'rectangle' }
  }
};

const validDecoratorPair = ({
  leftDecorator,
  rightDecorator
}: {
  leftDecorator: DecoratorType,
  rightDecorator: DecoratorType
}) =>
  (leftDecorator.name === 'diagonal' && rightDecorator.name === 'rectangle') ||
  (leftDecorator.name === 'rectangle' && rightDecorator.name === 'diagonal') ||
  (leftDecorator.name === 'diagonal' && rightDecorator.name === 'diagonal') ||
  (leftDecorator.name === 'rectangle' && rightDecorator.name === 'rectangle');

const Shape = ({
  leftDecorator,
  rightDecorator,
  backgroundColor,
  orientation,
  disableOnMobile,
  squareColor,
  squarePosition,
  direction,
  shrinkHeight,
  twoUpPadding
}: {
  direction?: 'left' | 'right' | 'center',
  leftDecorator: DecoratorType,
  rightDecorator: DecoratorType,
  backgroundColor: string | undefined,
  orientation?: 'left' | 'right',
  disableOnMobile?: boolean,
  squareColor?: string | undefined,
  squarePosition?: 'left' | 'right',
  shrinkHeight?: boolean,
  twoUpPadding?: boolean
}): ReactElement => (
  <>
    {validDecoratorPair({ leftDecorator, rightDecorator }) ? (
      <DiagonalShape
        backgroundColor={backgroundColor || defaultProps.backgroundColor}
        orientation={orientation}
        leftDecorator={leftDecorator}
        rightDecorator={rightDecorator}
        disableOnMobile={disableOnMobile}
        shrinkHeight={shrinkHeight}
      />
    ) : (
      <SquareShapeContainer direction={direction} twoUpPadding={twoUpPadding}>
        <DiagonalShapeAndSquare
          backgroundColor={backgroundColor || defaultProps.backgroundColor}
          orientation={orientation}
          leftDecorator={leftDecorator}
          rightDecorator={rightDecorator}
          disableOnMobile={disableOnMobile}
        />
        <SquareShape
          backgroundColor={backgroundColor || defaultProps.backgroundColor}
          orientation={squarePosition}
          leftDecorator={leftDecorator}
          rightDecorator={rightDecorator}>
          <Square backgroundColor={squareColor} orientation={squarePosition} />
          <SquareBackground backgroundColor={backgroundColor} orientation={squarePosition} />
        </SquareShape>
      </SquareShapeContainer>
    )}
  </>
);

export const Box = ({
  backgroundColor,
  squareColor,
  direction,
  orientation,
  squarePosition,
  disableOnMobile,
  decorators,
  children,
  shrinkHeight,
  twoUpPadding
}: BoxPropTypes = defaultProps): ReactElement => {
  const leftDecorator =
    decorators && decorators.left ? decorators.left : defaultProps.decorators.left;
  const rightDecorator =
    decorators && decorators.right ? decorators.right : defaultProps.decorators.right;

  return (
    <Container>
      <BoxContainer direction={direction} justifyEnd={shrinkHeight}>
        <Shape
          leftDecorator={leftDecorator}
          rightDecorator={rightDecorator}
          backgroundColor={backgroundColor || defaultProps.backgroundColor}
          orientation={orientation}
          disableOnMobile={disableOnMobile}
          squareColor={squareColor}
          squarePosition={squarePosition}
          shrinkHeight={shrinkHeight}
          twoUpPadding={twoUpPadding}
        />
        <Content leftDecorator={leftDecorator} rightDecorator={rightDecorator}>
          {children}
        </Content>
      </BoxContainer>
    </Container>
  );
};

export default Box;
