import React from 'react';

import './styles/global.css'

const WrapRootElement = ({ element }) => {
  return (
    <div>
      {element}
    </div>
  )
}

export default WrapRootElement