import React, { ChangeEvent, ReactElement } from 'react';

import { FormField } from 'component';

interface InputTextField {
  field: {
    name: string,
    value: string,
    onChange: (params: ChangeEvent<HTMLInputElement>) => void,
    onBlur: (params: ChangeEvent<HTMLInputElement>) => void
  };
  id?: string;
  type?: string;
}

export const InputTextField = ({ field, ...props }: InputTextField): ReactElement => (
  <FormField>
    <input type={props.type || 'text'} {...field} {...props} />
  </FormField>
);

export default InputTextField;
