import React, { ReactElement } from 'react';
import tw, { styled } from 'twin.macro';

interface Props {
  direction?: string;
  width?: string;
  height?: string;
}

const Container = styled.div`
  ${tw`
    flex
    items-center
    justify-center
    h-6
    w-6
    duration-500
    ease-in-out
  `};
  transition-property: transform;
  transform: ${({ direction }: { direction: string }): string =>
    direction === 'top' ? 'rotate(0deg)' : 'rotate(180deg)'};
`;

export const ArrowIcon = ({
  direction = 'top',
  width = '15',
  height = '8'
}: Props): ReactElement => (
  <Container direction={direction || 'top'}>
    <svg width={width} height={height} viewBox="0 0 15 8" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#000000" fillRule="nonzero">
          <path d="M6.7387688,0.298217135 C7.13354781,-0.101513044 7.88345646,-0.0972927798 8.27887195,0.298217135 L14.6938527,6.28878868 C15.084193,6.65330437 15.1051279,7.26523602 14.7406122,7.65557626 C14.7342536,7.66238539 14.7277968,7.66910227 14.7212441,7.6757248 L14.7017885,7.69538753 C14.3223161,8.07890084 13.7070323,8.09225114 13.311282,7.72555846 L7.50923438,2.34952018 L7.50923438,2.34952018 L1.68834832,7.72760596 C1.2925244,8.09331919 0.678093665,8.07959503 0.298993785,7.69657281 L0.278464031,7.67583064 C-0.0967128123,7.29677205 -0.0935660304,6.68534341 0.285492566,6.31016657 C0.292423316,6.3033068 0.299457574,6.29655239 0.306592919,6.28990569 L6.7387688,0.298217135 L6.7387688,0.298217135 Z"></path>
        </g>
      </g>
    </svg>
  </Container>
);

export default ArrowIcon;
