import React, { ReactElement } from 'react';
import { Link } from 'gatsby';

import Logo from 'component/Logo';
import Menu from 'component/Menu';
import { MenuOptionType } from 'component/Menu/Menu';

import { StyledHeader, StyledHeaderContent } from './element';

interface Props {
  menuOptions: MenuOptionType[];
  logoTitle?: string;
  toggleChangePasswordModal: () => void;
  isLoggedIn?: boolean;
}

const getLogoLink = ({ isLoggedIn = false }: { isLoggedIn?: boolean }) =>
  isLoggedIn ? '/brand' : '/';

export const Header = ({
  menuOptions,
  isLoggedIn,
  toggleChangePasswordModal
}: Props): ReactElement => (
  <StyledHeader>
    <StyledHeaderContent>
      <Link to={getLogoLink({ isLoggedIn })}>
        <Logo />
      </Link>
      <Menu options={menuOptions} toggleChangePasswordModal={toggleChangePasswordModal} />
    </StyledHeaderContent>
  </StyledHeader>
);

export default Header;
