import React, { ReactNode, ReactElement, FunctionComponent } from 'react';

import { StyledParagraph } from './element';

interface Props {
  children?: ReactNode;
  markdownText?: ReactNode;
  paragraphScaleName?: ParagraphScaleNameType;
  captionScaleName?: CaptionScaleNameType;
  textStyle?: TextStyle;
  textStyleForMobile?: TextStyle;
  bodyClickHandler?: () => void;
}

const defaultProps = {
  children: null,
  textStyle: {
    color: 'black',
    textAlign: 'left',
    marginTop: '0',
    marginRight: '0',
    marginBotton: '0',
    marginLeft: '0'
  },
  textStyleForMobile: {
    color: 'black',
    textAlign: 'left',
    marginTop: '0',
    marginRight: '0',
    marginBotton: '0',
    marginLeft: '0'
  }
};

export const Paragraph: FunctionComponent<Props> = ({
  children,
  markdownText,
  paragraphScaleName,
  captionScaleName,
  textStyle = defaultProps.textStyle,
  textStyleForMobile = defaultProps.textStyleForMobile,
  bodyClickHandler
}: Props): ReactElement => {
  return (
    <StyledParagraph
      source={markdownText}
      bodyClickHandler={bodyClickHandler}
      paragraphScaleName={paragraphScaleName}
      captionScaleName={captionScaleName}
      textStyle={textStyle}
      textStyleForMobile={textStyleForMobile}>
      {children}
    </StyledParagraph>
  );
};

export default Paragraph;
