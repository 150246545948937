import { FormStateType } from 'container/FormState';
import { default as firebase, auth, firestore } from 'lib/firebase';
import { isBrowser } from './browser';

export const signOut = async (): Promise<void> => {
  try {
    await auth.signOut();
  } catch (e) {
    if (isBrowser) {
      indexedDB.deleteDatabase('firebaseLocalStorageDb');
    }
  } finally {
    if (isBrowser) {
      location.replace('/');
    }
  }
};

export const changePassword = async (): Promise<void> => {
  if (auth?.currentUser?.email) {
    return await auth.sendPasswordResetEmail(auth?.currentUser?.email);
  }
};

export interface SignUpSuccessArgs extends FormStateType {
  user: firebase.User;
  name?: string;
}

export const signUpSuccess = async ({ user, name }: SignUpSuccessArgs): Promise<void> => {
  await user.updateProfile({ displayName: name });
  await user.sendEmailVerification();
};

export const signUp = async ({
  email,
  password,
  formState
}: {
  email: string,
  password: string,
  formState: FormStateType,
  loginRedirectPath: string
}): Promise<void> => {
  try {
    await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const { user } = await auth.createUserWithEmailAndPassword(email, password);
    if (user) {
      await signUpSuccess({ ...formState, user });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

// The email that receives email (via the Trigger Email extension)
// with the feedback
// TODO: explore altern
export const FEEDBACK_INBOX = 'wazebrand@freeassociation.is';

export const sendFeedback = async ({
  subject,
  text
}: {
  subject: string,
  text: string
}): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> => {
  return firestore.collection('mail').add({
    to: FEEDBACK_INBOX,
    from: auth.currentUser?.email,
    createdAt: new Date(),
    user: {
      uid: auth.currentUser?.uid,
      email: auth.currentUser?.email,
      name: auth.currentUser?.displayName
    },
    message: {
      subject,
      text
    }
  });
};
