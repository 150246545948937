/* eslint-disable max-lines */
import { get } from 'lodash';
import tw from 'twin.macro';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const design = require('./tailwind.config');

export const { theme, getDesignSystemValue } = design;

export const headingScales = {
  H1: tw`text-6xl leading-none`, // 59/59
  H2: tw`text-5xl leading-none`, // 48/48
  H3: tw`text-4xl leading-none`, // 40/40
  H4: tw`text-3xl leading-none`, // 32/32
  H5: tw`text-2xl leading-none`, // 22/22
  H6: tw`text-xl leading-none`, // 18/18
  mobile: {
    H1: tw`sm:text-6xl sm:leading-none`, // 59/59
    H2: tw`sm:text-5xl sm:leading-none`, // 48/48
    H3: tw`sm:text-4xl sm:leading-none`, // 40/40
    H4: tw`sm:text-3xl sm:leading-none`, // 32/32
    H5: tw`sm:text-2xl sm:leading-none`, // 22/22
    H6: tw`sm:text-xl sm:leading-none` // 18/18
  }
};

export const paragraphScales = {
  Large: tw`text-3xl leading-tight`, // 32/40
  Med: tw`text-2xl leading-tight`, // 22/28
  Small: tw`text-xl leading-tight`, // 18/23
  Legal: tw`text-lg leading-tight`, // 15/19
  mobile: {
    Large: tw`sm:text-3xl sm:leading-tight`, // 32/40
    Med: tw`sm:text-2xl sm:leading-tight`, // 22/28
    Small: tw`sm:text-xl sm:leading-tight`, // 18/23
    Legal: tw`sm:text-lg sm:leading-tight` // 15/19
  }
};

export const sectionByAspectRatio = {
  '1/1': tw`grid-cols["repeat(2, 1fr)"] sm:grid-cols-none`,
  '1/2': tw`grid-cols["1fr 2fr"] sm:grid-cols-none`,
  '1/3': tw`grid-cols["1fr 3fr"] sm:grid-cols-none`,
  '2/1': tw`grid-cols["2fr 1fr"] sm:grid-cols-none`
};

export const foregroundConfig = {
  white: ['wazePurple', 'wazeRed', 'black'],
  black: ['wazeBlue', 'carpoolGreen', 'wazeYellow', 'gray', 'gray2', 'wazeOrange']
};

export const paragraphMargins = {
  desktop: {
    top: {
      '0': tw`mt-0`,
      '1': tw`mt-1`,
      '2': tw`mt-2`,
      '3': tw`mt-3`,
      '4': tw`mt-4`,
      '5': tw`mt-5`,
      '6': tw`mt-6`,
      '7': tw`mt-7`,
      '8': tw`mt-8`,
      '9': tw`mt-9`,
      '10': tw`mt-10`,
      '11': tw`mt-11`,
      '12': tw`mt-12`,
      '13': tw`mt-14`,
      '14': tw`mt-14`,
      '15': tw`mt-16`
    },
    bottom: {
      '0': tw`mb-0`,
      '1': tw`mb-1`,
      '2': tw`mb-2`,
      '3': tw`mb-3`,
      '4': tw`mb-4`,
      '5': tw`mb-5`,
      '6': tw`mb-6`,
      '7': tw`mb-7`,
      '8': tw`mb-8`,
      '9': tw`mb-9`,
      '10': tw`mb-10`,
      '11': tw`mb-11`,
      '12': tw`mb-12`,
      '13': tw`mb-14`,
      '14': tw`mb-14`,
      '15': tw`mb-16`
    }
  },
  mobile: {
    top: {
      '0': tw`md:mt-0`,
      '1': tw`md:mt-1`,
      '2': tw`md:mt-2`,
      '3': tw`md:mt-3`,
      '4': tw`md:mt-4`,
      '5': tw`md:mt-5`,
      '6': tw`md:mt-6`,
      '7': tw`md:mt-7`,
      '8': tw`md:mt-8`,
      '9': tw`md:mt-9`,
      '10': tw`md:mt-10`,
      '11': tw`md:mt-11`,
      '12': tw`md:mt-12`,
      '13': tw`md:mt-12`,
      '14': tw`md:mt-14`,
      '15': tw`md:mt-16`
    },
    bottom: {
      '0': tw`md:mb-0`,
      '1': tw`md:mb-1`,
      '2': tw`md:mb-2`,
      '3': tw`md:mb-3`,
      '4': tw`md:mb-4`,
      '5': tw`md:mb-5`,
      '6': tw`md:mb-6`,
      '7': tw`md:mb-7`,
      '8': tw`md:mb-8`,
      '9': tw`md:mb-9`,
      '10': tw`md:mb-10`,
      '11': tw`md:mb-11`,
      '12': tw`md:mb-12`,
      '13': tw`md:mb-12`,
      '14': tw`md:mb-14`,
      '15': tw`md:mb-16`
    }
  }
};

export const widths = {
  desktop: {
    '0': tw`p-0`,
    '1': tw`p-1`,
    '2': tw`p-2`,
    '3': tw`p-3`,
    '4': tw`p-4`,
    '5': tw`p-5`,
    '6': tw`p-6`,
    '7': tw`p-7`,
    '8': tw`p-8`,
    '9': tw`p-9`,
    '10': tw`p-10`,
    '11': tw`p-11`,
    '12': tw`p-12`,
    '13': tw`p-12`,
    '14': tw`p-14`,
    '15': tw`p-14`,
    auto: tw`self-auto`,
    '1/2': tw`p-0.5`,
    px: tw`p-px`
  },
  mobile: {
    '0': tw`sm:p-0`,
    '1': tw`sm:p-1`,
    '2': tw`sm:p-2`,
    '3': tw`sm:p-3`,
    '4': tw`sm:p-4`,
    '5': tw`sm:p-5`,
    '6': tw`sm:p-6`,
    '7': tw`sm:p-7`,
    '8': tw`sm:p-8`,
    '9': tw`sm:p-9`,
    '10': tw`sm:p-10`,
    '11': tw`sm:p-11`,
    '12': tw`sm:p-12`,
    '13': tw`sm:p-12`,
    '14': tw`sm:p-14`,
    '15': tw`sm:p-14`,
    auto: tw`sm:self-auto`,
    '1/2': tw`sm:p-0.5`,
    px: tw`sm:p-px`
  },
  tablet: {
    '0': tw`md:p-0`,
    '1': tw`md:p-1`,
    '2': tw`md:p-2`,
    '3': tw`md:p-3`,
    '4': tw`md:p-4`,
    '5': tw`md:p-5`,
    '6': tw`md:p-6`,
    '7': tw`md:p-7`,
    '8': tw`md:p-8`,
    '9': tw`md:p-9`,
    '10': tw`md:p-10`,
    '11': tw`md:p-11`,
    '12': tw`md:p-12`,
    '13': tw`md:p-12`,
    '14': tw`md:p-14`,
    '15': tw`md:p-14`,
    auto: tw`md:self-auto`,
    '1/2': tw`md:p-0.5`,
    px: tw`md:p-px`
  }
};

const captionScales = {
  XL: {
    name: 'XL',
    heading: {
      desktop: 'h1',
      mobile: 'h3',
      tw: {
        ...headingScales.H1,
        ...headingScales.mobile.H3
      }
    },
    paragraph: {
      ...paragraphScales.Med,
      ...paragraphScales.mobile.Med
    }
  },
  L: {
    name: 'L',
    heading: {
      desktop: 'h2',
      mobile: 'h4',
      tw: {
        ...headingScales.H2,
        ...headingScales.mobile.H4
      }
    },
    paragraph: {
      ...paragraphScales.Large,
      ...paragraphScales.mobile.Med
    }
  },
  M: {
    name: 'M',
    heading: {
      desktop: 'h3',
      mobile: 'h5',
      tw: {
        ...headingScales.H3,
        ...headingScales.mobile.H5
      }
    },
    paragraph: {
      ...paragraphScales.Med,
      ...paragraphScales.mobile.Small
    }
  },
  S: {
    name: 'S',
    heading: {
      desktop: 'h4',
      mobile: 'h5',
      tw: {
        ...headingScales.H4,
        ...headingScales.mobile.H5
      }
    },
    paragraph: {
      ...paragraphScales.Med,
      ...paragraphScales.mobile.Small
    }
  },
  XS: {
    name: 'XS',
    heading: {
      desktop: 'h5',
      mobile: 'h5',
      tw: {
        ...headingScales.H5,
        ...headingScales.mobile.H5
      }
    },
    paragraph: {
      ...paragraphScales.Med,
      ...paragraphScales.mobile.Small
    }
  },
  XXS: {
    name: 'XXS',
    heading: {
      desktop: 'h5',
      mobile: 'h5',
      tw: {
        ...headingScales.H5,
        ...headingScales.mobile.H5
      }
    },
    paragraph: {
      ...paragraphScales.Small,
      ...paragraphScales.mobile.Small
    }
  }
};

export const colors = {
  transparent: 'transparent',
  transparent2: 'rgba(255, 255, 255, 0.001)',
  wazeBlue: '#33CCFF',
  wazeBlueLighter1: '#65dbff',
  wazeBlueLighter2: '#98e9ff',
  wazeBlueDarker1: '#1AB3FF',
  carpoolGreen: '#1ee592',
  wazePurple: '#963eff',
  wazePurpleLighter1: '#ac64ff',
  wazePurpleLighter2: '#c088ff',
  wazePurpleLighter3: '#d5acff',
  wazeYellow: '#FCE354',
  wazeOrange: '#FD804B',
  wazeRed: '#FF5252',
  black: '#000000',
  gray: '#F2F4F7',
  gray2: '#F5F5F5',
  gray3: '#9E9E9E',
  gray4: '#72767D',
  white: '#ffffff'
};

export const fontFamily = {
  boing: 'Boing'
};

export const getCaptionScaleByName = (name: string | CaptionScaleNameType): CaptionScale =>
  get(captionScales, name, captionScales.M);

export default theme;
