import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';

import config from './config';

import { isBrowser } from 'lib/browser';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let auth: firebase.auth.Auth;

if (isBrowser) {
  auth = firebase.auth();
}

const firestore = firebase.firestore();
const database = firebase.database();

const googleProvider = (): firebase.auth.GoogleAuthProvider =>
  new firebase.auth.GoogleAuthProvider();

export { auth, database, firestore, googleProvider };

export default firebase;
