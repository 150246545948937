import tw, { styled } from 'twin.macro';

import { paragraphScales } from 'config/design';

export const StyledFormField = styled.div`
  ${tw`
    w-full
  `}
  input {
    ${tw`
      bg-white
      text-black
      border-none
      outline-none
      resize-none
      appearance-none
      shadow-border-1-gray3
      rounded-cardRadius
      font-boing
      font-light
      px-3
      h-12
      w-full
      focus:shadow-border-2-black
      focus:outline-none
    `};
    transition: all 250ms ease-in-out;
    ${paragraphScales.Small};
    :placeholder {
      ${tw`
        text-gray2
      `}
    }
  }
`;
