/* eslint-disable */

const get = require('lodash/get');
const TailwindModularScale = require('tailwindcss-modularscale');
const TailwindCssGradients = require('tailwindcss-gradients');
const TailwindContent = require('tailwindcss-plugin-content');
const ModularScale = require('modularscale-js');

const modularScaleConfig = {
  base: 12,
  ratio: 1.22,
  unit: 'px'
};

const tailwindModularScaleFontClasses = TailwindModularScale({
  sizes: [
    { size: 'sm', value: -1 }, // 8
    { size: 'base', value: 0 }, // 12
    { size: 'lg', value: 1 }, // 15
    { size: 'xl', value: 2 }, // 18
    { size: '2xl', value: 3 }, // 22
    { size: '3xl', value: 5 }, // 32
    { size: '4xl', value: 6 }, // 40
    { size: '5xl', value: 7 }, // 48
    { size: '6xl', value: 8 } // 59
  ],
  ...modularScaleConfig
});

const getFontSizeByScale = (size) =>
  ModularScale(size, {
    base: modularScaleConfig.base,
    ratio: modularScaleConfig.ratio
  }).toFixed(2) + modularScaleConfig.unit;

const plugins = [tailwindModularScaleFontClasses, TailwindCssGradients, TailwindContent];

const squareSize = {
  flexible: {
    '1/2': '7.5vw',
    full: '15vw'
  },
  max: {
    '1/2': '65px',
    full: '130px'
  }
};

const fontWeights = {
  // hairline: 100,
  // thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600
  // bold: 700,
  // extrabold: 800,
  // black: 900
};

const colors = {
  transparent: 'transparent',
  transparent2: 'rgba(255, 255, 255, 0.001)',
  wazeBlue: '#33CCFF',
  wazeBlueLighter1: '#65dbff',
  wazeBlueLighter2: '#98e9ff',
  wazeBlueDarker1: '#1AB3FF',
  carpoolGreen: '#1ee592',
  wazePurple: '#963eff',
  wazePurpleLighter1: '#ac64ff',
  wazePurpleLighter2: '#c088ff',
  wazePurpleLighter3: '#d5acff',
  wazeYellow: '#FCE354',
  wazeOrange: '#FD804B',
  wazeRed: '#FF5252',
  black: '#000000',
  gray: '#F2F4F7',
  gray2: '#F5F5F5',
  gray3: '#9E9E9E',
  gray4: '#72767D',
  white: '#ffffff'
};

const screens = {
  lg: { max: '1280px' },
  md: { max: '1279px' },
  sm: { max: '847px' }
};

const fontFamily = {
  boing: 'Boing'
};

const lineHeight = {
  none: '1',
  tight: '1.250',
  snug: '1.375',
  normal: '1.500',
  relaxed: '1.625',
  loose: '2'
};

const letterSpacing = {
  normal: '0em'
};

const borderWidth = {
  '1': '1px',
  '2': '2px',
  '3': '3px'
};

const cardRadius = '8px';

const borderRadius = {
  pillRadiusLarge: '30px',
  pillRadius: '28px',
  pillRadiusSliderDesktop: '16px',
  pillRadiusSliderMobile: '8px',
  cardRadius,
  'flexible-square-1/2': squareSize.flexible['1/2'],
  'flexible-square-1/2plusCardRadius': `calc(${squareSize.flexible['1/2']} + ${cardRadius})`,
  'flexible-square-1/2-cardRadius': `calc(${squareSize.flexible['1/2']} - ${cardRadius})`,
  'max-square-1/2': squareSize.max['1/2'],
  'max-square-1/2plusCardRadius': `calc(${squareSize.max['1/2']} + ${cardRadius})`,
  'max-square-1/2-cardRadius': `calc(${squareSize.max['1/2']} - ${cardRadius})`
};

const opacity = {
  '0': '0',
  '25': '.25',
  '50': '.5',
  '75': '.75',
  '100': '1'
};

// Spacing from the design system
const spacing = {
  '0': '0px',
  '1': '8px',
  '2': '16px',
  '3': '24px',
  '4': '32px',
  '5': '40px',
  '6': '48px',
  '7': '56px',
  '8': '64px',
  '9': '72px',
  '10': '80px',
  '11': '88px',
  '12': '96px',
  '13': '104px',
  '14': '112px',
  '15': '120px',
  '16': '128px',
  '17': '136px',
  '18': '144px',
  '19': '152px',
  '20': '160px',
  '21': '168px',
  '22': '176px',
  '23': '184px',
  '24': '192px',
  '25': '200px'
};

const width = {
  auto: 'auto',
  px: '1px',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem', // 48px
  '13': '3.5rem', // 56px
  '16': '4rem', // 64px
  '24': '6rem', // 96px
  '32': '8rem', // 128px
  '40': '9rem', // 144px
  '48': '12rem',
  '52': '13.25rem', // 212px
  '64': '16rem',
  '80': '20rem', // 320px
  '96': '24rem',
  '128': '32rem',
  '1/2': '50%',
  '1/3': '33.33333%',
  '2/3': '66.66667%',
  '1/4': '25%',
  '3/4': '75%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '1/6': '16.66667%',
  '5/6': '83.33333%',
  '3/2': '150%',
  full: '100%',
  'full-2': '200%',
  screen: '100vw',
  'flexible-square-full': squareSize.flexible.full,
  'flexible-square-1/2': squareSize.flexible['1/2'],
  'flexible-full-gap-2': `calc(100% - ${spacing['2']})`,
  'flexible-full-gap-3': `calc(100% - ${spacing['3']})`,
  'flexible-full-gap-2-square-full': `calc(100% - ${spacing['2']} - ${squareSize.flexible.full})`,
  'flexible-full-gap-3-square-full': `calc(100% - ${spacing['3']} - ${squareSize.flexible.full})`,
  'max-square-full': squareSize.max.full,
  'max-square-1/2': squareSize.max['1/2'],
  'max-full-gap-2': `calc(100% - ${spacing['2']})`,
  'max-full-gap-3': `calc(100% - ${spacing['3']})`,
  'max-full-gap-2-square-full': `calc(100% - ${spacing['2']} - ${squareSize.max.full})`,
  'max-full-gap-3-square-full': `calc(100% - ${spacing['3']} - ${squareSize.max.full})`
};

const height = {
  auto: 'auto',
  px: '1px',
  '1': '0.25rem',
  '2': '0.5rem', // 8px
  '3': '0.75rem',
  '4': '1rem', // 16px
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem', // 32px
  '10': '2.5rem',
  '12': '3rem', // 48px
  '13': '3.5rem', // 56px
  '14': '3.75rem', // 60px
  '16': '4rem',
  '18': '4.5rem', // 72px
  '20': '5rem', // 80px
  '24': '6rem', // 96px
  '28': '7rem', // 112px
  '32': '8rem', // 128px
  '40': '9rem', // 144px
  '48': '12rem',
  '52': '13.25rem', // 212px
  '64': '16rem',
  '1/10': '10%',
  '2/10': '20%',
  '3/10': '30%',
  '4/10': '40%',
  '5/10': '50%',
  '6/10': '60%',
  '7/10': '70%',
  '8/10': '80%',
  '9/10': '90%',
  '10/10': '100%',
  '3/2': '150%',
  full: '100%',
  'full-2': '200%',
  screen: '100vh',
  'screen-1/2': '50vh',
  'menu-md': `calc(100vh - ${spacing['12']})`,
  'menu-sm': `calc(100vh - ${spacing['9']})`,
  'flexible-square-full': squareSize.flexible.full,
  'flexible-square-1/2': squareSize.flexible['1/2'],
  'flexible-full-gap-2': `calc(100% - ${spacing['2']})`,
  'flexible-full-gap-3': `calc(100% - ${spacing['3']})`,
  'flexible-full-gap-2-square-full': `calc(100% - ${spacing['2']} - ${squareSize.flexible.full})`,
  'flexible-full-gap-3-square-full': `calc(100% - ${spacing['3']} - ${squareSize.flexible.full})`,
  'max-square-full': squareSize.max.full,
  'max-square-1/2': squareSize.max['1/2'],
  'max-full-gap-2': `calc(100% - ${spacing['2']})`,
  'max-full-gap-3': `calc(100% - ${spacing['3']})`,
  'max-full-gap-2-square-full': `calc(100% - ${spacing['2']} - ${squareSize.max.full})`,
  'max-full-gap-3-square-full': `calc(100% - ${spacing['3']} - ${squareSize.max.full})`
};

const margin = {
  auto: 'auto',
  px: '1px',
  ...spacing
};

const padding = {
  auto: 'auto',
  '1/2': '50%',
  px: '1px',
  ...spacing
};

const gap = spacing;

const inset = {
  '-0.5': '-4px',
  '-1': '-8px',
  '-2': '-16px',
  '-3': '-24px',
  '-4': '-32px',
  ...padding
};

const captions = {
  xl: {
    screen: {
      lg: { heading: 'h1', text: 'lg' },
      sm: { heading: 'h3', text: 'md' }
    }
  },
  l: {
    screen: {
      lg: { heading: 'h2', text: 'lg' },
      sm: { heading: 'h4', text: 'md' }
    }
  },
  m: {
    screen: {
      lg: { heading: 'h3', text: 'md' },
      sm: { heading: 'h5', text: 'sm' }
    }
  },
  s: {
    screen: {
      lg: { heading: 'h4', text: 'md' },
      sm: { heading: 'h5', text: 'sm' }
    }
  },
  xs: {
    screen: {
      lg: { heading: 'h5', text: 'md' },
      sm: { heading: 'h5', text: 'sm' }
    }
  },
  xxs: {
    screen: {
      lg: { heading: 'h5', text: 'sm' },
      sm: { heading: 'h5', text: 'sm' }
    }
  }
};

const minHeight = {
  ...height,
  '0': '0',
  '1/4': '25%',
  '1/2': '50%',
  '3/4': '75%',
  full: '100%',
  '520': '520px',
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem', // 48px
  '16': '4rem',
  '18': '4.5rem', // 72px
  '20': '5.5rem', // 88px
  '24': '6rem', // 96px
  '32': '8rem',
  '40': '10.625rem', //170px
  '48': '12rem',
  '64': '16rem',
  'max-content': 'max-content',
  'min-content': 'min-content',
  screen: '100vh',
  auto: 'auto'
};

const maxHeight = minHeight;

const minWidth = {
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem', // 48px
  '16': '4rem',
  '18': '4.5rem', // 72px
  '24': '6rem', // 96px
  '28': '7.5625rem', // 121px
  '32': '8rem',
  '40': '10.625rem', //170px
  '48': '12rem',
  '64': '16rem',
  '80': '23.875rem', //382px
  '120': '40.5rem', // 648px
  '1/4': '25%',
  '1/2': '50%',
  '3/4': '75%',
  'max-content': 'max-content',
  'min-content': 'min-content',
  full: '100%'
};

const maxWidth = {
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem', // 48px
  '16': '4rem',
  '18': '4.5rem', // 72px
  '24': '6rem', // 96px
  '32': '8rem',
  '40': '10.625rem', //170px
  '48': '12rem',
  '64': '16rem',
  '80': '23.875rem', //382px
  '120': '40.5rem', // 648px
  '1/4': '25%',
  '1/2': '50%',
  '3/4': '75%',
  'max-content': 'max-content',
  'min-content': 'min-content',
  full: '100%',
  screen: '100vw'
};

const gridTemplateRows = {
  '2/auto': 'repeat(2, minmax(0, auto))',
  '1fr': '1fr',
  auto: 'auto'
};

const gridTemplateColumns = {
  sitewide: '288px 1fr',
  '2/auto': 'repeat(2, minmax(0, auto))',
  '3/auto': 'repeat(3, minmax(0, auto))',
  'auto-fit/auto': 'repeat(auto-fit, minmax(0, auto))',
  'auto-fit/1fr': 'repeat(auto-fit, minmax(0, 1fr))',
  '60/40': '60% 40%',
  '1:1': 'repeat(2, 1fr)',
  '1:2': '1fr 2fr',
  '1:3': '1fr 3fr',
  '2:1': '2fr 1fr',
  'auto/1': 'auto 1fr',
  'full': '100%'
};

const skew = {
  '8': '8deg',
  '-8': '-8deg'
};

const boxShadow = {
  'border-1-black': `inset 0 0 0 1px ${colors.black}`,
  'border-2-black': `inset 0 0 0 2px ${colors.black}`,
  'border-3-black': `inset 0 0 0 3px ${colors.black}`,
  'border-1-gray3': `inset 0 0 0 1px ${colors.gray3}`,
  'border-2-gray3': `inset 0 0 0 2px ${colors.gray3}`,
  'border-3-gray3': `inset 0 0 0 3px ${colors.gray3}`,
  'border-1-wazeRed': `inset 0 0 0 1px ${colors.wazeRed}`,
  'border-2-wazeRed': `inset 0 0 0 2px ${colors.wazeRed}`,
  'border-3-wazeRed': `inset 0 0 0 3px ${colors.wazeRed}`
};

const linearGradientDirections = {
  l: 'to left',
  t: 'to top',
  b: 'to bottom'
};

const linearGradientColors = {
  'transparent-to-gray2': [colors.transparent2, colors.gray2],
  'gray2-to-transparent': [colors.gray2, colors.transparent2]
};

const zIndex = {
  '-10': '-10',
  '-1': '-1',
  '0': 0,
  '10': 10,
  '20': 20,
  '30': 30,
  '40': 40,
  '50': 50,
  '25': 25,
  '50': 50,
  '75': 75,
  '100': 100,
  auto: 'auto'
};

const gridRowStart = {
  '8': '8',
  '9': '9',
  '10': '10',
  '11': '11',
  '12': '12'
};

const gridRowEnd = gridRowStart;

const tailwindConfig = {
  captions,
  plugins,
  theme: {
    fontWeights,
    colors,
    screens,
    fontFamily,
    extend: {
      // headline - 1 or 1.1
      // paragraph - 1.25 or 1.5
      lineHeight,
      letterSpacing,
      borderWidth,
      borderRadius,
      boxShadow,
      gridRowStart,
      gridRowEnd,
      inset,
      opacity,
      width,
      height,
      linearGradientDirections,
      linearGradientColors,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      margin,
      padding,
      gap,
      gridTemplateRows,
      gridTemplateColumns,
      skew,
      zIndex
    }
  },
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  variants: {
    linearGradients: ['responsive']
  }
};

const getDesignSystemValue = (path) => {
  if (get(tailwindConfig, `theme.${path}`)) {
    return get(tailwindConfig, `theme.${path}`);
  }
  console.log(`Error: designSystemValue not found for path: ${path}`);
  return '';
};

module.exports = tailwindConfig;
module.exports.getFontSizeByScale = getFontSizeByScale;
module.exports.getDesignSystemValue = getDesignSystemValue;
