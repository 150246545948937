/* eslint-disable max-lines */
export default {
  v: '5.6.5',
  fr: 30,
  ip: 55,
  op: 75,
  w: 750,
  h: 750,
  nm: '0Home Fade up slow wazers 2',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'waze_toneofvoice_01 Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [320, 324, 0], ix: 2 },
            a: { a: 0, k: [72, 72, 0], ix: 1 },
            s: { a: 0, k: [438.889, 438.889, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.104, 0],
                        [0, -1.104],
                        [1.104, 0],
                        [0, 1.104]
                      ],
                      o: [
                        [1.104, 0],
                        [0, 1.104],
                        [-1.104, 0],
                        [0, -1.104]
                      ],
                      v: [
                        [0, -2],
                        [2, 0],
                        [0, 2],
                        [-2, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [88.37, 96], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.104, 0],
                        [0, -1.104],
                        [1.104, 0],
                        [0, 1.104]
                      ],
                      o: [
                        [1.104, 0],
                        [0, 1.104],
                        [-1.104, 0],
                        [0, -1.104]
                      ],
                      v: [
                        [0, -2],
                        [2, 0],
                        [0, 2],
                        [-2, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [80.37, 96], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.104, 0],
                        [0, -1.104],
                        [1.104, 0],
                        [0, 1.104]
                      ],
                      o: [
                        [1.104, 0],
                        [0, 1.104],
                        [-1.104, 0],
                        [0, -1.104]
                      ],
                      v: [
                        [0, -2],
                        [2, 0],
                        [0, 2],
                        [-2, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [72.37, 96], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.104, 0],
                        [0, -1.104],
                        [1.104, 0],
                        [0, 1.104]
                      ],
                      o: [
                        [1.104, 0],
                        [0, 1.104],
                        [-1.104, 0],
                        [0, -1.104]
                      ],
                      v: [
                        [0, -2],
                        [2, 0],
                        [0, 2],
                        [-2, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [70, 48], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.104, 0],
                        [0, -1.104],
                        [1.104, 0],
                        [0, 1.104]
                      ],
                      o: [
                        [1.104, 0],
                        [0, 1.104],
                        [-1.104, 0],
                        [0, -1.104]
                      ],
                      v: [
                        [0, -2],
                        [2, 0],
                        [0, 2],
                        [-2, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [62, 48], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.104, 0],
                        [0, -1.104],
                        [1.104, 0],
                        [0, 1.104]
                      ],
                      o: [
                        [1.104, 0],
                        [0, 1.104],
                        [-1.104, 0],
                        [0, -1.104]
                      ],
                      v: [
                        [0, -2],
                        [2, 0],
                        [0, 2],
                        [-2, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [54, 48], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4, 4],
                        [-4, -4]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [36, 76], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 5.021],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-5.02, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [12, 12],
                        [5.09, 12],
                        [-4, 2.91],
                        [-4, -12],
                        [-12, -4]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [36, 84], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-4, -4],
                        [4, 4]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [108, 72], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, -5.02],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [5.021, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [-12, -14],
                        [-5.09, -14],
                        [4, -4.91],
                        [4, 14],
                        [12, 6]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [108, 62], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, -11.046],
                        [-11.94, -0.33],
                        [0, 0],
                        [-3.58, 4.2],
                        [1.95, 1.662],
                        [1.106, -0.003],
                        [0, 0],
                        [0, 3.12],
                        [10.71, 0]
                      ],
                      o: [
                        [0, 0],
                        [-11.046, 0],
                        [0, 11.27],
                        [0, 0],
                        [12.09, 0],
                        [1.662, -1.95],
                        [-0.842, -0.718],
                        [0, 0],
                        [-0.79, 0],
                        [0, -11],
                        [0, 0]
                      ],
                      v: [
                        [1.719, -20],
                        [-8.281, -20],
                        [-28.281, 0],
                        [-7.661, 20],
                        [2.479, 20],
                        [26.619, 10.8],
                        [26.098, 4.259],
                        [23.079, 3.15],
                        [22.879, 3.15],
                        [21.109, 0.03],
                        [1.689, -19.97]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -8.81],
                        [-2.16, 0],
                        [0, 0],
                        [0.009, -0.359],
                        [0.096, -0.113],
                        [10.62, 0],
                        [0, 0],
                        [0, 9.17],
                        [-8.836, 0]
                      ],
                      o: [
                        [8.5, 0],
                        [0, 6.2],
                        [0, 0],
                        [0.359, 0.009],
                        [-0.002, 0.149],
                        [-3, 3.55],
                        [0, 0],
                        [-9.9, -0.23],
                        [0, -8.837],
                        [0, 0]
                      ],
                      v: [
                        [1.719, -16],
                        [17.139, 0],
                        [22.909, 7.12],
                        [23.109, 7.12],
                        [23.742, 7.786],
                        [23.589, 8.19],
                        [2.509, 15.97],
                        [-7.491, 15.97],
                        [-24.281, 0],
                        [-8.281, -16]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [84.281, 96], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 11',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 10.33],
                        [-9.937, 0.006],
                        [0, 0],
                        [0, -9.91],
                        [-1.62, 0],
                        [0, 0],
                        [-0.025, -1.463],
                        [0.414, -0.491],
                        [11.4, 0]
                      ],
                      o: [
                        [-11, -0.28],
                        [0, -9.937],
                        [0, 0],
                        [9.599, 0],
                        [0, 4.59],
                        [0, 0],
                        [1.463, -0.025],
                        [0.011, 0.641],
                        [-3.301, 3.92],
                        [0, 0]
                      ],
                      v: [
                        [-7.292, 18],
                        [-26.014, 0],
                        [-8.023, -18],
                        [1.978, -18],
                        [19.396, 0],
                        [23.168, 5.12],
                        [23.308, 5.12],
                        [26.003, 7.724],
                        [25.378, 9.48],
                        [2.768, 17.97]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.322000002394, 0.933000033509, 0.666999966491, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [84.023, 96], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -11],
                        [0.77, 0],
                        [0, 0],
                        [0.028, -2.563],
                        [-0.727, -0.852],
                        [-12.09, 0],
                        [0, 0],
                        [0, 11.29],
                        [11.046, 0]
                      ],
                      o: [
                        [-10.71, 0],
                        [0, 3.12],
                        [0, 0],
                        [-2.562, -0.027],
                        [-0.011, 1.12],
                        [3.58, 4.2],
                        [0, 0],
                        [11.94, -0.31],
                        [0, -11.046],
                        [0, 0]
                      ],
                      v: [
                        [-1.92, -19.97],
                        [-21.34, 0.03],
                        [-23.11, 3.15],
                        [-23.31, 3.15],
                        [-27.999, 7.741],
                        [-26.89, 10.8],
                        [-2.75, 20],
                        [7.38, 20],
                        [28.01, 0],
                        [8.01, -20]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, -8.836],
                        [9.84, -0.28],
                        [0, 0],
                        [2.99, 3.55],
                        [-0.274, 0.232],
                        [-0.148, 0.004],
                        [0, 0],
                        [0, 6.2],
                        [-8.5, 0]
                      ],
                      o: [
                        [0, 0],
                        [8.837, 0],
                        [0, 9.15],
                        [0, 0],
                        [-10.62, 0],
                        [-0.232, -0.273],
                        [0.113, -0.096],
                        [0, 0],
                        [2.16, 0],
                        [0, -8.84],
                        [0, 0]
                      ],
                      v: [
                        [-1.99, -16],
                        [8.01, -16],
                        [24.01, 0],
                        [7.29, 16],
                        [-2.71, 16],
                        [-23.79, 8.22],
                        [-23.714, 7.304],
                        [-23.31, 7.15],
                        [-23.11, 7.15],
                        [-17.34, 0.03],
                        [-1.92, -15.97]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.99, 48], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 13',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [3.3, 3.92],
                        [-1.118, 0.945],
                        [-0.618, 0.006],
                        [0, 0],
                        [-0.685, 0.521],
                        [0, 1.45],
                        [-9.6, 0],
                        [0, 0],
                        [-0.017, -9.929],
                        [10.99, -0.31]
                      ],
                      o: [
                        [-11.4, 0],
                        [-0.945, -1.118],
                        [0.472, -0.398],
                        [0, 0],
                        [0.86, 0.037],
                        [1.14, -1],
                        [0, -9.94],
                        [0, 0],
                        [9.929, 0],
                        [0, 10.3],
                        [0, 0]
                      ],
                      v: [
                        [-2.602, 17.985],
                        [-25.212, 9.495],
                        [-24.898, 5.76],
                        [-23.212, 5.135],
                        [-23.012, 5.135],
                        [-20.622, 4.385],
                        [-19.242, 0.015],
                        [-1.822, -17.985],
                        [8.158, -17.985],
                        [26.158, -0.015],
                        [7.488, 17.985]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.2, 0.8, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.843, 48.015], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 14',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-39.765, 0],
                        [0, -39.765],
                        [39.765, 0],
                        [0, 39.765]
                      ],
                      o: [
                        [39.765, 0],
                        [0, 39.765],
                        [-39.765, 0],
                        [0, -39.765]
                      ],
                      v: [
                        [0, -72],
                        [72, 0],
                        [0, 72],
                        [-72, 0]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [72, 72], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 15',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 21,
      ty: 0,
      nm: '▽ TOV Icon',
      parent: 25,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 59, s: [0] },
            { t: 72, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-837.5, -1718.5, 0], ix: 2 },
        a: { a: 0, k: [318, 318, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [2.524, 2.524, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 59,
              s: [-80, 80, 100]
            },
            { t: 72, s: [-100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [202.395, 0],
                [0, -202.395],
                [-202.395, 0],
                [0, 202.395]
              ],
              o: [
                [-202.395, 0],
                [0, 202.395],
                [202.395, 0],
                [0, -202.395]
              ],
              v: [
                [322, -63.469],
                [-44.469, 303],
                [322, 669.469],
                [688.469, 303]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 59, s: [-370] },
              { t: 72, s: [0] }
            ],
            ix: 4
          },
          nm: 'Mask 1'
        }
      ],
      tm: {
        a: 1,
        k: [
          { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 59, s: [0] },
          { t: 361, s: [5] }
        ],
        ix: 2
      },
      w: 636,
      h: 636,
      ip: 59,
      op: 360,
      st: 59,
      bm: 0
    },
    {
      ddd: 0,
      ind: 25,
      ty: 3,
      nm: 'TOV Grey Block',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 59, s: [0] },
            { t: 72, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 59,
              s: [-466.5, 2190.5, 0],
              to: [0, -13.333, 0],
              ti: [0, 13.333, 0]
            },
            { t: 72, s: [-466.5, 2110.5, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [-100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 59,
      op: 360,
      st: 59,
      bm: 0
    }
  ],
  markers: []
};
